/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Upload, Button } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { DeleteOutlined, ImportOutlined } from "@ant-design/icons";
import { AxiosResponse } from "axios";
import { translations } from "../../../../config/translations";
import { pdfType } from "../../../../config/constants";
import { PrilogType } from "../../../../core/models/enum";
import { PrijedlogPrilog } from "../../../../core/models/PrijedlogPrilog";
import { showError } from "../../../../helpers/NotificationHelper";
import { formItemLayoutDrawer } from "../../../../helpers/TableHelper";
import { openPDFPreview } from "../../../../helpers/CommonHelper";
import { useApiContext } from '../../../../helpers/ApiContext';
import { Props } from "./index";

const UploadSignedPdfForm: React.FC<Props> = (props: Props) => {
    const { prijedlogId, onSuccess } = props;
    const { postAsync, deleteAsync, getAsync } = useApiContext();
    const [potpisaniPdf, setPotpisaniPdf] = useState<UploadFile[]>([]);
    const [potpisaniPrilog, setPotpisaniPrilog] = useState<PrijedlogPrilog | undefined>();

    useEffect(() => {
        getPdfs();
    }, []);

    const savePotpisaniPdf = async (): Promise<void> => {
        let formData = new FormData();

        formData.append("pdf", potpisaniPdf[0] as any);
        formData.append("type", PrilogType.PotpisaniPDF as any);
        formData.append("prijedlogId", prijedlogId);

        const data = await postAsync<AxiosResponse>('/Files/UploadSignedPdf', formData, undefined, translations.general.importPdfSuccess, translations.general.errorSavingData);

        if (data) {
            getPdfs();
            setPotpisaniPdf([]);
            onSuccess();
        };
    }

    const deletePotpisaniPdf = async () => {
        const data = await deleteAsync<AxiosResponse>('/Files/DeletePrijedlogPrilog/' + potpisaniPrilog?.id, undefined, translations.general.successDeletingPdf, translations.general.errorDeletingPdf);

        if (data.data) {
            getPdfs();
            setPotpisaniPrilog(undefined);
        };
    };

    const getPdfs = async () => {
        const data = await getAsync<PrijedlogPrilog[]>('/Files/GetAllPrijedlogPrilog/' + prijedlogId);

        if (data) {
            const potpisani = data.find((pdf: PrijedlogPrilog): boolean => pdf.type === PrilogType.PotpisaniPDF);
            if (potpisani) {
                setPotpisaniPrilog(potpisani);
            }
        }
    }

    return (
        <Form {...formItemLayoutDrawer}>
            <Form.Item
                name="potpisaniPdf"
                label={translations.general.potpisaniPdf}
                rules={[
                    {
                        required: true,
                        message: translations.general.requiredField,
                    },
                ]}
            >
                {!potpisaniPrilog && <Upload
                    fileList={potpisaniPdf}
                    onRemove={() => {
                        setPotpisaniPdf([]);
                    }}
                    beforeUpload={(file) => {
                        if (file.type !== pdfType) {
                            showError(translations.general.wrongFileType);
                        } else {
                            setPotpisaniPdf([file])
                        }
                        return false;
                    }}
                    accept={pdfType}
                    disabled={potpisaniPrilog !== undefined}
                >
                    <Button type="primary" style={{ marginTop: '10px' }}>
                        <ImportOutlined />
                        {translations.general.upload}
                    </Button>
                </Upload>}
                {potpisaniPrilog && <Button onClick={(): Promise<void> => openPDFPreview(potpisaniPrilog.base64, translations.general.prijedlog)}
                    style={{ width: '350px', overflow: 'hidden' }}
                >{potpisaniPrilog.fileName}</Button>}
                {!potpisaniPrilog && <Button
                    type="default"
                    onClick={savePotpisaniPdf}
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                    disabled={potpisaniPdf.length === 0}>
                    <ImportOutlined />
                    {translations.general.spremiDatoteku}
                </Button>}
                {potpisaniPrilog && <Button
                    type="default"
                    danger
                    onClick={deletePotpisaniPdf}
                    style={{ marginLeft: '10px' }}
                    disabled={!potpisaniPrilog}>
                    <DeleteOutlined />
                </Button>}
            </Form.Item>
        </Form>
    );
}

export default UploadSignedPdfForm;
