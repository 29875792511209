import moment from 'moment';
import { defaultFormat, defaultFormatWithTime } from '../config/constants';

export const formatDate = (date?: Date, format: string = defaultFormat): string => {
    if (!date) return '';

    return convertUtcToLocal(date)!.format(format);
};

export const formatDateTime = (date?: Date, format: string = defaultFormatWithTime): string => {
    if (!date) return '';

    return convertUtcToLocal(date)!.format(format);
};

export const convertUtcToLocal = (date?: Date): moment.Moment | undefined => {
    if (!date) return;

    // const utcOffset = moment(date).utcOffset();
    // return moment(date).add(utcOffset, 'minutes');
    return moment(date);
};