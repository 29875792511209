/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Form, Input, Divider, Checkbox, InputNumber, DatePicker, Button, Space } from "antd";
import { translations } from "../../../../config/translations";
import { OsobaTipString, VezaniSudionikTip } from "../../../../core/models/enum";
import { useApiContext } from "../../../../helpers/ApiContext";
import {
  customArrayValidationHelper,
  formItemLayoutDrawer,
} from "../../../../helpers/TableHelper";
import {
  getKamataTypeModel,
  getOsobaStringTipModel,
  getVjerodostojnaIspravaModel,
  getZatezneKamateModel,
} from "../../../../helpers/EnumHelper";
import { DropdownOption } from "../../../../core/models/DropdownOption";
import { IOsobaTipStringModel } from "../../../../utils/interfaces/IOsobaTipModel";
import { AutocompleteDropdown } from "../../../../components/autocomplete-dropdown";
import { confirmDropdownHelper, filterDropdownOptions } from "../../../../helpers/AutocompleteDropdownHelper";
import { customOibValidationHelper } from "../../../../helpers/OibHelper";
import { Props } from "./index";
import { DrawerButtons } from "../../../../components/drawer/buttons/DrawerButtons";
import { ZakonskiZastupnik } from "../../../../core/models/ZakonskiZastupnik";
import { PravnaOsnovaZaZastupanje } from "../../../../core/models/PravnaOsnovaZaZastupanje";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const UpdateExcelRowForm: React.FC<Props> = (props: Props) => {
  const { prijedlog, onClose, onSuccess } = props;
  const { postAsync, getAsync, userId } = useApiContext();
  const [osobaTipovi, setOsobaTipovi] = useState<DropdownOption[]>([]);
  const [vrsteVjerodostojneIsprave, setVrsteVjerodostojneIsprave] = useState<
    DropdownOption[]
  >([]);
  const [vrsteObracunKamatneStope, setVrsteObracunKamatneStope] = useState<
    DropdownOption[]
  >([]);
  const [vrsteZakonskeKamate, setVrsteZakonskeKamate] = useState<
    DropdownOption[]
  >([]);
  const [
    selectedVrstaVjerodostojneIsprave,
    setSelectedVrstaVjerodostojneIsprave,
  ] = useState<DropdownOption[] | undefined>();
  const [selectedOsobaTip1, setSelectedOsobaTip1] = useState<
    DropdownOption[] | undefined
  >();
  const [selectedOsobaTip2, setSelectedOsobaTip2] = useState<
    DropdownOption[] | undefined
  >();
  const [selectedObracunKamatneStope1, setSelectedObracunKamatneStope1] =
    useState<DropdownOption[] | undefined>();
  const [selectedObracunKamatneStope2, setSelectedObracunKamatneStope2] =
    useState<DropdownOption[] | undefined>();
  const [selectedVrstaZakonskeKamate1, setSelectedVrstaZakonskeKamate1] =
    useState<DropdownOption[] | undefined>();
  const [selectedVrstaZakonskeKamate2, setSelectedVrstaZakonskeKamate2] =
    useState<DropdownOption[] | undefined>();
  const [troskoviPostupkaKamataPovrat, setTroskoviPostupkaKamataPovrat] =
    useState<boolean>(false);
  const [dvaOvrsenika, setDvaOvrsenika] = useState<boolean>(false);
  const [dvijeTrazbine, setDvijeTrazbine] = useState<boolean>(false);
  const [zakonskiZastupnici, setZakonskiZastupnici] = useState<DropdownOption[]>([]);
  const [selectedZakonskiZastupnici, setSelectedZakonskiZastupnik] = useState<DropdownOption[] | undefined>();
  const [pravneOsnoveZaZastupanjeZakonskiZastupnik,
      setPravneOsnoveZaZastupanjeZakonskiZastupnik,
    ] = useState<DropdownOption[]>([]);
  const [selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik,
      setSelectedPravnaOsnovaZaZastupanjeZakonskiZastupnik,
    ] = useState<DropdownOption[] | undefined>();
  const [initialCountOfZakonskiZastupnici, setInitialCountOfZakonskiZastupnici] = useState<number>(0);



  useEffect(() => {
    setInitialValues();
    getDropdownOptions();
    getZakonskiZastupnici();
    getPravneOsnoveZaZastupanjeZakonskiZastupnik();
  }, []);

  useEffect(() => {
    setInitialOsobeValues();
  }, [osobaTipovi.length]);

  useEffect(() => {
    setInitialVjerodostojneValues();
  }, [vrsteVjerodostojneIsprave.length]);

  useEffect(() => {
    setInitialObracunKamatneValues();
  }, [vrsteObracunKamatneStope.length]);

  useEffect(() => {
    setInitialZakonskeKamateValues();
  }, [vrsteZakonskeKamate.length]);

  const setInitialValues = async () => {
    setDvaOvrsenika(prijedlog.dvaOvrsenika === "DA" ? true : false);
    setDvijeTrazbine(prijedlog.dvijeTrazbine === "DA" ? true : false);
    setTroskoviPostupkaKamataPovrat(
      prijedlog.troskoviPostupkaKamataPovrat === "DA" ? true : false
    );
  };

  const getPravneOsnoveZaZastupanjeZakonskiZastupnik = async () => {
    const data = await getAsync<PravnaOsnovaZaZastupanje[]>(
      "/EOvrheAPI/GetPravneOsnoveZaZastupanjeZakonskiZastupnik/" + userId
    );

    if(!data)
      return;

    const svePravneOsnoveZaZastupanje = data.map(
      (item: any): DropdownOption => ({
        id: item.id.toString(),
        name: `${item.naziv}`,
      })
    )

      setPravneOsnoveZaZastupanjeZakonskiZastupnik(svePravneOsnoveZaZastupanje);

    var postojecePravneOsnoveZastupnika = prijedlog.prijedlogZakonskiZastupnici?.map((x) => x.pravnaOsnovaZaZastupanjeId);
    if (postojecePravneOsnoveZastupnika) {
      setSelectedPravnaOsnovaZaZastupanjeZakonskiZastupnik(svePravneOsnoveZaZastupanje.filter((x) =>
          postojecePravneOsnoveZastupnika?.includes(parseInt(x.id))
        )
      );
    }
  };

  const getZakonskiZastupnici = async () => {
    const data = await getAsync<ZakonskiZastupnik[]>('/ZakonskiZastupnici/GetAllByUser/' + userId);

    if(!data)
      return;

    const sviZakonskiZastupniciDropdown = data.map(
      (item: ZakonskiZastupnik): DropdownOption => ({
        id: item.id!.toString(),
        name:
          item.tip === VezaniSudionikTip.FIZICKA_OSOBA
            ? `${item.ime} ${item.prezime}`
            : `${item.naziv}`,
      })
    );

    setZakonskiZastupnici(sviZakonskiZastupniciDropdown);

    var postojeciZakonskiZastupnici = prijedlog.prijedlogZakonskiZastupnici?.map(x => x.zakonskiZastupnikId)
    if(postojeciZakonskiZastupnici) {
      setSelectedZakonskiZastupnik(
        sviZakonskiZastupniciDropdown.filter((x) =>
          postojeciZakonskiZastupnici?.includes(parseInt(x.id))
        )
      );

      setInitialCountOfZakonskiZastupnici(postojeciZakonskiZastupnici.length);
    }
  };

  const getDropdownOptions = () => {
    const data = getOsobaStringTipModel();

    setOsobaTipovi(
      data.map(
        (item: IOsobaTipStringModel): DropdownOption => ({
          id: item.osobaTip,
          name: item.displayName,
        })
      )
    );

    setVrsteVjerodostojneIsprave(getVjerodostojnaIspravaModel());
    setVrsteObracunKamatneStope(getKamataTypeModel());
    setVrsteZakonskeKamate(getZatezneKamateModel());
  };

  const setInitialOsobeValues = async () => {
    if (prijedlog.tip) {
      setSelectedOsobaTip1(
        getOsobaTip(prijedlog.tip as unknown as OsobaTipString)
      );
    }
    if (prijedlog.tip2) {
      setSelectedOsobaTip2(
        getOsobaTip(prijedlog.tip2 as unknown as OsobaTipString)
      );
    }
  };

  const setInitialVjerodostojneValues = async () => {
    if (prijedlog.vrstaVjerodostojneIspraveId) {
      setSelectedVrstaVjerodostojneIsprave(
        getVrstaVjerodostojne(prijedlog.vrstaVjerodostojneIspraveId)
      );
    }
  };

  const setInitialObracunKamatneValues = async () => {
    if (prijedlog.obracunKamatneStope) {
      setSelectedObracunKamatneStope1(
        getVrstaObracunKamatne(prijedlog.obracunKamatneStope)
      );
    }
    if (prijedlog.obracunKamatneStope2) {
      setSelectedObracunKamatneStope2(
        getVrstaObracunKamatne(prijedlog.obracunKamatneStope2)
      );
    }
  };

  const setInitialZakonskeKamateValues = async () => {
    if (prijedlog.vrstaZakonskeZatezneKamateId) {
      setSelectedVrstaZakonskeKamate1(
        getVrstaZakonskeKamate(prijedlog.vrstaZakonskeZatezneKamateId)
      );
    }
    if (prijedlog.vrstaZakonskeZatezneKamateId2) {
      setSelectedVrstaZakonskeKamate2(
        getVrstaZakonskeKamate(prijedlog.vrstaZakonskeZatezneKamateId2)
      );
    }
  };

  const getOsobaTip = (tip: OsobaTipString): DropdownOption[] =>
    osobaTipovi.filter((o: DropdownOption): boolean => o.id === tip);

  const getVrstaVjerodostojne = (id: number): DropdownOption[] =>
    vrsteVjerodostojneIsprave.filter(
      (o: DropdownOption): boolean => o.id === id.toString()
    );

  const getVrstaObracunKamatne = (id: string): DropdownOption[] =>
    vrsteObracunKamatneStope.filter(
      (o: DropdownOption): boolean => o.id === id
    );

  const getVrstaZakonskeKamate = (id: number): DropdownOption[] =>
    vrsteZakonskeKamate.filter(
      (o: DropdownOption): boolean => o.id === id.toString()
    );

  const confirmZakonskiZastupnik = (option: DropdownOption[], index : number) => {
    const selectedZakonskiZastupnik = confirmDropdownHelper(option);

    if (selectedZakonskiZastupnik) {
      setSelectedZakonskiZastupnik(prevSelected => {
        const updated = [...(prevSelected || [])];
        updated[index + initialCountOfZakonskiZastupnici] = selectedZakonskiZastupnik[0];
        return updated;
      });
    }
  };

  const confirmPravnaOsobaZaZastupanjeZakonskiZastupnik = (option: DropdownOption[], index: number) => {
    const pravnaOsnovaZaZastupanjeZakonskiZastupnik = confirmDropdownHelper(option);
        
    if (pravnaOsnovaZaZastupanjeZakonskiZastupnik) {
      setSelectedPravnaOsnovaZaZastupanjeZakonskiZastupnik((prevSelected) => {
        const updated = [...(prevSelected || [])];
        updated[index + initialCountOfZakonskiZastupnici] = pravnaOsnovaZaZastupanjeZakonskiZastupnik[0];
        return updated;
      });
    }
  };

    const handleRemoveFromList = (remove: Function, name: number) => {
      remove(name);
      setSelectedZakonskiZastupnik((prev) =>(prev || []).filter((_, i) => i !== name + initialCountOfZakonskiZastupnici));
      setSelectedPravnaOsnovaZaZastupanjeZakonskiZastupnik((prev) =>(prev || []).filter((_, i) => i !== name + initialCountOfZakonskiZastupnici));
    };

    const handleDeleteExisting = (index: number) => {
      const updatedList = selectedZakonskiZastupnici?.filter((_, i) => i !== index);
      setInitialCountOfZakonskiZastupnici(initialCountOfZakonskiZastupnici - 1);
      setSelectedZakonskiZastupnik(updatedList);
    }

  const handleSave = async (values: any) => {
    const updateModel = {
      ...values,
      Id: prijedlog.id,
      obracunKamatneStope:
        selectedObracunKamatneStope1 && selectedObracunKamatneStope1?.length > 0
          ? selectedObracunKamatneStope1[0].id
          : undefined,
      obracunKamatneStope2:
        selectedObracunKamatneStope2 && selectedObracunKamatneStope2?.length > 0
          ? selectedObracunKamatneStope2[0].id
          : undefined,
      tip:
        selectedOsobaTip1 && selectedOsobaTip1?.length > 0
          ? selectedOsobaTip1[0].id
          : undefined,
      tip2:
        selectedOsobaTip2 && selectedOsobaTip2?.length > 0
          ? selectedOsobaTip2[0].id
          : undefined,
      vrstaVjerodostojneIsprave:
        selectedVrstaVjerodostojneIsprave &&
        selectedVrstaVjerodostojneIsprave?.length > 0
          ? Number(selectedVrstaVjerodostojneIsprave[0].id)
          : undefined,
      vrstaZakonskeZatezneKamate:
        selectedVrstaZakonskeKamate1 && selectedVrstaZakonskeKamate1?.length > 0
          ? Number(selectedVrstaZakonskeKamate1[0].id)
          : undefined,
      vrstaZakonskeZatezneKamate2:
        selectedVrstaZakonskeKamate2 && selectedVrstaZakonskeKamate2?.length > 0
          ? Number(selectedVrstaZakonskeKamate2[0].id)
          : undefined,
      troskoviPostupkaKamataPovrat: troskoviPostupkaKamataPovrat,
      dvijeTrazbine: dvijeTrazbine,
      dvaOvrsenika: dvaOvrsenika,
      datumVjerodostojneIsprave: values.datumVjerodostojneIsprave
        ? values.datumVjerodostojneIsprave.toDate()
        : undefined,
      datumPocetkaTijekaKamate: values.datumPocetkaTijekaKamate
        ? values.datumPocetkaTijekaKamate.toDate()
        : undefined,
      datumPocetkaTijekaKamate2: values.datumPocetkaTijekaKamate2
        ? values.datumPocetkaTijekaKamate2.toDate()
        : undefined,
      datumPrethodnoObracunateKamate: values.datumPrethodnoObracunateKamate
        ? values.datumPrethodnoObracunateKamate.toDate()
        : undefined,
      datumPrethodnoObracunateKamate2: values.datumPrethodnoObracunateKamate2
        ? values.datumPrethodnoObracunateKamate2.toDate()
        : undefined,
      zakonskiZastupniciOvrsenika: selectedZakonskiZastupnici && selectedZakonskiZastupnici?.length > 0 
        ? selectedZakonskiZastupnici.map((zastupnik, index) => ({
          zakonskiZastupnikId: zastupnik.id,
          pravnaOsnovaZaZastupanjeId: selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik && selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik[index]?.id,
          prijedlogId: prijedlog.id
        }))
        : undefined
    };

    const result = await postAsync(`/Prijedlozi/Update`, updateModel);

    if (result) {
      onSuccess();
    }
  };

  return (
    <Form onFinish={handleSave} {...formItemLayoutDrawer}>
      <DrawerButtons onCancelAction={() => onClose()} />
      <Divider>{translations.general.essentialData}</Divider>
      <Form.Item
        name="interniId"
        label={translations.excelTable.interniId}
        initialValue={prijedlog.interniId}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="ostaliPrivitci"
        label={translations.excelTable.ostaliPrivitci}
        initialValue={prijedlog.ostaliPrivitci}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="ostaliPrivitciOpisi"
        label={translations.excelTable.ostaliPrivitciOpisi}
        initialValue={prijedlog.ostaliPrivitciOpisi}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="oznakaVjerodostojneIsprave"
        label={translations.excelTable.oznakaVjerodostojneIsprave}
        initialValue={prijedlog.oznakaVjerodostojneIsprave}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="vrstaVjerodostojneIsprave"
        label={translations.excelTable.vrstaVjerodostojneIsprave}
        rules={[
          {
            required: true,
            validator: (_rule: any, value: any, callback: any): void =>
              customArrayValidationHelper(
                _rule,
                callback,
                translations.general.requiredField,
                selectedVrstaVjerodostojneIsprave
              ),
          },
        ]}
      >
        <AutocompleteDropdown
          placeholder={translations.excelTable.vrstaVjerodostojneIsprave}
          getOptionsFrontend={(value: string): DropdownOption[] =>
            filterDropdownOptions(value, vrsteVjerodostojneIsprave)
          }
          initialValues={selectedVrstaVjerodostojneIsprave}
          confirmDirty={(value: DropdownOption[]): void => {
            setSelectedVrstaVjerodostojneIsprave(value);
          }}
        />
      </Form.Item>
      <Form.Item
        name="datumVjerodostojneIsprave"
        label={translations.excelTable.datumVjerodostojneIsprave}
        initialValue={
          prijedlog.datumVjerodostojneIsprave
            ? moment(prijedlog.datumVjerodostojneIsprave)
            : undefined
        }
        rules={[
          {
            type: "object" as const,
            required: true,
            message: translations.general.selectDate,
          },
        ]}
      >
        <DatePicker format="DD.MM.yyyy." />
      </Form.Item>
      <Form.Item
        name="opisTrazbine"
        label={translations.excelTable.opisTrazbine}
        initialValue={prijedlog.opisTrazbine}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="troskoviPostupkaKamataPovrat"
        label={translations.excelTable.troskoviPostupkaKamataPovrat}
      >
        <Checkbox
          checked={troskoviPostupkaKamataPovrat}
          onChange={(value): void => {
            setTroskoviPostupkaKamataPovrat(value.target.checked);
          }}
        />
      </Form.Item>
      <Divider>{translations.excelTable.ovršenik1}</Divider>
      <Form.Item
        name="osobaTipovi"
        label={translations.ovrhovoditelj.tip}
        rules={[
          {
            required: true,
            validator: (_rule: any, value: any, callback: any): void =>
              customArrayValidationHelper(
                _rule,
                callback,
                translations.general.requiredField,
                selectedOsobaTip1
              ),
          },
        ]}
      >
        <AutocompleteDropdown
          placeholder={translations.ovrhovoditelj.unesiteTip}
          getOptionsFrontend={(value: string): DropdownOption[] =>
            filterDropdownOptions(value, osobaTipovi)
          }
          initialValues={selectedOsobaTip1}
          confirmDirty={(value: DropdownOption[]): void => {
            setSelectedOsobaTip1(value);
          }}
        />
      </Form.Item>
      <Form.Item
        name="oib"
        label={translations.excelTable.oIB}
        initialValue={prijedlog.oib}
        rules={[
          {
            validator: (_rule: any, value: any, callback: any): void =>
              customOibValidationHelper(
                _rule,
                callback,
                "Neispravan OIB",
                value
              ),
          },
        ]}
      >
        <Input />
      </Form.Item>
      {selectedOsobaTip1 &&
        selectedOsobaTip1?.length > 0 &&
        selectedOsobaTip1[0].id === OsobaTipString.FIZICKA_OSOBA.toString() && (
          <>
            <Form.Item
              name="ime"
              label={translations.excelTable.ime}
              initialValue={prijedlog.ime}
              rules={[
                {
                  required:
                    selectedOsobaTip1[0].id ===
                    OsobaTipString.FIZICKA_OSOBA.toString(),
                  message: translations.general.requiredField,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="prezime"
              label={translations.ovrhovoditelj.prezime}
              initialValue={prijedlog.prezime}
              rules={[
                {
                  required:
                    selectedOsobaTip1[0].id ===
                    OsobaTipString.FIZICKA_OSOBA.toString(),
                  message: translations.general.requiredField,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}
      {selectedOsobaTip1 &&
        selectedOsobaTip1?.length > 0 &&
        selectedOsobaTip1[0].id === OsobaTipString.PRAVNA_OSOBA.toString() && (
          <Form.Item
            name="naziv"
            label={translations.excelTable.naziv}
            initialValue={prijedlog.naziv}
            rules={[
              {
                required:
                  selectedOsobaTip1[0].id ===
                  OsobaTipString.PRAVNA_OSOBA.toString(),
                message: translations.general.requiredField,
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}
      <Form.Item
        name="adresa"
        label={translations.excelTable.adresa}
        initialValue={prijedlog.adresa}
        rules={[
          {
            required: true,
            message: translations.general.requiredField,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="postanskiBroj"
        label={translations.excelTable.postanskiBroj}
        initialValue={prijedlog.postanskiBroj}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mjesto"
        label={translations.excelTable.mjesto}
        initialValue={prijedlog.mjesto}
        rules={[
          {
            required: true,
            message: translations.general.requiredField,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="nazivObrtaOpgaSamostalneDjelatnosti"
        label={translations.excelTable.nazivObrtaOpgaSamostalneDjelatnosti}
        initialValue={prijedlog.nazivObrtaOpgaSamostalneDjelatnosti}
      >
        <Input />
      </Form.Item>
      <Form.Item label={translations.general.zakonskiZastupnik}>
        {selectedZakonskiZastupnici &&
          selectedZakonskiZastupnici.slice(0, initialCountOfZakonskiZastupnici).map((item, index) => (
            <div key={index} style={{ display: 'flex', justifyContent: "space-between" }}>
              <Form.Item style={{ width: 250 }}>
                <Input value={item.name} readOnly bordered={false}/>
              </Form.Item >
              <Form.Item label={translations.general.pravnaOsnovaZaZastupanje} 
                        style={{ marginLeft: 21.2 }}
                        rules={[
                          {
                            required: true,
                            validator: (
                              _rule: any,
                              value: any,
                              callback: any
                            ): void =>
                              customArrayValidationHelper(
                                _rule,
                                callback,
                                "Pravna osnova za zastupanje je obavezno polje!",
                                selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik
                              ),
                          },
                        ]}>
                <Input style={{ width: 295 }} value={
                  selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik && 
                  selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik[index]?.name} readOnly bordered={false}/>
              </Form.Item>
              <MinusCircleOutlined style={{ marginTop: 8 }} onClick={() => handleDeleteExisting(index)}/>
            </div>
          ))
        }
        <Form.List name="zastupniciOvrsenika">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => {
                return (
                  <Space key={key} align="baseline" style={{ width: "100%", justifyContent: "space-between" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "zakonskiZastupnikOvrsenika"]}
                      style={{ width: 250 }}
                    >
                      <AutocompleteDropdown
                        placeholder={translations.general.zakonskiZastupnici}
                        getOptionsFrontend={(value: string): DropdownOption[] =>
                          filterDropdownOptions(value, zakonskiZastupnici)
                        }
                        confirmDirty={(option: DropdownOption[]) =>
                          confirmZakonskiZastupnik(option, index)
                        }
                        isAllowedToClear
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "pravnaOsnovaZaZastupanjePunomocnik"]}
                      label={translations.general.pravnaOsnovaZaZastupanje}
                      rules={[
                        {
                          required: true,
                          validator: (
                            _rule: any,
                            value: any,
                            callback: any
                          ): void =>
                            customArrayValidationHelper(
                              _rule,
                              callback,
                              "Pravna osnova za zastupanje je obavezno polje!",
                              selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik
                            ),
                        },
                      ]}
                    >
                      <AutocompleteDropdown
                        style={{ width: 295 }}
                        placeholder={
                          translations.general.pravneOsnoveZaZastupanje
                        }
                        getOptionsFrontend={(value: string): DropdownOption[] =>
                          filterDropdownOptions(
                            value,
                            pravneOsnoveZaZastupanjeZakonskiZastupnik
                          )
                        }
                        confirmDirty={(option: DropdownOption[]) =>
                          confirmPravnaOsobaZaZastupanjeZakonskiZastupnik(
                            option,
                            index
                          )
                        }
                        isAllowedToClear
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => handleRemoveFromList(remove, name)}
                    />
                  </Space>
                );
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {translations.zakonskiZastupnik.addZakonskiZastupnik}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item
        name="dvaOvrsenika"
        label={translations.excelTable.dvaOvrsenika}
        initialValue={prijedlog.dvaOvrsenika}
      >
        <Checkbox
          checked={dvaOvrsenika}
          onChange={(value): void => {
            setDvaOvrsenika(value.target.checked);
          }}
        />
      </Form.Item>
      {dvaOvrsenika && (
        <>
          <Divider>{translations.excelTable.ovršenik2}</Divider>
          <Form.Item
            name="osobaTipovi2"
            label={translations.ovrhovoditelj.tip}
            rules={[
              {
                required: true,
                validator: (_rule: any, value: any, callback: any): void =>
                  customArrayValidationHelper(
                    _rule,
                    callback,
                    translations.general.requiredField,
                    selectedOsobaTip2
                  ),
              },
            ]}
          >
            <AutocompleteDropdown
              placeholder={translations.ovrhovoditelj.unesiteTip}
              getOptionsFrontend={(value: string): DropdownOption[] =>
                filterDropdownOptions(value, osobaTipovi)
              }
              initialValues={selectedOsobaTip2}
              confirmDirty={(value: DropdownOption[]): void => {
                setSelectedOsobaTip2(value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="oib2"
            label={translations.excelTable.oIB}
            initialValue={prijedlog.oib2}
            rules={[
              {
                required: true,
                validator: (_rule: any, value: any, callback: any): void =>
                  customOibValidationHelper(
                    _rule,
                    callback,
                    "Neispravan OIB",
                    value
                  ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          {selectedOsobaTip2 &&
            selectedOsobaTip2?.length > 0 &&
            selectedOsobaTip2[0].id ===
              OsobaTipString.FIZICKA_OSOBA.toString() && (
              <>
                <Form.Item
                  name="ime2"
                  label={translations.excelTable.ime}
                  initialValue={prijedlog.ime2}
                  rules={[
                    {
                      required:
                        selectedOsobaTip2[0].id ===
                        OsobaTipString.FIZICKA_OSOBA.toString(),
                      message: translations.general.requiredField,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="prezime2"
                  label={translations.ovrhovoditelj.prezime}
                  initialValue={prijedlog.prezime2}
                  rules={[
                    {
                      required:
                        selectedOsobaTip2[0].id ===
                        OsobaTipString.FIZICKA_OSOBA.toString(),
                      message: translations.general.requiredField,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
          {selectedOsobaTip2 &&
            selectedOsobaTip2?.length > 0 &&
            selectedOsobaTip2[0].id ===
              OsobaTipString.PRAVNA_OSOBA.toString() && (
              <Form.Item
                name="naziv2"
                label={translations.excelTable.naziv}
                initialValue={prijedlog.naziv2}
                rules={[
                  {
                    required:
                      selectedOsobaTip2[0].id ===
                      OsobaTipString.PRAVNA_OSOBA.toString(),
                    message: translations.general.requiredField,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
          <Form.Item
            name="adresa2"
            label={translations.excelTable.adresa}
            initialValue={prijedlog.adresa2}
            rules={[
              {
                required: true,
                message: translations.general.requiredField,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="postanskiBroj2"
            label={translations.excelTable.postanskiBroj}
            initialValue={prijedlog.postanskiBroj2}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mjesto2"
            label={translations.excelTable.mjesto}
            initialValue={prijedlog.mjesto2}
            rules={[
              {
                required: true,
                message: translations.general.requiredField,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="nazivObrtaOpgaSamostalneDjelatnosti2"
            label={translations.excelTable.nazivObrtaOpgaSamostalneDjelatnosti}
            initialValue={prijedlog.nazivObrtaOpgaSamostalneDjelatnosti2}
          >
            <Input />
          </Form.Item>
        </>
      )}
      <Divider>{translations.excelTable.trazbina1}</Divider>
      {(!prijedlog.vjerodostojneIsprave ||
        prijedlog.vjerodostojneIsprave?.length === 0) && (
        <>
          <Form.Item
            name="glavnica"
            label={translations.excelTable.glavnica}
            initialValue={prijedlog.glavnica}
            rules={[
              {
                required: true,
                message: translations.general.requiredField,
              },
            ]}
          >
            <InputNumber className="custom-antd-number-input" />
          </Form.Item>
          <Form.Item
            name="vrstaZakonskeKamate"
            label={translations.excelTable.vrstaZakonskeZatezneKamate}
            rules={[
              {
                required: true,
                validator: (_rule: any, value: any, callback: any): void =>
                  customArrayValidationHelper(
                    _rule,
                    callback,
                    translations.general.requiredField,
                    selectedVrstaZakonskeKamate1
                  ),
              },
            ]}
          >
            <AutocompleteDropdown
              placeholder={translations.excelTable.vrstaZakonskeZatezneKamate}
              getOptionsFrontend={(value: string): DropdownOption[] =>
                filterDropdownOptions(value, vrsteZakonskeKamate)
              }
              initialValues={selectedVrstaZakonskeKamate1}
              confirmDirty={(value: DropdownOption[]): void => {
                setSelectedVrstaZakonskeKamate1(value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="obracunKamatneStope"
            label={translations.excelTable.obracunKamatneStope}
            rules={[
              {
                required: true,
                validator: (_rule: any, value: any, callback: any): void =>
                  customArrayValidationHelper(
                    _rule,
                    callback,
                    translations.general.requiredField,
                    selectedObracunKamatneStope1
                  ),
              },
            ]}
          >
            <AutocompleteDropdown
              placeholder={translations.excelTable.obracunKamatneStope}
              getOptionsFrontend={(value: string): DropdownOption[] =>
                filterDropdownOptions(value, vrsteObracunKamatneStope)
              }
              initialValues={selectedObracunKamatneStope1}
              confirmDirty={(value: DropdownOption[]): void => {
                setSelectedObracunKamatneStope1(value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="ugovornaKamatnaStopa"
            label={translations.excelTable.ugovornaKamatnaStopa}
            initialValue={prijedlog.ugovornaKamatnaStopa}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="kamataPoRanijemZakonuOpis"
            label={translations.excelTable.kamataPoRanijemZakonuOpis}
            initialValue={prijedlog.kamataPoRanijemZakonuOpis}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="kamata"
            label={translations.excelTable.kamata}
            initialValue={prijedlog.kamata}
          >
            <InputNumber className="custom-antd-number-input" />
          </Form.Item>
          <Form.Item
            name="datumPocetkaTijekaKamate"
            label={translations.excelTable.datumPocetkaTijekaKamate}
            initialValue={
              prijedlog.datumPocetkaTijekaKamate
                ? moment(prijedlog.datumPocetkaTijekaKamate)
                : undefined
            }
            rules={[
              {
                type: "object" as const,
                required: true,
                message: translations.general.selectDate,
              },
            ]}
          >
            <DatePicker format="DD.MM.yyyy." />
          </Form.Item>
          <Form.Item
            name="prethodnoObracunataKamata"
            label={translations.excelTable.prethodnoObracunataKamata}
            initialValue={prijedlog.prethodnoObracunataKamata}
          >
            <InputNumber className="custom-antd-number-input" />
          </Form.Item>
          <Form.Item
            name="datumPrethodnoObracunateKamate"
            label={translations.excelTable.datumPrethodnoObracunateKamate}
            initialValue={
              prijedlog.datumPrethodnoObracunateKamate
                ? moment(prijedlog.datumPrethodnoObracunateKamate)
                : undefined
            }
          >
            <DatePicker format="DD.MM.yyyy." />
          </Form.Item>
        </>
      )}
      <Form.Item
        name="brojRacuna"
        label={translations.excelTable.brojRacuna}
        initialValue={prijedlog.brojRacuna}
        rules={[
          {
            required: true,
            message: translations.general.requiredField,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="model"
        label={translations.excelTable.model}
        initialValue={prijedlog.model}
        rules={[
          {
            required: true,
            message: translations.general.requiredField,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="pozivNaBroj"
        label={translations.excelTable.pozivNaBroj}
        initialValue={prijedlog.pozivNaBroj}
        rules={[
          {
            required: true,
            message: translations.general.requiredField,
          },
        ]}
      >
        <Input />
      </Form.Item>
      {(!prijedlog.vjerodostojneIsprave ||
        prijedlog.vjerodostojneIsprave?.length === 0) && (
        <>
          <Form.Item
            name="dvijeTrazbine"
            label={translations.excelTable.dvijeTrazbine}
            initialValue={prijedlog.dvijeTrazbine}
          >
            <Checkbox
              checked={dvijeTrazbine}
              onChange={(value): void => {
                setDvijeTrazbine(value.target.checked);
              }}
            />
          </Form.Item>
        </>
      )}
      {dvijeTrazbine && (
        <>
          <Divider>{translations.excelTable.trazbina2}</Divider>
          <Form.Item
            name="glavnica2"
            label={translations.excelTable.glavnica2}
            initialValue={prijedlog.glavnica2}
            rules={[
              {
                required: true,
                message: translations.general.requiredField,
              },
            ]}
          >
            <InputNumber className="custom-antd-number-input" />
          </Form.Item>
          <Form.Item
            name="vrstaZakonskeKamate2"
            label={translations.excelTable.vrstaZakonskeZatezneKamate}
            rules={[
              {
                required: true,
                validator: (_rule: any, value: any, callback: any): void =>
                  customArrayValidationHelper(
                    _rule,
                    callback,
                    translations.general.requiredField,
                    selectedVrstaZakonskeKamate2
                  ),
              },
            ]}
          >
            <AutocompleteDropdown
              placeholder={translations.excelTable.vrstaZakonskeZatezneKamate}
              getOptionsFrontend={(value: string): DropdownOption[] =>
                filterDropdownOptions(value, vrsteZakonskeKamate)
              }
              initialValues={selectedVrstaZakonskeKamate2}
              confirmDirty={(value: DropdownOption[]): void => {
                setSelectedVrstaZakonskeKamate2(value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="obracunKamatneStope2"
            label={translations.excelTable.obracunKamatneStope}
            rules={[
              {
                required: true,
                validator: (_rule: any, value: any, callback: any): void =>
                  customArrayValidationHelper(
                    _rule,
                    callback,
                    translations.general.requiredField,
                    selectedObracunKamatneStope2
                  ),
              },
            ]}
          >
            <AutocompleteDropdown
              placeholder={translations.excelTable.obracunKamatneStope}
              getOptionsFrontend={(value: string): DropdownOption[] =>
                filterDropdownOptions(value, vrsteObracunKamatneStope)
              }
              initialValues={selectedObracunKamatneStope2}
              confirmDirty={(value: DropdownOption[]): void => {
                setSelectedObracunKamatneStope2(value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="ugovornaKamatnaStopa2"
            label={translations.excelTable.ugovornaKamatnaStopa}
            initialValue={prijedlog.ugovornaKamatnaStopa2}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="kamataPoRanijemZakonuOpis2"
            label={translations.excelTable.kamataPoRanijemZakonuOpis}
            initialValue={prijedlog.kamataPoRanijemZakonuOpis2}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="kamata2"
            label={translations.excelTable.kamata}
            initialValue={prijedlog.kamata2}
          >
            <InputNumber className="custom-antd-number-input" />
          </Form.Item>
          <Form.Item
            name="datumPocetkaTijekaKamate2"
            label={translations.excelTable.datumPocetkaTijekaKamate}
            initialValue={
              prijedlog.datumPocetkaTijekaKamate2
                ? moment(prijedlog.datumPocetkaTijekaKamate2)
                : undefined
            }
            rules={[
              {
                type: "object" as const,
                required: true,
                message: translations.general.selectDate,
              },
            ]}
          >
            <DatePicker format="DD.MM.yyyy." />
          </Form.Item>
          <Form.Item
            name="prethodnoObracunataKamata2"
            label={translations.excelTable.prethodnoObracunataKamata}
            initialValue={prijedlog.prethodnoObracunataKamata2}
          >
            <InputNumber className="custom-antd-number-input" />
          </Form.Item>
          <Form.Item
            name="datumPrethodnoObracunateKamate2"
            label={translations.excelTable.datumPrethodnoObracunateKamate}
            initialValue={
              prijedlog.datumPrethodnoObracunateKamate2
                ? moment(prijedlog.datumPrethodnoObracunateKamate2)
                : undefined
            }
          >
            <DatePicker format="DD.MM.yyyy." />
          </Form.Item>
          <Form.Item
            name="brojRacuna2"
            label={translations.excelTable.brojRacuna}
            initialValue={prijedlog.brojRacuna2}
            rules={[
              {
                required: true,
                message: translations.general.requiredField,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="model2"
            label={translations.excelTable.model}
            initialValue={prijedlog.model2}
            rules={[
              {
                required: true,
                message: translations.general.requiredField,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="pozivNaBroj2"
            label={translations.excelTable.pozivNaBroj}
            initialValue={prijedlog.pozivNaBroj2}
            rules={[
              {
                required: true,
                message: translations.general.requiredField,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      )}
      <Divider></Divider>
      <Form.Item
        name="interniBrojPredmeta"
        label={translations.excelTable.interniBrojPredmeta}
        initialValue={prijedlog.interniBrojPredmeta}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="oznakaSredstvaDuga"
        label={translations.excelTable.oznakaSredstvaDuga}
        initialValue={prijedlog.oznakaSredstvaDuga}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default UpdateExcelRowForm;
