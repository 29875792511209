import { translations } from "../config/translations";
import { PrilogType } from "../core/models/enum";

export const getBulkUploadDrawerTitle = (type: PrilogType): string => {
    switch (type) {
        case PrilogType.VjerodostojnaIsprava:
            return translations.general.addBulkPrivitakVjerodostojne;
        case PrilogType.Ostalo:
            return translations.general.addBulkPrivitakOstalo;
        case PrilogType.PotpisaniPDF:
            return translations.general.addBulkPrijedlogSigned;
        default:
            return translations.general.addBulkPrivitakVjerodostojne;
    }
}

export const getBulkUploadDrawerLabel = (type: PrilogType): string => {
    switch (type) {
        case PrilogType.VjerodostojnaIsprava:
            return translations.general.vjerodostojneIsprave;
        case PrilogType.Ostalo:
            return translations.general.ostaloPrivitci;
        case PrilogType.PotpisaniPDF:
            return translations.general.signedPrijedlozi;
        default:
            return translations.general.vjerodostojneIsprave;
    }
}