/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Input, PageHeader } from 'antd';
import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { AxiosResponse } from "axios";
import { translations } from '../../config/translations';
import { TokenResponse } from '../../core/models/TokenResponse';
import { formItemLayout, tailFormItemLayout } from '../../helpers/TableHelper';
import { useApiContext } from '../../helpers/ApiContext';
import { Props } from './index';

const { TextArea } = Input;

const Token: React.FC<Props> = (props: Props) => {
    const { postAsync, getAsync, userId } = useApiContext();
    const [oldToken, setOldToken] = useState<string | undefined>();
    const [newToken, setNewToken] = useState<string | undefined>();

    useEffect(() => {
        getToken();
    }, [userId]);

    const getToken = async () => {
        if (userId !== "") {
            const data = await getAsync<TokenResponse>('/Users/GetToken/' + userId);

            if (data) {
                setOldToken(data.token);
            }
        };
    };

    const handleSave = async () => {
        let formData = new FormData();

        formData.append("applicationUserId", userId);
        formData.append("token", newToken!);

        const data = await postAsync<AxiosResponse>('/Users/SaveToken', formData, undefined, translations.token.successSavingNewToken, translations.token.errorSavingNewToken);

        if (data) {
            setNewToken(undefined);
            getToken();
        };
    };

    return (
        <div>
            <PageHeader
                title={translations.token.title}
            />
            <Form style={{ marginLeft: '40px', marginTop: '20px', width: '800px' }} {...formItemLayout}>
                <FormItem label={translations.token.oldToken}>
                    <TextArea
                        autoSize={{ minRows: 1 }}
                        placeholder={translations.token.oldTokenPlaceholder}
                        value={oldToken}
                        disabled={true}
                    >
                    </TextArea>
                </FormItem>
                <FormItem label={translations.token.newToken}>
                    <TextArea
                        autoSize={{ minRows: 1 }}
                        placeholder={translations.token.newTokenPlaceholder}
                        value={newToken}
                        onChange={
                            (input): void => setNewToken(input.target.value)
                        }
                    >
                    </TextArea>
                </FormItem>
                <FormItem {...tailFormItemLayout}>
                    <Button
                        type="primary"
                        onClick={handleSave}
                        disabled={!newToken}>
                        {translations.token.saveBtn}
                    </Button>
                </FormItem>
            </Form>
        </div>
    );
}

export default Token;