import { RouteComponentProps, withRouter } from 'react-router-dom';
import UploadSignedPdfForm from './UploadSignedPdfForm';

interface OwnProps {
    onClose: () => void;
    onSuccess: () => void;
    prijedlogId: string;
}

export type Props = OwnProps & RouteComponentProps;

export default withRouter(UploadSignedPdfForm);
