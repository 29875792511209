import React from "react";
import { Layout as AntLayout } from "antd";
import SideMenu from "./SideMenu";
import Routes from "../config/routes";
import { LoginMenu } from "./api-authorization/LoginMenu";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import authService from "./api-authorization/AuthorizeService";

const { Content, Sider } = AntLayout;

interface State {
  collapsed: boolean;
  isAuthenticated: boolean;
}

type Props = RouteComponentProps;

class Layout extends React.Component<Props, State> {
  _subscription: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      collapsed: false,
      isAuthenticated: false,
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated] = await Promise.all([
      authService.isAuthenticated(),
    ]);
    this.setState({
      isAuthenticated,
    });
  }

  onCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { collapsed, isAuthenticated } = this.state;

    return (
      <AntLayout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          className="no-print"
        >
          <div className="logo">
            <Link to={Routes.ROUTE_DASHBOARD}>
              <img src={collapsed ? "/images/logo-collapsed.svg" : "/images/logo.svg"} alt="icon-logo" />
            </Link>
          </div>
          {isAuthenticated && <SideMenu />}
          <LoginMenu />
        </Sider>

        <AntLayout>
          <Content>{this.props.children}</Content>
        </AntLayout>
      </AntLayout>
    );
  }
}

export default withRouter(Layout);
