
import { oibValidate } from "./Validators";

export const customOibValidationHelper = (
    _rule: any,
    callback: any,
    message: string,
    entityToValidate?: string
): void => {
    const validOib = validateOib(entityToValidate)
    if (!validOib && entityToValidate && entityToValidate.length !== 0) {
        callback(message);
    } else {
        callback();
    }
};


const validateOib = (newOib?: string) => {

    if (newOib?.length === 11) {

        const oibIsValid = oibValidate(newOib);

        return oibIsValid;

    } else {

        return false;

    }
};