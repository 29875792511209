import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { AuthorizationRoutes } from './ApiAuthorizationConstants';
import { translations } from '../../config/translations';
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';

interface Props { }

interface State {
    isAuthenticated: boolean;
    userName?: string;
}

export class LoginMenu extends React.Component<Props, State> {
    _subscription: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            isAuthenticated: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser()
        ]);
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated } = this.state;

        return (
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['1']}
                className={isAuthenticated ? 'bottom-menu' : ''}
            >
                {isAuthenticated ? (
                    <Menu.Item key="logout" icon={<LogoutOutlined />}>
                        <Link
                            to={{
                                pathname: `${AuthorizationRoutes.LogOut}`,
                                state: {
                                    local: true,
                                    returnUrl: AuthorizationRoutes.DefaultLoginRedirectPath,
                                },
                            }}
                        >
                            {translations.general.logout}
                        </Link>
                    </Menu.Item>
                ) : (
                    <Menu.Item key="login" icon={<LoginOutlined />}>
                        <Link to={AuthorizationRoutes.Login}>{translations.general.login}</Link>
                    </Menu.Item>
                )}
            </Menu>
        );
    }
}
