import { RouteComponentProps, withRouter } from 'react-router-dom';
import ExcelTable from './ExcelTable';

interface OwnProps {
    excelFileId: string;
    shouldUpdate: boolean;
    setShouldUpdateState: (newState: boolean) => void;
    setShouldConditionsUpdateState: (newState: boolean) => void;
    opomeneUvjet: boolean;
    showDvijeTrazbine: boolean;
    showDvaOvrsenika: boolean;
}

export type Props = OwnProps & RouteComponentProps;

export default withRouter(ExcelTable);
