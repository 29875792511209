/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Drawer, PageHeader, Modal, Tooltip, Popover } from 'antd';
import { AuditOutlined, DeleteOutlined, DiffOutlined, DownloadOutlined, EditOutlined, FileAddOutlined, FileTextOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import { AxiosResponse } from "axios";
import Routes from '../../config/routes';
import { translations } from '../../config/translations';
import { DRAWER_MEDIUM_WIDTH } from '../../config/constants';
import { ExcelFile } from '../../core/models/ExcelFile';
import { PdfBase64 } from '../../core/models/PdfBase64';
import { DrawerState, PrilogType } from '../../core/models/enum';
import { useApiContext } from '../../helpers/ApiContext';
import { getBulkUploadDrawerTitle } from '../../helpers/TitleHelper';
import { showSuccess } from "../../helpers/NotificationHelper";
import { ButtonDropdownSettings } from '../../helpers/TableHelper';
import ExcelAttachmentForm from './forms/excelAttachment';
import BulkExcelAttachmentForm from './forms/bulkExcelAttachment';
import ExcelTable from './tables';
import { Props } from './index';

const Excel: React.FC<Props> = (props: Props) => {
    const { match: { params: { excelFileId } } } = props;
    const history = useHistory();
    const { getAsync, postAsync, deleteAsync, userId } = useApiContext();
    const [pdfPrivitciDrawerState, setPdfPrivitciDrawerState] = useState<DrawerState>(DrawerState.Closed);
    const [excelTrazbineDrawerState, setExcelTrazbineDrawerState] = useState<DrawerState>(DrawerState.Closed);
    const [showGenerateModal, setShowGenerateModal] = useState<boolean>(false);
    const [showSignModal, setShowSignModal] = useState<boolean>(false);
    const [showSendModal, setShowSendModal] = useState<boolean>(false);
    const [showDownloadChunkPrijedloziModal, setShowDownloadChunkPrijedloziModal] = useState<boolean>(false);
    const [showDownloadChunkFinalModal, setShowDownloadChunkFinalModal] = useState<boolean>(false);
    const [showDownloadOpomeneModal, setShowDownloadOpomeneModal] = useState<boolean>(false);
    const [showOpomenaModal, setShowOpomenaModal] = useState<boolean>(false);
    const [punomocnikIds, setPunomocnikIds] = useState<string[] | string | undefined>();
    const [zakonskiZastupnikId, setZakonskiZastupnikId] = useState<string | undefined>();
    const [generirajPrijedlogeUvjet, setGenerirajPrijedlogeUvjet] = useState(false);
    const [potpisiUvjet, setPotpisiUvjet] = useState(false);
    const [posaljiUvjet, setPosaljiUvjet] = useState(false);
    const [opomenaUvjet, setOpomenaUvjet] = useState(false);
    const [opomenaAkcijeUvjet, setOpomenaAkcijeUvjet] = useState(false);
    const [showDvijeTrazbine, setShowDvijeTrazbine] = useState(false);
    const [showDvaOvrsenika, setShowDvaOvrsenika] = useState(false);
    const [brojNegeneriranihPrijedloga, setBrojNegeneriranihPrijedloga] = useState<number | undefined>();
    const [ukupanBrojPrijedloga, setUkupanBrojPrijedloga] = useState<number | undefined>();
    const [downloadChunkPrijedloziUvjet, setDownloadChunkPrijedloziUvjet] = useState(false);
    const [downloadChunkFinalUvjet, setDownloadChunkFinalUvjet] = useState(false);
    const [downloadOpomeneUvjet, setDownloadOpomeneUvjet] = useState(false);
    const [shouldTableUpdate, setShouldTableUpdate] = useState(false);
    const [shouldConditionsUpdate, setShouldConditionsUpdate] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleteUvjet, setDeleteUvjet] = useState(false);
    const [pdfBulkPrivitciDrawerState, setBulkPdfPrivitciDrawerState] = useState<DrawerState>(DrawerState.Closed);
    const [pdfBulkPrivitciDrawerType, setBulkPdfPrivitciDrawerType] = useState<PrilogType>(PrilogType.VjerodostojnaIsprava);

    useEffect(() => {
        confirmExcelFile();
    }, [userId, excelFileId, shouldConditionsUpdate]);

    const confirmExcelFile = async () => {
        if (excelFileId) {
            const data = await getAsync<ExcelFile>('/Excel/GetById/' + excelFileId);
            setUkupanBrojPrijedloga(data.ukupniBrojPrijedloga);
            setBrojNegeneriranihPrijedloga(data.negeneriranihPrijedloga);
            setPosaljiUvjet(data.imaPotpisanih!);
            setDeleteUvjet(!data.imaPoslanihNaSud!);
            setOpomenaAkcijeUvjet(data.imaOpomene!);
            setShowDvijeTrazbine(data.dvijeTrazbine!);
            setShowDvaOvrsenika(data.dvaOvrsenika!);

            if (data.negeneriranihPrijedloga! > 0 && (data.generiranihOpomena! !== data.ukupniBrojPrijedloga)) {
                setOpomenaUvjet(true);
            } else if (data.generiranihOpomena! === data.ukupniBrojPrijedloga) {
                setOpomenaUvjet(false);
            }

            if (data.nedodaniObavezniPrilozi! > 0) {
                if (data.ukupniBrojPrijedloga! - data.nedodaniObavezniPrilozi! > 0) {
                    setGenerirajPrijedlogeUvjet(true);
                } else {
                    setGenerirajPrijedlogeUvjet(false);
                }
            } else if (data.nedodaniObavezniPrilozi === 0) {
                setGenerirajPrijedlogeUvjet(true);
            }

            if (data.negeneriranihPrijedloga! > 0 && data.negeneriranihPrijedloga !== data.ukupniBrojPrijedloga) {
                setPotpisiUvjet(true);
            } else if (data.negeneriranihPrijedloga === 0 && data.nedodaniObavezniPrilozi === 0) {
                setPotpisiUvjet(true);
            } else if (data.negeneriranihPrijedloga === 0) {
                setPotpisiUvjet(false);
            }

            if (data.negeneriranihPrijedloga! < data.ukupniBrojPrijedloga!) {
                setDownloadChunkPrijedloziUvjet(true);
            }

            setDownloadChunkFinalUvjet(data.imaPoslanihNaSud!);

            if (data.generiranihOpomena! > 0) {
                setDownloadOpomeneUvjet(true);
            }

            setPunomocnikIds(data.punomocnikId || data.excelPunomocnici?.map(x => x.punomocnikId.toString()));
            setZakonskiZastupnikId(data.zakonskiZastupnikId);
        } else {
            setPotpisiUvjet(false);
            setPosaljiUvjet(false);
            setGenerirajPrijedlogeUvjet(false);
            setDownloadChunkPrijedloziUvjet(false);
            setDownloadChunkFinalUvjet(false);
            setDownloadOpomeneUvjet(false);
            setDeleteUvjet(false);
            setOpomenaUvjet(false);
            setOpomenaAkcijeUvjet(false);
        }

        setShouldConditionsUpdate(false);
    };

    const handlePdfPrivitciDrawerChange = (newPdfPrivitciDrawerState: DrawerState) => {
        setPdfPrivitciDrawerState(newPdfPrivitciDrawerState);
        if (newPdfPrivitciDrawerState === DrawerState.Closed) {
            confirmExcelFile();
            setShouldTableUpdate(true);
        }
    };

    const handleExcelTrazbineDrawerChange = (excelTrazbineDrawerState: DrawerState) => {
        setExcelTrazbineDrawerState(excelTrazbineDrawerState);
        if (excelTrazbineDrawerState === DrawerState.Closed) {
            setShouldTableUpdate(true);
        }
    };

    const handleBulkPdfPrivitciDrawerChange = (newBulkPdfPrivitciDrawerState: DrawerState, newPdfPrivitciType?: PrilogType) => {
        setBulkPdfPrivitciDrawerState(newBulkPdfPrivitciDrawerState);
        setBulkPdfPrivitciDrawerType(newPdfPrivitciType || PrilogType.VjerodostojnaIsprava);

        if (newBulkPdfPrivitciDrawerState === DrawerState.Closed) {
            confirmExcelFile();
            setShouldTableUpdate(true);
        }
    };

    const handlePdfPrivitciSuccessCreate = () => {
        handlePdfPrivitciDrawerChange(DrawerState.Closed);
    };

    const handleExcelTrazbineSuccessCreate = () => {
        handlePdfPrivitciDrawerChange(DrawerState.Closed);
    };

    const handleGenerateModal = (newState: boolean): void => {
        setShowGenerateModal(newState);
    };

    const handleGenerateOkModal = async () => {
        const data = await postAsync<AxiosResponse>(`/EOvrheAPI/PostPrijedlozi/${excelFileId}`, null, translations.general.prijedloziSaving, translations.general.generateSuccess, translations.general.errorGenerate);

        if (data) {
            handleGenerateModal(false);
            confirmExcelFile();
            setShouldTableUpdate(true);
        };

        handleGenerateModal(false);
    };

    const handleSignModal = (newState: boolean) => {
        setShowSignModal(newState);
    };

    const handleSignOkModal = async () => {
        const data = await postAsync<AxiosResponse>(`/EOvrheAPI/PotpisiPrijedloge/${excelFileId}`, null, translations.general.signingSaving, translations.general.signSuccess, translations.general.errorSign);

        if (data) {
            handleSignModal(false);
            confirmExcelFile();
            setShouldTableUpdate(true);
        };

        handleSignModal(false);
    };

    const handleSendModal = (newState: boolean) => {
        setShowSendModal(newState);
    };

    const handleSendOkModal = async () => {
        const data = await postAsync<AxiosResponse>(`/EOvrheAPI/PostPosaljiNaSud/${excelFileId}`, null, translations.general.sendToCountSaving, translations.general.sendSuccess, translations.general.errorSend);

        if (data) {
            handleSendModal(false);
            confirmExcelFile();
            setShouldTableUpdate(true);
        };

        handleSendModal(false);
    };

    const handleDownloadChunkPrijedloziModal = (newState: boolean): void => {
        setShowDownloadChunkPrijedloziModal(newState);
    };

    const handleDownloadChunkPrijedloziOkModal = async () => {
        const data = await getAsync<PdfBase64>(`/Prijedlozi/GetZIPPrijedlozi/${excelFileId}`, undefined, undefined, translations.general.errorDownloadChunkPrijedlozi);

        if (data) {
            handleDownloadChunkPrijedloziModal(false);

            var byteString = atob(data.base64);
            var buffer = new ArrayBuffer(byteString.length);
            var array = new Uint8Array(buffer);

            for (var i = 0; i < byteString.length; i++) {
                array[i] = byteString.charCodeAt(i);
            };

            const blob = new Blob([buffer], { type: 'application/zip' });

            let a = document.createElement('a');

            const url = window.URL.createObjectURL(blob);

            a.href = url;
            a.download = translations.general.prijedloziDownloadFilename;
            a.click();

            showSuccess(translations.general.downloadChunkPrijedloziSuccess);
        };

        handleDownloadChunkPrijedloziModal(false);
    };

    const handleDownloadChunkFinalModal = (newState: boolean): void => {
        setShowDownloadChunkFinalModal(newState);
    };

    const handleDownloadChunkFinalOkModal = async () => {
        const data = await getAsync<PdfBase64>(`/Prijedlozi/GetZIPPoslaniNaSud/${excelFileId}`, undefined, undefined, translations.general.errorDownloadChunkFinal);

        if (data) {
            handleDownloadChunkFinalModal(false);

            var byteString = atob(data.base64);
            var buffer = new ArrayBuffer(byteString.length);
            var array = new Uint8Array(buffer);

            for (var i = 0; i < byteString.length; i++) {
                array[i] = byteString.charCodeAt(i);
            };

            const blob = new Blob([buffer], { type: 'application/zip' });

            let a = document.createElement('a');

            const url = window.URL.createObjectURL(blob);

            a.href = url;
            a.download = translations.general.finalDownloadFilename;
            a.click();

            showSuccess(translations.general.downloadChunkFinalSuccess);
        };

        handleDownloadChunkFinalModal(false);
    };

    const handleDownloadOpomeneModal = (newState: boolean): void => {
        setShowDownloadOpomeneModal(newState);
    };

    const handleDownloadOpomeneOkModal = async () => {
        const data = await getAsync<PdfBase64>(`/Prijedlozi/GetZIPOpomene/${excelFileId}`, undefined, undefined, translations.general.errorDownloadOpomene);

        if (data) {
            handleDownloadOpomeneModal(false);

            var byteString = atob(data.base64);
            var buffer = new ArrayBuffer(byteString.length);
            var array = new Uint8Array(buffer);

            for (var i = 0; i < byteString.length; i++) {
                array[i] = byteString.charCodeAt(i);
            };

            const blob = new Blob([buffer], { type: 'application/zip' });

            let a = document.createElement('a');

            const url = window.URL.createObjectURL(blob);

            a.href = url;
            a.download = translations.general.opomeneDownloadFilename;
            a.click();

            showSuccess(translations.general.downloadOpomeneSuccess);
        };

        handleDownloadOpomeneModal(false);
    };

    const handleDeleteModal = (newState: boolean) => {
        setShowDeleteModal(newState);
    };
    const handleDeleteOkModal = async () => {
        const data = await deleteAsync<AxiosResponse>('/Excel/Delete/' + excelFileId, undefined, translations.general.deleteSuccess, translations.general.deleteError);
        if (data.data) {
            history.push(Routes.ROUTE_EXCEL_FILES)
        };

        handleDeleteModal(false);
    };

    const handleOpomenaModal = (newState: boolean) => {
        setShowOpomenaModal(newState);
    };

    const handleOpomenaOkModal = async () => {
        const data = await postAsync<AxiosResponse>('/Pdf/GenerateOpomene/' + excelFileId, null, translations.general.opomeneSaving, translations.general.opomenaSendSuccess, translations.general.opomenaSendError);

        if (data) {
            handleOpomenaModal(false);
            confirmExcelFile();
            setShouldTableUpdate(true);
        };

        handleOpomenaModal(false);
    };

    const contentAkcije = (
        <div style={{ display: "grid", padding: 5 }}>
            <Tooltip title={generirajPrijedlogeUvjet ? translations.general.generirajPrijedlogTooltip : translations.general.generirajPrijedlogTooltipDisable}>
                <Button
                    key="2"
                    type="default"
                    style={{ ...ButtonDropdownSettings }}
                    onClick={(): void => handleGenerateModal(true)}
                    className="no-print"
                    disabled={!excelFileId || !generirajPrijedlogeUvjet}
                >
                    <PlusOutlined />
                    {translations.general.generirajPrijedloge}
                </Button>
            </Tooltip>
            {/* 26.8.2024. Ovo je legacy iz vremena kada je ideja bila kroz aplikaciju potpisivati generirane prijedloge. Kasnije se pokazalo da se zbog certifikata
            to ipak ne moze. samo jezakomentirano jer je logika vec tu i ako bude bilo potrebno nekak odrugacije potpisivati u buducnosti ovo je dobar satrting point */}
            {/* <Tooltip title={potpisiUvjet ? translations.general.potpisiTooltip : translations.general.potpisiTooltipDisable}>
                <Button
                    key="3"
                    type="default"
                    style={{ ...ButtonDropdownSettings }}
                    onClick={(): void => handleSignModal(true)}
                    className="no-print"
                    disabled={!excelFileId || !potpisiUvjet}
                >
                    <EditOutlined />
                    {translations.general.potpisi}
                </Button>
            </Tooltip> */}
            <Tooltip title={posaljiUvjet ? translations.general.posaljiTooltip : translations.general.posaljiTooltipDisable}>
                <Button
                    key="4"
                    type="default"
                    style={{ ...ButtonDropdownSettings }}
                    onClick={(): void => handleSendModal(true)}
                    className="no-print"
                    disabled={!excelFileId || !posaljiUvjet}
                >
                    <SendOutlined />
                    {translations.general.posalji}
                </Button>
            </Tooltip>
            <Tooltip title={downloadChunkPrijedloziUvjet ? translations.general.downloadChunkPrijedlozi : translations.general.downloadChunkPrijedloziDisable}>
                <Button
                    key="5"
                    type="default"
                    style={{ ...ButtonDropdownSettings }}
                    onClick={(): void => handleDownloadChunkPrijedloziModal(true)}
                    className="no-print"
                    disabled={!excelFileId || !downloadChunkPrijedloziUvjet}
                >
                    <DownloadOutlined />
                    {translations.general.downloadChunkPrijedloziBtn}
                </Button>
            </Tooltip>
            <Tooltip title={downloadChunkFinalUvjet ? translations.general.downloadChunkFinal : translations.general.downloadChunkFinalDisable}>
                <Button
                    key="7"
                    type="default"
                    style={{ ...ButtonDropdownSettings }}
                    onClick={(): void => handleDownloadChunkFinalModal(true)}
                    className="no-print"
                    disabled={!excelFileId || !downloadChunkFinalUvjet}
                >
                    <DownloadOutlined />
                    {translations.general.downloadChunkFinalBtn}
                </Button>
            </Tooltip>
        </div>
    );

    const opomenaAkcije = (
        <div style={{ display: "grid", padding: 5 }}>
            <Tooltip title={opomenaUvjet ? translations.general.opomenaTooltip : translations.general.opomenaTooltipDisable}>
                <Button
                    key="8"
                    type="default"
                    style={{ ...ButtonDropdownSettings, marginTop: 0 }}
                    onClick={(): void => handleOpomenaModal(true)}
                    className="no-print"
                    disabled={!excelFileId || !opomenaUvjet}
                >
                    <EditOutlined />
                    {translations.general.generirajOpomene}
                </Button>
            </Tooltip>
            <Tooltip title={downloadOpomeneUvjet ? translations.general.downloadOpomene : translations.general.downloadOpomeneDisable}>
                <Button
                    key="6"
                    type="default"
                    style={{ ...ButtonDropdownSettings }}
                    onClick={(): void => handleDownloadOpomeneModal(true)}
                    className="no-print"
                    disabled={!excelFileId || !downloadOpomeneUvjet}
                >
                    <DownloadOutlined />
                    {translations.general.downloadOpomeneBtn}
                </Button>
            </Tooltip>
        </div>
    );

    const contentAttachments = (
        <div style={{ display: "grid", padding: 5 }}>
            <Button
                key="1"
                type="default"
                style={{ ...ButtonDropdownSettings, marginTop: 0 }}
                onClick={() => handlePdfPrivitciDrawerChange(DrawerState.Create)}
                className="no-print"
            >
                <FileAddOutlined />
                {translations.general.addMutualPrivitakExcel}
            </Button>
            <Button
                key="9"
                type="default"
                style={{ ...ButtonDropdownSettings }}
                onClick={() => handleBulkPdfPrivitciDrawerChange(DrawerState.Create, PrilogType.VjerodostojnaIsprava)}
                className="no-print"
            >
                <DiffOutlined />
                {translations.general.addBulkPrivitakVjerodostojne}
            </Button>
            <Button
                key="10"
                type="default"
                style={{ ...ButtonDropdownSettings }}
                onClick={() => handleBulkPdfPrivitciDrawerChange(DrawerState.Create, PrilogType.Ostalo)}
                className="no-print"
            >
                <DiffOutlined />
                {translations.general.addBulkPrivitakOstalo}
            </Button>
            <Button
                key="11"
                type="default"
                style={{ ...ButtonDropdownSettings }}
                onClick={() => handleBulkPdfPrivitciDrawerChange(DrawerState.Create, PrilogType.PotpisaniPDF)}
                className="no-print"
            >
                <DiffOutlined />
                {translations.general.addBulkPrijedlogSigned}
            </Button>
            <Button
                key="12"
                type="default"
                style={{ ...ButtonDropdownSettings }}
                onClick={(): void => handleExcelTrazbineDrawerChange(DrawerState.Create)}
                className="no-print"
            >
                <DiffOutlined />
                {translations.general.dodajPrilezeceTrazbine}
            </Button>
        </div>
    );

    return (
        <div>
            <PageHeader
                title={translations.general.excelTitle}
                extra={
                    [
                        <Button
                            key="6"
                            type="default"
                            danger
                            style={{
                                zIndex: 10,
                                float: 'right',
                                marginLeft: '10px',

                            }}
                            onClick={() => handleDeleteModal(true)}
                            className="no-print"
                            disabled={!excelFileId || !deleteUvjet}
                        >
                            <DeleteOutlined />
                            {translations.general.deleteDocument}
                        </Button>,
                        <Popover content={opomenaAkcije} key={"opomenaAkcije"}>
                            {opomenaAkcijeUvjet && <Button
                                type="default"
                                style={{ float: "right", marginLeft: '10px' }}>
                                <FileTextOutlined />
                                {translations.general.opomene}
                            </Button>}
                        </Popover>,
                        <Popover content={contentAkcije} key={"contentAkcije"}>
                            <Button
                                type="default"
                                style={{ float: "right", marginLeft: '10px' }}>
                                <AuditOutlined />
                                {translations.general.akcije}
                            </Button>
                        </Popover>,
                        <Popover content={contentAttachments} key={"contentAttachments"}>
                            <Button
                                type="default"
                                style={{ float: "right" }}
                                disabled={!excelFileId}
                            >
                                <FileAddOutlined />
                                {translations.general.addPrivitakExcel}
                            </Button>
                        </Popover>,
                    ]
                }
            />
            {excelFileId && <ExcelTable
                excelFileId={excelFileId}
                shouldUpdate={shouldTableUpdate}
                setShouldUpdateState={setShouldTableUpdate}
                setShouldConditionsUpdateState={setShouldConditionsUpdate}
                opomeneUvjet={opomenaAkcijeUvjet}
                showDvijeTrazbine={showDvijeTrazbine}
                showDvaOvrsenika={showDvaOvrsenika}
            />}
            {excelFileId && <Drawer
                title={translations.general.addExcelPrivitci}
                visible={!!pdfPrivitciDrawerState}
                onClose={() => handlePdfPrivitciDrawerChange(DrawerState.Closed)}
                width={DRAWER_MEDIUM_WIDTH}
                destroyOnClose
            >
                <ExcelAttachmentForm
                    onClose={() => handlePdfPrivitciDrawerChange(DrawerState.Closed)}
                    onSuccess={() => handlePdfPrivitciSuccessCreate()}
                    excelFileId={excelFileId}
                    punomocnikIds={punomocnikIds as string[]}
                    zakonskiZastupnikId={zakonskiZastupnikId}
                />
            </Drawer>}
            {excelFileId && <Drawer
                title={getBulkUploadDrawerTitle(pdfBulkPrivitciDrawerType)}
                visible={!!pdfBulkPrivitciDrawerState}
                onClose={() => handleBulkPdfPrivitciDrawerChange(DrawerState.Closed)}
                width={DRAWER_MEDIUM_WIDTH}
                destroyOnClose
            >
                <BulkExcelAttachmentForm
                    onSuccess={() => handleBulkPdfPrivitciDrawerChange(DrawerState.Closed)}
                    excelFileId={excelFileId}
                    prilogType={pdfBulkPrivitciDrawerType}
                />
            </Drawer>}
            {excelFileId && <Drawer
                title={translations.general.dodajPrilezeceTrazbine}
                visible={!!excelTrazbineDrawerState}
                onClose={() => handleExcelTrazbineDrawerChange(DrawerState.Closed)}
                width={DRAWER_MEDIUM_WIDTH}
                destroyOnClose
            >
                <ExcelAttachmentForm
                    onClose={() => handleExcelTrazbineDrawerChange(DrawerState.Closed)}
                    onSuccess={() => handleExcelTrazbineSuccessCreate()}
                    excelFileId={excelFileId}
                    attachmentType={PrilogType.Trazbine}
                />
            </Drawer>}
            {generirajPrijedlogeUvjet &&
                <Modal
                    title={translations.general.generirajPrijedloge}
                    visible={showGenerateModal}
                    onOk={handleGenerateOkModal}
                    onCancel={(): void => handleGenerateModal(false)}>
                    {translations.general.generirajPrijedlogeTekst}
                </Modal>}
            {potpisiUvjet &&
                <Modal
                    title={translations.general.potpisi}
                    visible={showSignModal}
                    onOk={handleSignOkModal}
                    onCancel={(): void => handleSignModal(false)}>
                    {brojNegeneriranihPrijedloga && ukupanBrojPrijedloga && brojNegeneriranihPrijedloga > 0 ? translations.general.potpisiTekstNegenerirani.replace("x", brojNegeneriranihPrijedloga.toString()).replace("y", ukupanBrojPrijedloga.toString()) : translations.general.potpisiTekstSviGenerirani}
                </Modal>}
            {posaljiUvjet &&
                <Modal
                    title={translations.general.posalji}
                    visible={showSendModal}
                    onOk={handleSendOkModal}
                    onCancel={(): void => handleSendModal(false)}>
                    {translations.general.posaljiTekst}
                </Modal>}
            {downloadChunkPrijedloziUvjet && <Modal
                title={translations.general.downloadChunkPrijedlozi}
                visible={showDownloadChunkPrijedloziModal}
                onOk={handleDownloadChunkPrijedloziOkModal}
                onCancel={(): void => handleDownloadChunkPrijedloziModal(false)}>
                {brojNegeneriranihPrijedloga && ukupanBrojPrijedloga && brojNegeneriranihPrijedloga > 0 ? translations.general.downloadChunkPrijedloziTekstNegenerirani.replace("x", brojNegeneriranihPrijedloga.toString()).replace("y", ukupanBrojPrijedloga.toString()) : translations.general.downloadChunkPrijedloziTekstSviGenerirani}
            </Modal>}
            {downloadChunkFinalUvjet && <Modal
                title={translations.general.downloadChunkFinal}
                visible={showDownloadChunkFinalModal}
                onOk={handleDownloadChunkFinalOkModal}
                onCancel={(): void => handleDownloadChunkFinalModal(false)}>
                {translations.general.downloadChunkFinalTekst}
            </Modal>}
            {downloadOpomeneUvjet && <Modal
                title={translations.general.downloadOpomene}
                visible={showDownloadOpomeneModal}
                onOk={handleDownloadOpomeneOkModal}
                onCancel={(): void => handleDownloadOpomeneModal(false)}>
                {translations.general.downloadOpomeneTekst}
            </Modal>}
            {deleteUvjet &&
                <Modal
                    title={translations.general.deleteExcelFile}
                    visible={showDeleteModal}
                    onOk={handleDeleteOkModal}
                    onCancel={(): void => handleDeleteModal(false)}>
                    {translations.general.deleteExcelFileTekst}
                </Modal>}
            {opomenaUvjet && <Modal
                title={translations.general.generirajOpomene}
                visible={showOpomenaModal}
                onOk={handleOpomenaOkModal}
                onCancel={(): void => handleOpomenaModal(false)}>
                {brojNegeneriranihPrijedloga && ukupanBrojPrijedloga && brojNegeneriranihPrijedloga > 0 ? translations.general.opomenaTekstNegenerirani.replace("x", brojNegeneriranihPrijedloga.toString()).replace("y", ukupanBrojPrijedloga.toString()) : translations.general.opomenaTekstSviGenerirani}
            </Modal>}
        </div>
    );
}

export default Excel;