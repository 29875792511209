import React from "react";

export class Home extends React.PureComponent {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <h4 style={{ marginLeft: "50px", marginTop: "30px" }}>
          Dobrodošli u aplikaciju za ovrhovoditelje e-ovrhe!
        </h4>
        <h6 style={{ marginLeft: "50px", marginTop: "40px" }}>
          Za rad na aplikaciji molimo da koristite opcije u izborniku s lijeve
          strane.
        </h6>
      </div>
    );
  }
}
