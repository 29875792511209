/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from "react";
import { Form, Upload, Button, Input, Table } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { ImportOutlined, DeleteOutlined } from "@ant-design/icons";
import { AxiosResponse } from "axios";
import { translations } from "../../../../config/translations";
import { pdfType, xlsxType } from "../../../../config/constants";
import { PrilogType } from "../../../../core/models/enum";
import { ExcelPrilog } from "../../../../core/models/ExcelPrilog";
import { showError } from "../../../../helpers/NotificationHelper";
import { formItemLayoutDrawer, getTableLocale } from "../../../../helpers/TableHelper";
import { openPDFPreview } from "../../../../helpers/CommonHelper";
import { useApiContext } from "../../../../helpers/ApiContext";
import { Props } from "./index";
import { ExcelErrorTableData } from "../../../../utils/models/ExcelErrorTableData";
import { ExcelUploadResponse } from "../../../../core/models/ExcelUploadResponse";

const ExcelAttachmentForm: React.FC<Props> = (props: Props) => {
  const { excelFileId, punomocnikIds, zakonskiZastupnikId, attachmentType } = props;
  const { postAsync, deleteAsync, getAsync } = useApiContext();
  const [dokazPunomociPdf, setDokazPunomociPdf] = useState<UploadFile[]>([]);
  const [dokazZastupanjaPdf, setDokazZastupanjaPdf] = useState<UploadFile[]>([]);
  const [ostaloPdf, setOstaloPdf] = useState<UploadFile[]>([]);
  const [dodatneTrazbineXlsx, setDodatneTrazbineXlsx] = useState<UploadFile[]>([]);

  const [ostaloDescription, setOstaloDescription] = useState<string | undefined>();
  const [dokazPunomoci, setDokazPunomoci] = useState<ExcelPrilog | undefined>();
  const [dokazZastupanja, setDokazZastupanja] = useState< ExcelPrilog | undefined>();
  const [ostaliPdfovi, setOstaliPdfovi] = useState<ExcelPrilog[] | undefined>();
  const [excelErrorTableData, setExcelErrorTableData] = useState<ExcelErrorTableData[]>([]);


  useEffect(() => {
    getPdfs();
  }, []);

  const saveDokazPunomociPdf = async () => {
    let formData = new FormData();

    formData.append("pdf", dokazPunomociPdf[0] as any);
    formData.append("type", PrilogType.DokazPunomoci as any);
    formData.append("excelFileId", excelFileId);

    const data = await postAsync<AxiosResponse>(
      "/Files/UploadExcelPdf",
      formData,
      undefined,
      translations.general.importPdfSuccess,
      translations.general.errorSavingData
    );

    if (data) {
      getPdfs();
      setDokazPunomociPdf([]);
    }
  };

  const deleteDokazPunomociPdf = async () => {
    const data = await deleteAsync<AxiosResponse>(
      "/Files/DeleteExcelPrilog/" + dokazPunomoci?.id,
      undefined,
      translations.general.successDeletingPdf,
      translations.general.errorDeletingPdf
    );

    if (data.data) {
      getPdfs();
      setDokazPunomoci(undefined);
    }
  };

  const saveDokazZastupanjaPdf = async () => {
    let formData = new FormData();

    formData.append("pdf", dokazZastupanjaPdf[0] as any);
    formData.append("type", PrilogType.DokazZastupanja as any);
    formData.append("excelFileId", excelFileId);

    const data = await postAsync<AxiosResponse>(
      "/Files/UploadExcelPdf",
      formData,
      undefined,
      translations.general.importPdfSuccess,
      translations.general.errorSavingData
    );

    if (data) {
      getPdfs();
      setDokazZastupanjaPdf([]);
    }
  };

  const deleteDokazZastupanjaPdf = async () => {
    const data = await deleteAsync<AxiosResponse>(
      "/Files/DeleteExcelPrilog/" + dokazZastupanja?.id,
      undefined,
      translations.general.successDeletingPdf,
      translations.general.errorDeletingPdf
    );

    if (data.data) {
      getPdfs();
      setDokazZastupanja(undefined);
    }
  };

  const saveOstaloPdfovi = async () => {
    if (ostaloDescription) {
      let formData = new FormData();

      formData.append("pdf", ostaloPdf[0] as any);
      formData.append("type", PrilogType.Ostalo as any);
      formData.append("excelFileId", excelFileId);
      formData.append("desc", ostaloDescription as any);

      const data = await postAsync<AxiosResponse>(
        "/Files/UploadExcelPdf",
        formData,
        undefined,
        translations.general.importPdfSuccess,
        translations.general.errorSavingData
      );

      if (data) {
        getPdfs();
        setOstaloPdf([]);
        setOstaloDescription(undefined);
      }
    } else {
      showError(translations.general.opisRequired);
    }
  };

  const deleteOstaloPdfovi = async (id: string) => {
    const data = await deleteAsync<AxiosResponse>(
      "/Files/DeleteExcelPrilog/" + id,
      undefined,
      translations.general.successDeletingPdf,
      translations.general.errorDeletingPdf
    );

    if (data.data) {
      getPdfs();
    }
  };

  const editOstaloOpis = async (index: number) => {
    if (ostaliPdfovi && ostaliPdfovi[index].desc.length > 0) {
      let formData = new FormData();

      formData.append("id", ostaliPdfovi[index].id);
      formData.append("description", ostaliPdfovi[index].desc);
      const data = await postAsync<AxiosResponse>(
        "/Files/EditExcelPrilogOpis",
        formData,
        undefined,
        translations.general.successEditingPdf,
        translations.general.errorEditingPdf
      );

      if (data.data) {
        getPdfs();
      }
    } else {
      showError(translations.general.opisRequired);
    }
  };

  const saveDodatneTrazbineXlsx = async () => {
    let formData = new FormData();
    formData.append("pdf", dodatneTrazbineXlsx[0] as any);
    formData.append("excelFileId", excelFileId);

    const data = await postAsync<ExcelUploadResponse>(
      "/Excel/UploadTrazbine",
      formData,
      undefined,
      translations.general.importSuccess,
      translations.general.errorSavingData
    );

    if (data) {
      setDodatneTrazbineXlsx([]);
      setExcelErrorTableData(data.errors);
    }
  };

  const getPdfs = async () => {
    const data = await getAsync<ExcelPrilog[]>(
      "/Files/GetAllExcelPrilog/" + excelFileId
    );

    if (data) {
      const punomoc = data.find(
        (pdf: ExcelPrilog): boolean => pdf.type === PrilogType.DokazPunomoci
      );
      if (punomoc) {
        setDokazPunomoci(punomoc);
      }

      const zastupanje = data.find(
        (pdf: ExcelPrilog): boolean => pdf.type === PrilogType.DokazZastupanja
      );
      if (zastupanje) {
        setDokazZastupanja(zastupanje);
      }

      const ostalo = data.filter(
        (pdf: ExcelPrilog): boolean => pdf.type === PrilogType.Ostalo
      );
      if (ostalo) {
        setOstaliPdfovi(ostalo.map((item: ExcelPrilog): ExcelPrilog => item));
      }
    }
  };

  const setDescription = (description: string, index: number) => {
    if (ostaliPdfovi) {
      const newPdfArray = ostaliPdfovi.map(
        (pdf: ExcelPrilog, i: number): ExcelPrilog => {
          if (index === i) {
            return { ...pdf, desc: description };
          } else {
            return pdf;
          }
        }
      );

      setOstaliPdfovi(newPdfArray);
    }
  };

  const excelErrorColumns = [
    {
        title: translations.excelTable.rowIndex,
        dataIndex: "rowIndex",
        width: 50
    },
    {
        title: translations.excelTable.columnIndex,
        dataIndex: "columnIndex",
        width: 50
    },
    {
        title: translations.excelTable.columnName,
        dataIndex: "columnName",
        width: 200
    },
    {
        title: translations.excelTable.cellValue,
        dataIndex: "cellValue",
        width: 140
    },
    {
        title: translations.excelTable.errorMessage,
        dataIndex: "errorMessage",
        width: 160
    },
];

  return (
    <Form {...formItemLayoutDrawer}>
      {punomocnikIds && punomocnikIds.length > 0 && (
        <Form.Item label={translations.general.dokazPunomoci}>
          {!dokazPunomoci && (
            <Upload
              fileList={dokazPunomociPdf}
              onRemove={() => {
                setDokazPunomociPdf([]);
              }}
              beforeUpload={(file) => {
                if (file.type !== pdfType) {
                  showError(translations.general.wrongFileType);
                } else {
                  setDokazPunomociPdf([file]);
                }
                return false;
              }}
              accept={pdfType}
            >
              <Button type="primary" style={{ marginTop: "10px" }}>
                <ImportOutlined />
                {translations.general.upload}
              </Button>
            </Upload>
          )}
          {dokazPunomoci && (
            <Button
              onClick={(): Promise<void> =>
                openPDFPreview(
                  dokazPunomoci.base64,
                  translations.general.prijedlog
                )
              }
              style={{ width: "350px", overflow: "hidden" }}
            >
              {dokazPunomoci.fileName}
            </Button>
          )}
          {!dokazPunomoci && (
            <Button
              type="default"
              onClick={saveDokazPunomociPdf}
              style={{ marginTop: "20px", marginBottom: "20px" }}
              disabled={dokazPunomociPdf.length === 0}
            >
              <ImportOutlined />
              {translations.general.spremiDatoteku}
            </Button>
          )}
          {dokazPunomoci && (
            <Button
              type="default"
              danger
              onClick={deleteDokazPunomociPdf}
              style={{ marginLeft: "10px" }}
              disabled={!dokazPunomoci}
            >
              <DeleteOutlined />
            </Button>
          )}
        </Form.Item>
      )}
      {zakonskiZastupnikId && (
        <Form.Item label={translations.general.dokazZastupanja}>
          {!dokazZastupanja && (
            <Upload
              fileList={dokazZastupanjaPdf}
              onRemove={() => {
                setDokazZastupanjaPdf([]);
              }}
              beforeUpload={(file) => {
                if (file.type !== pdfType) {
                  showError(translations.general.wrongFileType);
                } else {
                  setDokazZastupanjaPdf([file]);
                }
                return false;
              }}
              accept={pdfType}
            >
              <Button type="primary" style={{ marginTop: "10px" }}>
                <ImportOutlined />
                {translations.general.upload}
              </Button>
            </Upload>
          )}
          {dokazZastupanja && (
            <Button
              onClick={(): Promise<void> =>
                openPDFPreview(
                  dokazZastupanja.base64,
                  translations.general.prijedlog
                )
              }
              style={{ width: "350px", overflow: "hidden" }}
            >
              {dokazZastupanja.fileName}
            </Button>
          )}
          {!dokazZastupanja && (
            <Button
              type="default"
              onClick={saveDokazZastupanjaPdf}
              style={{ marginTop: "20px", marginBottom: "20px" }}
              disabled={dokazZastupanjaPdf.length === 0}
            >
              <ImportOutlined />
              {translations.general.spremiDatoteku}
            </Button>
          )}
          {dokazZastupanja && (
            <Button
              type="default"
              danger
              onClick={deleteDokazZastupanjaPdf}
              style={{ marginLeft: "10px" }}
              disabled={!dokazZastupanja}
            >
              <DeleteOutlined />
            </Button>
          )}
        </Form.Item>
      )}
      {attachmentType && attachmentType === PrilogType.Trazbine &&
        <Form.Item label={translations.general.dodajTrazbine}>
          <Upload
            fileList={dodatneTrazbineXlsx}
            onRemove={() => {
              setDodatneTrazbineXlsx([]);
            }}
            beforeUpload={(file) => {
              if (file.type !== xlsxType) {
                showError(translations.general.wrongFileType);
              } else {
                setDodatneTrazbineXlsx([file]);
              }
              return false;
            }}
            accept={xlsxType}
          >
            <Button type="primary">
              <ImportOutlined />
              {translations.general.upload}
            </Button>
          </Upload>
          <Button
            type="default"
            onClick={saveDodatneTrazbineXlsx}
            style={{ marginTop: "20px", marginBottom: "20px" }}
            disabled={dodatneTrazbineXlsx.length === 0}
          >
            <ImportOutlined />
            {translations.general.spremiDatoteku}
          </Button>
        </Form.Item>
      }
      {excelErrorTableData && excelErrorTableData.length > 0 &&
        <Table
          locale={getTableLocale()}
          columns={excelErrorColumns}
          dataSource={excelErrorTableData}
          bordered
          scroll={{ y: 400 }}
        />
      }
      {!attachmentType &&
        <Form.Item label={translations.general.ostalo}>
          {ostaliPdfovi &&
            ostaliPdfovi?.map(
              (pdf: ExcelPrilog, index: number): ReactElement => (
                <>
                  <Button
                    style={{
                      marginBottom: "10px",
                      width: "350px",
                      overflow: "hidden",
                    }}
                    onClick={(): Promise<void> =>
                      openPDFPreview(pdf.base64, translations.general.prijedlog)
                    }
                  >
                    {pdf.fileName}
                  </Button>
                  <Button
                    type="default"
                    danger
                    onClick={() => deleteOstaloPdfovi(pdf.id)}
                    style={{ marginLeft: "10px", marginTop: "20px" }}
                    disabled={!ostaliPdfovi}
                  >
                    <DeleteOutlined />
                  </Button>
                  <Input
                    addonBefore={translations.general.opisDokumenta}
                    placeholder={translations.general.opis}
                    required={true}
                    value={pdf.desc}
                    style={{ marginTop: "10px" }}
                    onChange={(input): void =>
                      setDescription(input.target.value, index)
                    }
                  ></Input>
                  <Button
                    type="default"
                    onClick={() => editOstaloOpis(index)}
                    style={{
                      marginLeft: "40px",
                      display: "block",
                      marginTop: "10px",
                      marginBottom: "30px",
                    }}
                    disabled={!ostaliPdfovi}
                  >
                    {translations.general.editDescriptionBtn}
                  </Button>
                </>
              )
            )}
          <Upload
            fileList={ostaloPdf}
            onRemove={() => {
              setOstaloPdf([]);
            }}
            beforeUpload={(file) => {
              if (file.type !== pdfType) {
                showError(translations.general.wrongFileType);
              } else {
                setOstaloPdf(ostaloPdf.concat([file]));
              }
              return false;
            }}
            accept={pdfType}
          >
            <Button type="primary" style={{ marginTop: "10px" }}>
              <ImportOutlined />
              {translations.general.upload}
            </Button>
          </Upload>
          {ostaloPdf.length > 0 && (
            <Input
              addonBefore={translations.general.opisDokumenta}
              placeholder={translations.general.opis}
              required={true}
              value={ostaloDescription}
              style={{ marginTop: "20px" }}
              onChange={(input): void => setOstaloDescription(input.target.value)}
            ></Input>
          )}
          <Button
            type="default"
            onClick={saveOstaloPdfovi}
            style={{ marginTop: "20px", marginBottom: "20px" }}
            disabled={ostaloPdf.length === 0}
          >
            <ImportOutlined />
            {translations.general.spremiDatoteku}
          </Button>
        </Form.Item>
      }
    </Form>
  );
};

export default ExcelAttachmentForm;
