/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Table from 'antd/lib/table';
import { translations } from '../../../config/translations';
import { DrawerState, OsobaTip } from '../../../core/models/enum';
import { Ovrhovoditelj } from '../../../core/models/Ovrhovoditelj';
import { genericTableProps, getColumnSearchProps } from '../../../helpers/TableHelper';
import { onRowClick } from '../../../helpers/TableHelper';
import { useApiContext } from '../../../helpers/ApiContext';
import { getOsobaTypeDisplayName } from '../../../helpers/EnumHelper';
import { Props } from './index';

const OvrhovoditeljiTable: React.FC<Props> = (props: Props) => {
    const { shouldUpdate, setShouldUpdateState, handleOvrhovoditeljDrawerChange } = props;
    const { getAsync, userId } = useApiContext();
    const [ovrhovoditeljiTableData, setOvrhovoditeljiTableData] = useState<Ovrhovoditelj[] | undefined>([]);

    useEffect(() => {
        if (userId) getOvrhovoditeljiTableData();
    }, [userId]);

    useEffect(() => {
        if (shouldUpdate) {
            setShouldUpdateState(false);
            getOvrhovoditeljiTableData();
        }
    }, [shouldUpdate]);

    const getOvrhovoditeljiTableData = async () => {
        if (userId) {
            const data = await getAsync<Ovrhovoditelj[]>('/Ovrhovoditelji/GetAllByUser/' + userId);

            setOvrhovoditeljiTableData(data || []);
        }
    };

    const columns = [
        {
            title: translations.ovrhovoditelj.oib,
            dataIndex: 'oib',
            ...getColumnSearchProps('oib'),
            width: 110
        },
        {
            title: translations.ovrhovoditelj.tip,
            dataIndex: 'tip',
            width: 140,
            render: (row: OsobaTip): string => getOsobaTypeDisplayName(row),
            filters: [
                { text: getOsobaTypeDisplayName(OsobaTip.FIZICKA_OSOBA), value: OsobaTip.FIZICKA_OSOBA },
                { text: getOsobaTypeDisplayName(OsobaTip.PRAVNA_OSOBA), value: OsobaTip.PRAVNA_OSOBA },
                { text: getOsobaTypeDisplayName(OsobaTip.OSTALO), value: OsobaTip.OSTALO },
            ],
            onFilter: (value: any, record: Ovrhovoditelj) => record.tip === value,
        },
        {
            title: translations.ovrhovoditelj.ime,
            dataIndex: 'ime',
            ...getColumnSearchProps('ime'),
            width: 120
        },
        {
            title: translations.ovrhovoditelj.prezime,
            dataIndex: 'prezime',
            ...getColumnSearchProps('prezime'),
            width: 120
        },
        {
            title: translations.ovrhovoditelj.naziv,
            dataIndex: 'naziv',
            ...getColumnSearchProps('naziv'),
            width: 140
        },
        {
            title: translations.ovrhovoditelj.ulicaIKbr,
            dataIndex: 'ulicaIKbr',
            ...getColumnSearchProps('ulicaIKbr'),
            width: 120
        },
        {
            title: translations.ovrhovoditelj.postanskiBroj,
            dataIndex: 'postanskiBroj',
            ...getColumnSearchProps('postanskiBroj'),
            width: 110
        },
        {
            title: translations.ovrhovoditelj.naselje,
            dataIndex: 'naselje',
            ...getColumnSearchProps('naselje'),
            width: 100
        },
        {
            title: translations.ovrhovoditelj.nazivObrtaOpgaSamostalneDjelatnosti,
            dataIndex: 'nazivObrtaOpgaSamostalneDjelatnosti',
            ...getColumnSearchProps('nazivObrtaOpgaSamostalneDjelatnosti'),
            width: 160
        },
        {
            title: translations.ovrhovoditelj.racunSpecificneNamjene,
            dataIndex: 'racunSpecificneNamjene',
            ...getColumnSearchProps('racunSpecificneNamjene'),
            width: 120
        },
        {
            title: translations.ovrhovoditelj.kontaktPodaci,
            dataIndex: 'kontaktPodaci',
            ...getColumnSearchProps('kontaktPodaci'),
            width: 120
        }
    ]

    return (
        <Table
            {...genericTableProps}
            columns={columns}
            className="cursorPointer"
            dataSource={ovrhovoditeljiTableData}
            rowKey={(item): string => item.id!}
            onChange={getOvrhovoditeljiTableData}
            onRow={(item): { onClick: (column: any) => void } => ({
                onClick: (column: { target: { nodeName: string } }): void => onRowClick(column, (): void => handleOvrhovoditeljDrawerChange(DrawerState.Edit, false, item.id)),
            })}
        />
    );
}

export default OvrhovoditeljiTable;