import React, { useEffect } from "react";
import { Route, withRouter } from "react-router";
import { Switch, RouteComponentProps } from "react-router-dom";
import moment from 'moment'
import 'moment/locale/hr';
import Layout from "./components/Layout";
import { Home } from "./components/Home";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { AuthorizationRoutes } from "./components/api-authorization/ApiAuthorizationConstants";
import { ApiContextProvider } from "./helpers/ApiContext";
import Routes from "./config/routes";
import { translations } from "./config/translations";
import Excel from "./modules/excel";
import ExcelFiles from "./modules/excelFiles";
import Ovrhovoditelji from "./modules/ovrhovoditelji";
import Punomocnici from "./modules/punomocnici";
import ZakonskiZastupnici from "./modules/zakonskiZastupnici";
import Token from "./modules/token";
import "antd/dist/antd.css";
import "./custom.css";

moment.locale('hr')

const App: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  useEffect((): void => {
    document.title = translations.general.dashboard;
  }, []);

  return (
    <ApiContextProvider>
      <Layout>
        <Switch>
          <Route
            path={AuthorizationRoutes.ApiAuthorizationPrefix}
            component={ApiAuthorizationRoutes}
          />
          <Route
            exact
            path={Routes.ROUTE_EXCEL_FILE_DETAILS}
            component={Excel}
          />
          <Route
            exact
            path={Routes.ROUTE_EXCEL_FILES}
            component={ExcelFiles}
          />
          <Route
            exact
            path={Routes.ROUTE_OVRHOVODITELJI}
            component={Ovrhovoditelji}
          />
          <Route
            exact
            path={Routes.ROUTE_PUNOMOCNICI}
            component={Punomocnici}
          />
          <Route
            exact
            path={Routes.ROUTE_ZAKONSKI_ZASTUPNICI}
            component={ZakonskiZastupnici}
          />
          <Route exact path={Routes.ROUTE_TOKEN} component={Token} />
          <Route exact path={Routes.ROUTE_DASHBOARD} component={Home} />
        </Switch>
      </Layout>
    </ApiContextProvider>
  );
};

export default withRouter(App);
