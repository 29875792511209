import React from "react";
import { Link } from "react-router-dom";
import {
  ContainerOutlined,
  HomeOutlined,
  LockOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import Routes, { sidebarKey } from "../config/routes";
import { translations } from "../config/translations";
import authService from "./api-authorization/AuthorizeService";

interface Props { }

interface State { }

export default class SideMenu extends React.Component<Props, State> {
  _subscription: any;

  componentDidMount = async () => {
    this._subscription = authService.subscribe(() =>
      this.authenticationChanged()
    );
    this.populateAuthenticationState();
  };

  async populateAuthenticationState() { }

  async authenticationChanged() {
    await this.populateAuthenticationState();
  }

  render() {
    return (
      <Menu className="side-menu" theme="dark" mode="inline" /* selectedKeys={[selectedKey]}*/>
        <Menu.Item icon={<HomeOutlined />} key={sidebarKey.Dashboard}>
          <Link to={Routes.ROUTE_DASHBOARD}>
            {translations.general.dashboard}
          </Link>
        </Menu.Item>
        <Menu.Item icon={<ContainerOutlined />} key={sidebarKey.ExcelFiles}>
          <Link to={Routes.ROUTE_EXCEL_FILES}>{translations.general.excelFiles}</Link>
        </Menu.Item>
        <SubMenu
          key={sidebarKey.Settings}
          icon={<SettingOutlined />}
          title={
            translations.general.settings
          }
        >
          <Menu.Item icon={<LockOutlined />} key={sidebarKey.Token}>
            <Link to={Routes.ROUTE_TOKEN}>{translations.general.token}</Link>
          </Menu.Item>
          <Menu.Item icon={<UserOutlined />}>
            <Link to={Routes.ROUTE_OVRHOVODITELJI}>
              {translations.general.ovrhovoditelji}
            </Link>
          </Menu.Item>
          <Menu.Item icon={<UserOutlined />} key={sidebarKey.Punomocnici}>
            <Link to={Routes.ROUTE_PUNOMOCNICI}>
              {translations.general.punomocnici}
            </Link>
          </Menu.Item>
          <Menu.Item icon={<UserOutlined />} key={sidebarKey.ZakonskiZastupnici}>
            <Link to={Routes.ROUTE_ZAKONSKI_ZASTUPNICI}>
              {translations.general.zakonskiZastupnici}
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  }
}
