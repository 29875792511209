import { DropdownOption } from "../core/models/DropdownOption";
import {
  KamataType,
  OsobaTip,
  OsobaTipString,
  SredstvaOvrheType,
  VezaniSudionikTip,
  VjerodostojnaIspravaType,
  ZatezneKamateType,
} from "../core/models/enum";
import {
  IOsobaTipModel,
  IOsobaTipStringModel,
} from "../utils/interfaces/IOsobaTipModel";
import { IVezaniSudionikTipModel } from "../utils/interfaces/IVezaniSudionikTipModel";

export const getVezaniSudionikTipModel = (): IVezaniSudionikTipModel[] => [
  {
    vezaniSudionikTip: VezaniSudionikTip.FIZICKA_OSOBA,
    displayName: "Fizička osoba",
  },
  {
    vezaniSudionikTip: VezaniSudionikTip.PRAVNA_OSOBA,
    displayName: "Pravna osoba",
  },
];

export const getVezaniSudionikTypeDisplayName = (
  type: VezaniSudionikTip
): string => {
  switch (type) {
    case VezaniSudionikTip.FIZICKA_OSOBA:
      return "Fizička osoba";
    case VezaniSudionikTip.PRAVNA_OSOBA:
      return "Pravna osoba";
    default:
      return "Fizička osoba";
  }
};

export const getOsobaTipModel = (): IOsobaTipModel[] => [
  {
    osobaTip: OsobaTip.FIZICKA_OSOBA,
    displayName: "Fizička osoba",
  },
  {
    osobaTip: OsobaTip.PRAVNA_OSOBA,
    displayName: "Pravna osoba",
  },
  {
    osobaTip: OsobaTip.OSTALO,
    displayName: "Ostalo",
  },
];

export const getOsobaStringTipModel = (): IOsobaTipStringModel[] => [
  {
    osobaTip: OsobaTipString.FIZICKA_OSOBA,
    displayName: "Fizička osoba",
  },
  {
    osobaTip: OsobaTipString.PRAVNA_OSOBA,
    displayName: "Pravna osoba",
  },
  {
    osobaTip: OsobaTipString.OSTALO,
    displayName: "Ostalo",
  },
];

export const getOsobaTypeDisplayName = (type?: OsobaTip): string => {
  switch (type) {
    case OsobaTip.FIZICKA_OSOBA:
      return "Fizička osoba";
    case OsobaTip.PRAVNA_OSOBA:
      return "Pravna osoba";
    case OsobaTip.OSTALO:
      return "Ostalo";
    default:
      return "";
  }
};

export const getKamataTypeDisplayName = (type?: KamataType): string => {
  switch (type) {
    case KamataType.ZakonskaZatezna:
      return "Zakonska zatezna";
    case KamataType.UgovornaZatezna:
      return "Ugovorna zatezna";
    case KamataType.NeTraziSe:
      return "Ne traži se";
    default:
      return "";
  }
};

export const getKamataTypeModel = (): DropdownOption[] => [
  {
    id: KamataType.ZakonskaZatezna.toString(),
    name: "Zakonska zatezna",
  },
  {
    id: KamataType.UgovornaZatezna.toString(),
    name: "Ugovorna zatezna",
  },
  {
    id: KamataType.NeTraziSe.toString(),
    name: "Ne traži se",
  },
];

export const getSredstvaOvrheTypeDisplayName = (
  type?: SredstvaOvrheType
): string => {
  switch (type) {
    case SredstvaOvrheType.OdredbeOvrsnogZakona:
      return "Sukladno odredbama Ovršnog zakona";
    case SredstvaOvrheType.PljenidbaRadiNaplate:
      return "Pljenidba i prijenos novčane tražbine radi naplate";
    case SredstvaOvrheType.PljenidbaUmjestoIsplate:
      return "Pljenidba i prijenos novčane tražbine umjesto isplate";
    case SredstvaOvrheType.ZapljenaRadiNaplate:
      return "Zapljena i prijenos radi naplate";
    case SredstvaOvrheType.ZabiljezbaUZemljisnojKnjizi:
      return "Zabilježba ovrhe u zemljišnoj knjizi, utvrđenje vrijednosti nekretnine, prodaja nekretnine  i namirenje ovrhovoditelja iz iznosa dobivenoga prodajom";
    case SredstvaOvrheType.ZapljenaPokretnine:
      return "Zapljena pokretnine, procjena pokretnine, oduzimanje pokretnine, otpremanje pokretnine, povjeravanje pokretnine na čuvanje sudu, ovrhovoditelju ili trećoj osobi, prodaja pokretnine i namirenje ovrhovoditelja iz iznosa dobivenog prodajom";
    case SredstvaOvrheType.ZapljenaPokretninePutemJavnogKomisionara:
      return "Zapljena, procjena otpremanje i čuvanje pokretnine putem javnog komisionara te prodaja pokretnine i namirenje ovrhovoditelja iz iznosa dobivenog prodajom";
    case SredstvaOvrheType.ZabiljezbaNaMotornomVozilu:
      return "Zabilježba ovrhe na motornom vozilu, oduzimanje vozila i povjeravanje na  čuvanje vozila, procjena vozila, prodaja vozila i namirenje ovrhovoditelja";
    case SredstvaOvrheType.ZabiljezbaNaMotornomVoziluPutemJavnogKomisionara:
      return "Zabilježba ovrhe na motornom vozilu, oduzimanje vozila i  čuvanje vozila, procjena vozila putem javnog komisionara te prodaja vozila i namirenje ovrhovoditelja";
    case SredstvaOvrheType.PljenidbaDionica:
      return "Pljenidba, procjena i prodaja dionice te namirenje ovrhovoditelja";
    case SredstvaOvrheType.PljenidbaUdjela:
      return "Pljenidba udjela, procjena i prodaja udjela odnosno poslovnog udjela u trgovačkom društvu te namirenje ovrhovoditelja";
    case SredstvaOvrheType.PljenidbaVrijednosnica:
      return "Pljenidba, procjena vrijednosnice i prodaja vrijednosnice te namirenje ovrhovoditelja";
    case SredstvaOvrheType.ZapljenaTrazbine:
      return "Zapljena tražbine i prijenos tražbine na ovrhovoditelja te zapljena, procjena, oduzimanje i otpremanje pokretnine te povjeravanje pokretnine na čuvanje sudu, ovrhovoditelju ili trećoj osobi";
    case SredstvaOvrheType.ZapljenaTrazbineZabiljezbaOvrhe:
      return "Zapljena tražbine i prijenos tražbine na ovrhovoditelja te zabilježba ovrhe u zemljišnoj knjizi, utvrđenje vrijednosti nekretnine, prodaja nekretnine  i namirenje ovrhovoditelja";
    case SredstvaOvrheType.PljenidbaOProdajiPokretnina:
      return "Pljenidba prava i unovčenje prava u skladu s odredbama o prodaji pokretnina";
    default:
      return "";
  }
};

export const getVjerodostojnaIspravaTypeDisplayName = (
  type?: VjerodostojnaIspravaType
): string => {
  switch (type) {
    case VjerodostojnaIspravaType.Racun:
      return "Račun";
    case VjerodostojnaIspravaType.IzvadakIzPoslovnihKnjiga:
      return "Izvadak iz poslovnih knjiga";
    case VjerodostojnaIspravaType.Mjenica:
      return "Mjenica";
    case VjerodostojnaIspravaType.CekSProtestom:
      return "Ček s protestom i povratnim računima kad je to potrebno za zasnivanje tražbine";
    case VjerodostojnaIspravaType.JavnaIsprava:
      return "Javna isprava";
    case VjerodostojnaIspravaType.OvjerovljenaPrivatnaIsprava:
      return "Po zakonu ovjerovljena privatna isprava";
    case VjerodostojnaIspravaType.PosebnimPropisimaJavnaIsprava:
      return "Isprava koja se po posebnim propisima smatra javnom ispravom";
    case VjerodostojnaIspravaType.ObracunKamata:
      return "Obračun kamata";
    default:
      return "";
  }
};

export const getVjerodostojnaIspravaModel = (): DropdownOption[] => [
  {
    id: VjerodostojnaIspravaType.Racun.toString(),
    name: "Račun",
  },
  {
    id: VjerodostojnaIspravaType.IzvadakIzPoslovnihKnjiga.toString(),
    name: "Izvadak iz poslovnih knjiga",
  },
  {
    id: VjerodostojnaIspravaType.Mjenica.toString(),
    name: "Mjenica",
  },
  {
    id: VjerodostojnaIspravaType.CekSProtestom.toString(),
    name: "Ček s protestom i povratnim računima kad je to potrebno za zasnivanje tražbine",
  },
  {
    id: VjerodostojnaIspravaType.JavnaIsprava.toString(),
    name: "Javna isprava",
  },
  {
    id: VjerodostojnaIspravaType.OvjerovljenaPrivatnaIsprava.toString(),
    name: "Po zakonu ovjerovljena privatna isprava",
  },
  {
    id: VjerodostojnaIspravaType.PosebnimPropisimaJavnaIsprava.toString(),
    name: "Isprava koja se po posebnim propisima smatra javnom ispravom",
  },
  {
    id: VjerodostojnaIspravaType.ObracunKamata.toString(),
    name: "Obračun kamata",
  },
];

export const getZatezneKamateTypeDisplayName = (
  type: ZatezneKamateType
): string => {
  switch (type) {
    case ZatezneKamateType.KamatnaStopaClanak29TrgovackihUgovora:
      return "Kamatna stopa prema članku 29. Zakona o obveznim odnosima za odnose iz trgovačkih ugovora i ugovora između trgovca i osobe javnog prava";
    case ZatezneKamateType.KamatnaStopaClanak29OstaliOdnosi:
      return "Kamatna stopa prema članku 29. Zakona o obveznim odnosima za ostale odnose";
    case ZatezneKamateType.KamatnaStopaClanak12:
      return "Kamatna stopa prema članku 12.a. Zakona o financijskom poslovanju i predstečajnoj nagodbi za odnose između poduzetnika i poduzetnika i osoba javnog prava";
    case ZatezneKamateType.KamatnaStopaPrije01012008KamatnaStopaClanak29TrgovackihUgovora:
      return "Kamatna stopa po propisima prije 01.01.2008.g. te kamatna stopa prema članku 29. Zakona o obveznim odnosima za odnose iz trgovačkih ugovora i ugovora između trgovca i osobe javnog prava";
    case ZatezneKamateType.KamatnaStopaPrije01012008KamatnaStopaClanak29OstaliOdnos:
      return "Kamatna stopa po propisima prije 01.01.2008.g. te kamatna stopa prema članku 29. Zakona o obveznim odnosima za ostale odnose";
    default:
      return "";
  }
};

export const getZatezneKamateModel = (): DropdownOption[] => [
  {
    id: ZatezneKamateType.KamatnaStopaClanak29TrgovackihUgovora.toString(),
    name: "Kamatna stopa prema članku 29. Zakona o obveznim odnosima za odnose iz trgovačkih ugovora i ugovora između trgovca i osobe javnog prava",
  },
  {
    id: ZatezneKamateType.KamatnaStopaClanak29OstaliOdnosi.toString(),
    name: "Kamatna stopa prema članku 29. Zakona o obveznim odnosima za ostale odnose",
  },
  {
    id: ZatezneKamateType.KamatnaStopaClanak12.toString(),
    name: "Kamatna stopa prema članku 12.a. Zakona o financijskom poslovanju i predstečajnoj nagodbi za odnose između poduzetnika i poduzetnika i osoba javnog prava",
  },
  {
    id: ZatezneKamateType.KamatnaStopaPrije01012008KamatnaStopaClanak29TrgovackihUgovora.toString(),
    name: "Kamatna stopa po propisima prije 01.01.2008.g. te kamatna stopa prema članku 29. Zakona o obveznim odnosima za odnose iz trgovačkih ugovora i ugovora između trgovca i osobe javnog prava",
  },
  {
    id: ZatezneKamateType.KamatnaStopaPrije01012008KamatnaStopaClanak29OstaliOdnos.toString(),
    name: "Kamatna stopa po propisima prije 01.01.2008.g. te kamatna stopa prema članku 29. Zakona o obveznim odnosima za ostale odnose",
  },
];
