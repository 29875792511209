import { RouteComponentProps, withRouter } from 'react-router-dom';
import ExcelAttachmentForm from './ExcelAttachmentForm';
import { PrilogType } from '../../../../core/models/enum';

interface OwnProps {
    onClose: () => void;
    onSuccess: () => void;
    excelFileId: string;
    punomocnikIds?: string[];
    zakonskiZastupnikId?: string;
    attachmentType?: PrilogType;
}

export type Props = OwnProps & RouteComponentProps;

export default withRouter(ExcelAttachmentForm);
