import React, { ReactElement } from 'react';
import { Button, Empty, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { TableLocale } from 'antd/lib/table/interface';
import { translations } from '../config/translations';
import { DropdownOption } from '../core/models/DropdownOption';

export const getTableLocale = (): TableLocale => ({
    emptyText: <Empty description={translations.general.noData} />,
});

export const onRowClick = (column: { target: { nodeName: string } }, callback: Function): void => {
    if (column && column.target && column.target.nodeName === 'TD') {
        callback();
    }
};

export const formItemLayoutDrawer = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};



export const formItemCrudDrawer = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

export const formItemLayoutDrawer10_14 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};

export const formItemLayoutFullWidthDrawer = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

export const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

export const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 20,
            offset: 4,
        },
    },
};

export const headerFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 20,
            offset: 4,
        },
        sm: {
            span: 20,
            offset: 4,
        },
    },
};

export const ButtonDropdownSettings: React.CSSProperties = {
    zIndex: 10,
    float: 'right',
    marginTop: "10px",
    minWidth: 200,
};

export const customArrayValidationHelper = (
    _rule: any,
    callback: any,
    message: string,
    entityToValidate?: DropdownOption[]
): void => {
    if (!entityToValidate || entityToValidate.length === 0) {
        callback(message);
    } else {
        callback();
    }
};

export const customStringValidationHelper = (
    _rule: any,
    callback: any,
    message: string,
    entityToValidate?: string
): void => {
    if (!entityToValidate || entityToValidate.length === 0) {
        callback(message);
    } else {
        callback();
    }
};

export const genericTableProps = {
    style: { padding: '20px' },
    bordered: true,
    size: "small" as SizeType,
    scroll: { x: 120, y: 1000 },
    pagination: {
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "20", "50", "100"],
    },
    locale: getTableLocale(),
};

export const nestedTableProps = {
    bordered: true,
    size: "small" as SizeType,
    pagination: {
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "20", "50", "100"],
    },
    locale: getTableLocale(),
};

export const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
            <Input
                ref={(input: Input): void => input && input.focus()}
                placeholder={"Pretraži"}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => confirm()}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    {translations.general.search}
                </Button>
                <Button onClick={() => { clearFilters(); confirm(); }} size="small" style={{ width: 90 }}>
                    {translations.general.clear}
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered: any): ReactElement => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
});
