
export const defaultFormat = 'DD.MM.YYYY.';
export const defaultFormatWithTime = 'DD.MM.YYYY. HH:mm';

export const xlsxType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const pdfType = 'application/pdf';
export const zipType = 'application/x-zip-compressed';

export const API_HOST = "/api";

export const DRAWER_WIDTH = 600;

export const DRAWER_MEDIUM_WIDTH = 700;

export const DRAWER_FULL_WIDTH = 1000;

export const DRAWER_MAX_WIDTH = 1150;