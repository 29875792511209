/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from "axios";
import { ImportOutlined } from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import Table from "antd/lib/table";
import { UploadFile } from "antd/lib/upload/interface";
import { xlsxType } from "../../../../config/constants";
import Routes from '../../../../config/routes';
import { translations } from "../../../../config/translations";
import { Punomocnik } from "../../../../core/models/Punomocnik";
import { Ovrhovoditelj } from "../../../../core/models/Ovrhovoditelj";
import { ZakonskiZastupnik } from "../../../../core/models/ZakonskiZastupnik";
import { PravnaOsnovaZaZastupanje } from "../../../../core/models/PravnaOsnovaZaZastupanje";
import { DropdownOption } from "../../../../core/models/DropdownOption";
import { ExcelUploadResponse } from "../../../../core/models/ExcelUploadResponse";
import { OsobaTip, VezaniSudionikTip } from "../../../../core/models/enum";
import { ExcelErrorTableData } from "../../../../utils/models/ExcelErrorTableData";
import {
    filterDropdownOptions,
    confirmDropdownHelper,
} from "../../../../helpers/AutocompleteDropdownHelper";
import { customArrayValidationHelper, getTableLocale, headerFormItemLayout, formItemLayoutFullWidthDrawer } from "../../../../helpers/TableHelper";
import { showError } from "../../../../helpers/NotificationHelper";
import { useApiContext } from '../../../../helpers/ApiContext';
import { AutocompleteDropdown } from "../../../../components/autocomplete-dropdown";
import { DrawerButtons } from "../../../../components/drawer/buttons/DrawerButtons";
import { Props } from "./index";

const UploadExcelForm: React.FC<Props> = (props: Props) => {
    const { onClose, onSuccess } = props;
    const { postAsync, getAsync, userId } = useApiContext();
    const history = useHistory();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [selectedOvrhovoditelj, setSelectedOvrhovoditelj] = useState<DropdownOption[] | undefined>();
    const [selectedPunomocnici, setSelectedPunomocnik] = useState<DropdownOption[] | undefined>();
    const [selectedZakonskiZastupnik, setSelectedZakonskiZastupnik] = useState<DropdownOption[] | undefined>();
    const [ovrhovoditelji, setOvrhovoditelji] = useState<DropdownOption[]>([]);
    const [punomocnici, setPunomocnici] = useState<DropdownOption[]>([]);
    const [zakonskiZastupnici, setZakonskiZastupnici] = useState<DropdownOption[]>([]);
    const [pravneOsnoveZaZastupanjePunomocnik, setPravneOsnoveZaZastupanjePunomocnik] = useState<DropdownOption[]>([]);
    const [pravneOsnoveZaZastupanjeZakonskiZastupnik, setPravneOsnoveZaZastupanjeZakonskiZastupnik] = useState<DropdownOption[]>([]);
    const [selectedPravnaOsnovaZaZastupanjePunomocnik, setSelectedPravnaOsnovaZaZastupanjePunomocnik] = useState<DropdownOption[] | undefined>();
    const [selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik, setSelectedPravnaOsnovaZaZastupanjeZakonskiZastupnik] = useState<DropdownOption[] | undefined>();
    const [excelErrorTableData, setExcelErrorTableData] = useState<ExcelErrorTableData[]>([]);

    useEffect(() => {
        getOvrhovoditelji();
        getPunomocnici();
        getZakonskiZastupnici();
        getPravneOsnoveZaZastupanjePunomocnik();
        getPravneOsnoveZaZastupanjeZakonskiZastupnik();
    }, []);

    const getOvrhovoditelji = async () => {
        const data = await getAsync<Ovrhovoditelj[]>('/Ovrhovoditelji/GetAllByUser/' + userId);

        setOvrhovoditelji(data.map((
            item: Ovrhovoditelj,
        ): DropdownOption => ({ id: item.id!.toString(), name: item.tip === OsobaTip.FIZICKA_OSOBA ? `${item.ime} ${item.prezime}` : `${item.naziv}`  })));
    };

    const getPunomocnici = async () => {
        const data = await getAsync<Punomocnik[]>('/Punomocnici/GetAllByUser/' + userId);

        setPunomocnici(data.map((
            item: Punomocnik,
        ): DropdownOption => ({ id: item.id!.toString(), name: item.tip === VezaniSudionikTip.FIZICKA_OSOBA ? `${item.ime} ${item.prezime}` : `${item.naziv}`})));
    };

    const getZakonskiZastupnici = async () => {
        const data = await getAsync<ZakonskiZastupnik[]>('/ZakonskiZastupnici/GetAllByUser/' + userId);

        setZakonskiZastupnici(data.map((
            item: ZakonskiZastupnik,
        ): DropdownOption => ({ id: item.id!.toString(), name: item.tip === VezaniSudionikTip.FIZICKA_OSOBA ? `${item.ime} ${item.prezime}` : `${item.naziv}`})));
    };

    const getPravneOsnoveZaZastupanjePunomocnik = async () => {
        const data = await getAsync<PravnaOsnovaZaZastupanje[]>('/EOvrheAPI/GetPravneOsnoveZaZastupanjePunomocnik/' + userId);

        if (data) {
            setPravneOsnoveZaZastupanjePunomocnik(data.map((
                item: PravnaOsnovaZaZastupanje,
            ): DropdownOption => ({ id: item.id.toString(), name: `${item.naziv}` })));
        };
    };

    const getPravneOsnoveZaZastupanjeZakonskiZastupnik = async () => {
        const data = await getAsync<PravnaOsnovaZaZastupanje[]>('/EOvrheAPI/GetPravneOsnoveZaZastupanjeZakonskiZastupnik/' + userId);

        if (data) {
            setPravneOsnoveZaZastupanjeZakonskiZastupnik(data.map((
                item: PravnaOsnovaZaZastupanje,
            ): DropdownOption => ({ id: item.id.toString(), name: `${item.naziv}` })));
        };
    };

    const confirmOvrhovoditelj = (option: DropdownOption[]) => {
        const selectedOvrhovoditelj = confirmDropdownHelper(option);

        setSelectedOvrhovoditelj(selectedOvrhovoditelj);
    };

    const confirmPunomocnik = (option: DropdownOption[]) => {
        const selectedPunomocnik = confirmDropdownHelper(option);

        setSelectedPunomocnik(selectedPunomocnik);

    };

    const confirmZakonskiZastupnik = (option: DropdownOption[]) => {
        const selectedZakonskiZastupnik = confirmDropdownHelper(option);

        setSelectedZakonskiZastupnik(selectedZakonskiZastupnik);
    };

    const confirmPravnaOsobaZaZastupanjePunomocnik = (option: DropdownOption[]) => {
        const selectedPravnaOsnovaZaZastupanjePunomocnik = confirmDropdownHelper(option);

        setSelectedPravnaOsnovaZaZastupanjePunomocnik(selectedPravnaOsnovaZaZastupanjePunomocnik);
    };

    const confirmPravnaOsobaZaZastupanjeZakonskiZastupnik = (option: DropdownOption[]) => {
        const selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik = confirmDropdownHelper(option);

        setSelectedPravnaOsnovaZaZastupanjeZakonskiZastupnik(selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik);
    };

    const handleSubmit = async (values: any) => {
        let formData = new FormData();
        formData.append("file", fileList[0] as any, fileList[0].name);

        const data = await postAsync<ExcelUploadResponse>('/Excel/Upload', formData, translations.general.validating, undefined, translations.general.errorUpload);
        setExcelErrorTableData(data.errors);

        if (data.errors?.length === 0) {
            formData.append("ovrhovoditeljId", selectedOvrhovoditelj && selectedOvrhovoditelj[0].id as any);
            formData.append("applicationUserId", userId)
            if (selectedPunomocnici && selectedPunomocnici.length > 0) {
                selectedPunomocnici.forEach(punomocnik => {
                    formData.append("punomocnikIds[]", punomocnik.id);
                });
            }
            if (selectedZakonskiZastupnik) formData.append("zakonskiZastupnikId", selectedZakonskiZastupnik && selectedZakonskiZastupnik[0].id);
            if (selectedPravnaOsnovaZaZastupanjePunomocnik)
                formData.append("pravnaOsnovaZaZastupanjePunomocnikId", selectedPravnaOsnovaZaZastupanjePunomocnik && selectedPravnaOsnovaZaZastupanjePunomocnik[0].id);
            if (selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik)
                formData.append("pravnaOsnovaZaZastupanjeZakonskiZastupnikId", selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik && selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik[0].id);

            const saveData = await postAsync<AxiosResponse>('/Excel/Save', formData, translations.general.loading, translations.general.importSuccess, translations.general.errorSavingData);

            if (saveData) {
                history.push(`${Routes.ROUTE_EXCEL_FILES}/${saveData}`);
                onSuccess();
            }
        }
    };

    const columns = [
        {
            title: translations.excelTable.rowIndex,
            dataIndex: "rowIndex",
            width: 50
        },
        {
            title: translations.excelTable.columnIndex,
            dataIndex: "columnIndex",
            width: 50
        },
        {
            title: translations.excelTable.columnName,
            dataIndex: "columnName",
            width: 200
        },
        {
            title: translations.excelTable.cellValue,
            dataIndex: "cellValue",
            width: 140
        },
        {
            title: translations.excelTable.errorMessage,
            dataIndex: "errorMessage",
            width: 160
        },
    ];

    return (
        <Form onFinish={handleSubmit} {...formItemLayoutFullWidthDrawer} >
            <Form.Item  {...headerFormItemLayout} style={{ marginRight: '140px' }}>
                <DrawerButtons
                    onCancelAction={() => onClose()}
                    submitText={translations.general.importBtnText}
                    disableSubmit={fileList.length === 0}
                />
            </Form.Item>
            <Form.Item
                name="file"
                label={translations.general.file}
                rules={[
                    {
                        required: true,
                        message: translations.general.requiredField,
                    },
                ]}
            >
                <Upload
                    fileList={fileList}
                    onRemove={() => {
                        setFileList([]);
                    }}
                    beforeUpload={(file) => {
                        if (file.type !== xlsxType) {
                            showError(translations.general.wrongFileType);
                        } else {
                            setFileList([file]);
                        }
                        return false;
                    }}
                    accept={xlsxType}
                >
                    <Button type="primary">
                        <ImportOutlined />
                        {translations.general.upload}
                    </Button>
                </Upload>
            </Form.Item>
            <Form.Item
                name="ovrhovoditelji"
                label={translations.general.ovrhovoditelj}
                rules={[
                    {
                        required: true,
                        validator: (
                            _rule: any,
                            value: any,
                            callback: any
                        ): void =>
                            customArrayValidationHelper(
                                _rule,
                                callback,
                                'Ovrhovoditelj je obavezno polje!',
                                selectedOvrhovoditelj
                            ),
                    },
                ]}
            >
                <AutocompleteDropdown
                    placeholder={translations.general.ovrhovoditelji}
                    getOptionsFrontend={(value: string): DropdownOption[] =>
                        filterDropdownOptions(value, ovrhovoditelji)
                    }
                    initialValues={selectedOvrhovoditelj}
                    confirmDirty={confirmOvrhovoditelj}
                    style={{ width: '500px' }}
                    isAllowedToClear
                />
            </Form.Item>
            <Form.Item
                name="punomocnici"
                label={translations.general.punomocnik}>
                <AutocompleteDropdown
                    mode="multiple"
                    placeholder={translations.general.punomocnici}
                    getOptionsFrontend={(value: string): DropdownOption[] =>
                        filterDropdownOptions(value, punomocnici)
                    }
                    initialValues={selectedPunomocnici}
                    confirmDirty={confirmPunomocnik}
                    style={{ width: '500px' }}
                    isAllowedToClear
                />
            </Form.Item>
            {selectedPunomocnici && <Form.Item
                name="pravnaOsnovaZaZastupanjePunomocnik"
                label={translations.general.pravnaOsnovaZaZastupanje}
                style={{ marginLeft: '150px' }}
                rules={[
                    {
                        required: true,
                        validator: (
                            _rule: any,
                            value: any,
                            callback: any
                        ): void =>
                            customArrayValidationHelper(
                                _rule,
                                callback,
                                'Pravna osnova za zastupanje je obavezno polje!',
                                selectedPravnaOsnovaZaZastupanjePunomocnik
                            ),
                    },
                ]}
                >
                <AutocompleteDropdown
                    placeholder={translations.general.pravneOsnoveZaZastupanje}
                    getOptionsFrontend={(value: string): DropdownOption[] =>
                        filterDropdownOptions(value, pravneOsnoveZaZastupanjePunomocnik)
                    }
                    initialValues={selectedPravnaOsnovaZaZastupanjePunomocnik}
                    confirmDirty={confirmPravnaOsobaZaZastupanjePunomocnik}
                    style={{ width: '500px' }}
                    isAllowedToClear
                />
            </Form.Item>}
            <Form.Item
                name="zakonskiZastupnici"
                label={translations.general.zakonskiZastupnik}>
                <AutocompleteDropdown
                    placeholder={translations.general.zakonskiZastupnici}
                    getOptionsFrontend={(value: string): DropdownOption[] =>
                        filterDropdownOptions(value, zakonskiZastupnici)
                    }
                    initialValues={selectedZakonskiZastupnik}
                    confirmDirty={confirmZakonskiZastupnik}
                    style={{ width: '500px' }}
                    isAllowedToClear
                />
            </Form.Item>
            {selectedZakonskiZastupnik && <Form.Item
                name="pravnaOsnovaZaZastupanjeZakonskiZastupnik"
                label={translations.general.pravnaOsnovaZaZastupanje}
                style={{ marginLeft: '150px' }}
                rules={[
                    {
                        required: true,
                        validator: (
                            _rule: any,
                            value: any,
                            callback: any
                        ): void =>
                            customArrayValidationHelper(
                                _rule,
                                callback,
                                'Pravna osnova za zastupanje je obavezno polje!',
                                selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik
                            ),
                    },
                ]}>
                <AutocompleteDropdown
                    placeholder={translations.general.pravneOsnoveZaZastupanje}
                    getOptionsFrontend={(value: string): DropdownOption[] =>
                        filterDropdownOptions(value, pravneOsnoveZaZastupanjeZakonskiZastupnik)
                    }
                    initialValues={selectedPravnaOsnovaZaZastupanjeZakonskiZastupnik}
                    confirmDirty={confirmPravnaOsobaZaZastupanjeZakonskiZastupnik}
                    style={{ width: '500px' }}
                    isAllowedToClear
                />
            </Form.Item>}
            {excelErrorTableData && excelErrorTableData.length > 0 &&
                <Table
                    locale={getTableLocale()}
                    columns={columns}
                    dataSource={excelErrorTableData}
                    bordered
                    scroll={{ y: 400 }}
                />
            }
        </Form>
    );
}

export default UploadExcelForm;
