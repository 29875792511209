/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Table from 'antd/lib/table';
import { translations } from '../../../config/translations';
import { Punomocnik } from '../../../core/models/Punomocnik';
import { DrawerState, VezaniSudionikTip } from '../../../core/models/enum';
import { genericTableProps, getColumnSearchProps } from '../../../helpers/TableHelper';
import { onRowClick } from '../../../helpers/TableHelper';
import { useApiContext } from '../../../helpers/ApiContext';
import { getVezaniSudionikTypeDisplayName } from '../../../helpers/EnumHelper';
import { Props } from './index';

const PunomocniciTable: React.FC<Props> = (props: Props) => {
    const { shouldUpdate, setShouldUpdateState, handlePunomocnikDrawerChange } = props;
    const { getAsync, userId } = useApiContext();
    const [punomocniciTableData, setPunomocniciTableData] = useState<Punomocnik[] | undefined>([]);

    useEffect(() => {
        if (userId) {
            getPunomocniciTableData();
        }
    }, [userId]);

    useEffect(() => {
        if (shouldUpdate) {
            setShouldUpdateState(false);
            getPunomocniciTableData();
        }
    }, [shouldUpdate]);


    const getPunomocniciTableData = async () => {
        if (userId) {
            const data = await getAsync<Punomocnik[]>('/Punomocnici/GetAllByUser/' + userId);

            setPunomocniciTableData(data || []);
        }
    };

    const columns = [
        {
            title: translations.punomocnik.oib,
            dataIndex: 'oib',
            ...getColumnSearchProps('oib'),
            width: 110
        },
        {
            title: translations.punomocnik.tip,
            dataIndex: 'tip',
            width: 110,
            render: (row: VezaniSudionikTip) => getVezaniSudionikTypeDisplayName(row),
            filters: [
                { text: getVezaniSudionikTypeDisplayName(VezaniSudionikTip.FIZICKA_OSOBA), value: VezaniSudionikTip.FIZICKA_OSOBA },
                { text: getVezaniSudionikTypeDisplayName(VezaniSudionikTip.PRAVNA_OSOBA), value: VezaniSudionikTip.PRAVNA_OSOBA },
            ],
            onFilter: (value: any, record: Punomocnik) => record.tip === value,
        },
        {
            title: translations.punomocnik.ime,
            dataIndex: 'ime',
            ...getColumnSearchProps('ime'),
            width: 100
        },
        {
            title: translations.punomocnik.prezime,
            dataIndex: 'prezime',
            ...getColumnSearchProps('prezime'),
            width: 100
        },
        {
            title: translations.punomocnik.naziv,
            dataIndex: 'naziv',
            ...getColumnSearchProps('naziv'),
            width: 120
        },
        {
            title: translations.punomocnik.ulicaIKbr,
            dataIndex: 'ulicaIKbr',
            ...getColumnSearchProps('ulicaIKbr'),
            width: 130
        },
        {
            title: translations.punomocnik.postanskiBroj,
            dataIndex: 'postanskiBroj',
            ...getColumnSearchProps('postanskiBroj'),
            width: 100
        },
        {
            title: translations.punomocnik.naselje,
            dataIndex: 'naselje',
            ...getColumnSearchProps('naselje'),
            width: 100
        }
    ]

    return (
        <Table
            {...genericTableProps}
            columns={columns}
            className="cursorPointer"
            dataSource={punomocniciTableData}
            rowKey={(item): string => item.id!}
            onChange={getPunomocniciTableData}
            onRow={(item): { onClick: (column: any) => void } => ({
                onClick: (column: { target: { nodeName: string } }): void => onRowClick(column, (): void => handlePunomocnikDrawerChange(DrawerState.Edit, false, item.id)),
            })}
        />
    );
}

export default PunomocniciTable;