/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ReactElement } from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { AlignType } from "rc-table/lib/interface";
import { UploadFile } from "antd/lib/upload/interface";
import { SortOrder } from "antd/lib/table/interface";
import { Dropdown, Button, Drawer, Form, Tooltip, Upload, Modal } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DesktopOutlined,
  HighlightOutlined,
  SmallDashOutlined,
} from "@ant-design/icons";
import { AxiosResponse } from "axios";
import moment from "moment";
import { translations } from "../../../config/translations";
import {
  DRAWER_MAX_WIDTH,
  DRAWER_MEDIUM_WIDTH,
  pdfType,
} from "../../../config/constants";
import { dropdownStyle } from "../../../config/settings";
import { ExcelTableData, TableDataType, Trazbina, VjerodostojnaIsprava } from "../../../utils/models/ExcelTableData";
import {
  DrawerState,
  KamataType,
  OsobaTip,
  OsobaTipString,
  PdfSigningTypeEnum,
  SredstvaOvrheType,
  VjerodostojnaIspravaType,
  ZatezneKamateType,
} from "../../../core/models/enum";
import { TableAction } from "../../../core/models/TableAction";
import { PdfBase64 } from "../../../core/models/PdfBase64";
import { PrilogType } from "../../../core/models/enum";
import { formatDate, formatDateTime } from "../../../helpers/DateHelper";
import {
  genericTableProps,
  getColumnSearchProps,
  nestedTableProps,
} from "../../../helpers/TableHelper";
import { openPDFPreview } from "../../../helpers/CommonHelper";
import { useApiContext } from "../../../helpers/ApiContext";
import { showError } from "../../../helpers/NotificationHelper";
import {
  getKamataTypeDisplayName,
  getOsobaTypeDisplayName,
  getSredstvaOvrheTypeDisplayName,
  getVjerodostojnaIspravaTypeDisplayName,
  getZatezneKamateTypeDisplayName,
} from "../../../helpers/EnumHelper";
import { TableActionDropdown } from "../../../components/tableAction-dropdown/TableActionDropdown";
import UploadPdfForm from "../../../modules/excel/forms/uploadPdf";
import UpdateExcelRowForm from "../forms/updateExcelRow";
import UploadSignedPdfForm from "../../../modules/excel/forms/uploadSignedPdf";
import { Props } from "./index";
import UpdateVjerodostojnaIsprava from "../forms/updateVjerodostojnaIsprava";
import UpdateTrazbina from "../forms/updateTrazbina";

const ExcelTable: React.FC<Props> = (props: Props) => {
  const {
    excelFileId,
    shouldUpdate,
    opomeneUvjet,
    showDvijeTrazbine,
    showDvaOvrsenika,
    setShouldUpdateState,
    setShouldConditionsUpdateState,
  } = props;

  const { getAsync, postAsync } = useApiContext();
  //TODO: Refaktoriraj ovo - ima dosta ponavljajuceg ili krivo nazvanog koda
  const [excelTableData, setExcelTableData] = useState<ExcelTableData[] | undefined>([]);
  const [pdfDrawerState, setPdfDrawerState] = useState<DrawerState>( DrawerState.Closed);
  const [updateDrawerState, setUpdateDrawerState] = useState<DrawerState>(DrawerState.Closed);
  const [potpisaniPdfDrawerState, setPotpisaniPdfDrawerState] = useState<DrawerState>(DrawerState.Closed);
  const [clickedPrijedlogId, setClickedPrijedlogId] = useState<string>();
  const [clickedPrijedlog, setClickedPrijedlog] = useState<ExcelTableData | undefined>();
  const [clickedVjerodostojnaIsprava, setClickedVjerodostojnaIsprava] = useState<VjerodostojnaIsprava | undefined>();
  const [clickedTrazbina, setClickedTrazbina] = useState<Trazbina | undefined>();
  const [vjerodostojnaIspravaPdf, setVjerodostojnaIspravaPdf] = useState<UploadFile[] | undefined>();
  const [opomenaPlacenaModal, setOpomenaPlacenaModal] =useState<boolean>(false);
  const [clickedOpomenaId, setClickedOpomenaId] = useState<string>();
  const [shouldShowExpandableTable, setShouldShowExpandableTable] = useState<boolean>(false);

  useEffect(() => {
    getExcelTableData();
  }, [excelFileId]);

  useEffect(() => {
    if (vjerodostojnaIspravaPdf !== undefined) {
      saveVjerodostojnaIspravaPdf();
    }
  }, [vjerodostojnaIspravaPdf]);

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdateState(false);
      getExcelTableData();
    }
  }, [shouldUpdate]);

  const getExcelTableData = async () => {
    const data = await getAsync<ExcelTableData[]>(
      `/Prijedlozi/GetAllForExcelFile/${excelFileId}`
    );

    setExcelTableData(data || []);
    setShouldShowExpandableTable(data.some(item => item.vjerodostojneIsprave && item.vjerodostojneIsprave.length > 0))
  };

  const handlePdfDrawerChange = (
    newPdfDrawerState: DrawerState,
    shouldUpdate: boolean,
    id?: string
  ) => {
    setPdfDrawerState(newPdfDrawerState);
    setClickedPrijedlogId(id);

    if (shouldUpdate) {
      getExcelTableData();
      setShouldConditionsUpdateState(true);
    }
  };

  const handleUpdateDrawerChange = (
    newUpdaDrawerState: DrawerState,
    shouldUpdate: boolean,
    newClicked?: ExcelTableData | VjerodostojnaIsprava | Trazbina,
    type? : TableDataType
  ) => {
    setUpdateDrawerState(newUpdaDrawerState);

    if(!newClicked && newUpdaDrawerState === DrawerState.Closed) {
      setClickedPrijedlog(undefined);
      setClickedVjerodostojnaIsprava(undefined);
      setClickedTrazbina(undefined);
    }

    if(newClicked && type === TableDataType.ExcelTableData) {
      setClickedPrijedlog(newClicked as ExcelTableData);
    } else if(newClicked && type === TableDataType.VjerodostojnaIsprava) {
      setClickedVjerodostojnaIsprava(newClicked as VjerodostojnaIsprava);
    } else if(newClicked && type === TableDataType.Trazbina) {
      setClickedTrazbina(newClicked as Trazbina);
    }

    if (shouldUpdate) {
      getExcelTableData();
      setShouldConditionsUpdateState(true);
    }
  };

  const handlePotpisaniPdfDrawerChange = (
    newPdfDrawerState: DrawerState,
    shouldUpdate: boolean,
    id?: string
  ) => {
    setPotpisaniPdfDrawerState(newPdfDrawerState);
    setClickedPrijedlogId(id);
    if (shouldUpdate) {
      getExcelTableData();
    }
  };

  const getOpomenaPDFBase64 = async (id: string) => {
    const data = await getAsync<PdfBase64>(
      "/Prijedlozi/GetOpomenaPdfBase64/" + id
    );

    if (data) {
      openPDFPreview(data.base64, translations.general.opomena);
    }
  };

  const getGeneriraniPDFBase64 = async (id: string) => {
    const data = await getAsync<PdfBase64>(
      "/Prijedlozi/GetPrijedlogPdfBase64/" + id
    );

    if (data) {
      openPDFPreview(data.base64, translations.general.prijedlog);
    }
  };

  const getPotpisaniPDFBase64 = async (id: string) => {
    const data = await getAsync<PdfBase64>(
      "/Prijedlozi/GetPotpisaniPrijedlogPdfBase64/" + id
    );

    if (data) {
      openPDFPreview(data.base64, translations.general.prijedlog);
    }
  };

  const getPoslaniPDFBase64 = async (id: string) => {
    const data = await getAsync<PdfBase64>(
      "/Prijedlozi/GetPotvrdaPdfBase64/" + id
    );

    if (data) {
      openPDFPreview(data.base64, translations.general.prijedlog);
    }
  };

  const renderTooltip = (
    value: string,
    length: number
  ): ReactElement | string => {
    if (!value || value.length < length) {
      return value;
    }
    return (
      <Tooltip placement="topLeft" title={value}>
        {value && `${value.substring(0, length - 3)}...`}
      </Tooltip>
    );
  };

  const saveVjerodostojnaIspravaPdf = async () => {
    let formData = new FormData();

    formData.append("pdf", vjerodostojnaIspravaPdf![0] as any);
    formData.append("type", PrilogType.VjerodostojnaIsprava as any);
    formData.append("prijedlogId", clickedPrijedlogId as any);

    const data = await postAsync<AxiosResponse>(
      "/Files/UploadPrijedlogPdf",
      formData,
      undefined,
      translations.general.importSuccess,
      translations.general.errorSavingData
    );

    if (data) {
      setVjerodostojnaIspravaPdf(undefined);
      getExcelTableData();
      setShouldConditionsUpdateState(true);
    }
  };

  const handleOpomenaModal = (newState: boolean) => {
    setOpomenaPlacenaModal(newState);
  };

  const handleOpomenaOkModal = async (value: boolean) => {
    let formData = new FormData();

    formData.append("opomenaPlacena", value as any);
    formData.append("prijedlogId", clickedOpomenaId as any);

    const data = await postAsync<AxiosResponse>(
      "/Prijedlozi/ChangeOpomenaPlacena",
      formData
    );

    if (data) {
      getExcelTableData();
    }

    handleOpomenaModal(false);
  };

  const actionsContent = (value: ExcelTableData): TableAction[] => {
    const returnActions: TableAction[] = [
      {
        id: 1,
        entity: value,
        className: "details-action-button",
        label: translations.general.edit,
        onClick: (): void =>
          handleUpdateDrawerChange(DrawerState.Create, false, value, TableDataType.ExcelTableData),
        disabled: !!value.prijedlogNapravljen,
      },
      {
        id: 2,
        entity: value,
        className: "details-action-button",
        label: translations.excelTable.dodajPrivitak,
        onClick: (): void =>
          handlePdfDrawerChange(DrawerState.Create, false, value.id),
      },
      {
        id: 3,
        entity: value,
        className: "details-action-button",
        label: translations.excelTable.dodajPotpisaniPdf,
        onClick: (): void =>
          handlePotpisaniPdfDrawerChange(DrawerState.Create, false, value.id),
        disabled: !value.prijedlogNapravljen,
      },
    ];

    const opomenaAction: TableAction = {
      id: 4,
      entity: value,
      className: "details-action-button",
      label: translations.excelTable.promijeniStatusOpomene,
      onClick: (): void => {
        handleOpomenaModal(true);
        setClickedOpomenaId(value.id);
      },
      disabled: !opomeneUvjet,
    };

    if (opomeneUvjet) {
      returnActions.push(opomenaAction);
    }

    return returnActions;
  };

  const vjerodostojnaIspravaActionsContent = (value: VjerodostojnaIsprava): TableAction[] => {
    return [
      {
        id: 1,
        entity: value,
        className: "details-action-button",
        label: translations.general.edit,
        onClick: (): void =>
          handleUpdateDrawerChange(DrawerState.Create, false, value, TableDataType.VjerodostojnaIsprava),
        //TODO:
        // disabled: !!value.prijedlogNapravljen,
      },
    ]
  }

  const trazbinaActionsContent = (value: Trazbina): TableAction[] => {
    return [
      {
        id: 1,
        entity: value,
        className: "details-action-button",
        label: translations.general.edit,
        onClick: (): void =>
          handleUpdateDrawerChange(DrawerState.Create, false, value, TableDataType.Trazbina),
        //TODO:s
        // disabled: !!value.prijedlogNapravljen,
      },
    ]
  }

  const renderVjerodostojnaIspravaTable = (isprave: VjerodostojnaIsprava[]) => (
    <Table
      {...nestedTableProps}
      columns={vjerodostojnaIspravaColumns}
      dataSource={isprave}
      pagination={false}
      rowKey="id"
      expandable={{
        expandedRowRender: record => renderTrazbinaTable(record.trazbine),
      }}
    />
  );

  const renderTrazbinaTable = (trazbine: Trazbina[]) => {
    const totalGlavnica = calculateTotalGlavnica(trazbine);

    return ( 
      <Table {...nestedTableProps}
        columns={trazbinaColumns}
        dataSource={trazbine}
        pagination={false}
        rowKey="id"
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell index={-1} colSpan={1}>{translations.general.total}: {totalGlavnica.toFixed(2)}</Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      />
    );
  };

  const calculateTotalGlavnica = (trazbine: Trazbina[]) => {
    return trazbine.reduce((total, item) => total + (item.glavnica || 0), 0);
  };

  const columns: ColumnsType<ExcelTableData> = [
    {
      title: translations.excelTable.akcije,
      dataIndex: "",
      key: "",
      width: 50,
      render: (value: ExcelTableData): React.ReactElement => (
        <Dropdown
          overlay={<TableActionDropdown tableActions={actionsContent(value)} />}
          overlayStyle={dropdownStyle}
        >
          <Button
            shape="circle"
            type="dashed"
            id="actionsButton"
            className="do-not-use-on-row-function"
          >
            <SmallDashOutlined />
          </Button>
        </Dropdown>
      ),
    },
    {
      title: translations.excelTable.redniBroj,
      dataIndex: "redniBroj",
      width: 60,
      sorter: (a: ExcelTableData, b: ExcelTableData) =>
        a.redniBroj! - b.redniBroj!,
      defaultSortOrder: "ascend" as SortOrder,
    },
    {
      title: translations.excelTable.interniId,
      dataIndex: "interniId",
      width: 100,
      render: (value: string) => renderTooltip(value, 13),
      sorter: (a: ExcelTableData, b: ExcelTableData) => {
        const c = a.interniId || "";
        const d = b.interniId || "";
        return c.localeCompare(d);
      },
    },
    {
      title: translations.excelTable.ostaliPrivitci,
      dataIndex: "ostaliPrivitci",
      render: (value: string) => renderTooltip(value, 14),
      width: 120,
      sorter: (a: ExcelTableData, b: ExcelTableData) => {
        const c = a.ostaliPrivitci || "";
        const d = b.ostaliPrivitci || "";
        return c.localeCompare(d);
      },
    },
    {
      title: translations.excelTable.ostaliPrivitciOpisi,
      dataIndex: "ostaliPrivitciOpisi",
      width: 120,
      render: (value: string) => renderTooltip(value, 14),
      sorter: (a: ExcelTableData, b: ExcelTableData) => {
        const c = a.ostaliPrivitciOpisi || "";
        const d = b.ostaliPrivitciOpisi || "";
        return c.localeCompare(d);
      },
    },
    {
      title: translations.excelTable.jedinstveniIdentifikator,
      dataIndex: "jedinstveniIdentifikator",
      width: 150,
      sorter: (a: ExcelTableData, b: ExcelTableData) => {
        const c = a.jedinstveniIdentifikator || "";
        const d = b.jedinstveniIdentifikator || "";
        return c.localeCompare(d);
      },
      ...getColumnSearchProps("jedinstveniIdentifikator"),
    },
    {
      title: translations.excelTable.opomenaPoslana,
      dataIndex: "opomenaPoslana",
      width: 105,
      align: "center" as AlignType,
      render: (value: boolean) =>
        value ? translations.general.da : translations.general.ne,
      filters: [
        { text: translations.general.da, value: true },
        { text: translations.general.ne, value: false },
      ],
      onFilter: (value: any, record: ExcelTableData) =>
        record.opomenaPoslana === value,
      hidden: !opomeneUvjet,
    },
    {
      title: translations.excelTable.datumZaOpomenaPoslana,
      dataIndex: "datumZaOpomenaPoslana",
      width: 140,
      render: (value: Date) => formatDateTime(value),
      sorter: (a: ExcelTableData, b: ExcelTableData) =>
        moment(a.datumZaOpomenaPoslana).unix() -
        moment(b.datumZaOpomenaPoslana).unix(),
      hidden: !opomeneUvjet,
    },
    {
      title: translations.excelTable.opomenaPlacena,
      dataIndex: "opomenaPlacena",
      width: 100,
      align: "center" as AlignType,
      render: (value: boolean, row: ExcelTableData) =>
        value ? (
          <Button
            type="text"
            onClick={() => {
              handleOpomenaModal(true);
              setClickedOpomenaId(row.id);
            }}
          >
            {translations.general.da}
          </Button>
        ) : (
          <Button
            type="text"
            onClick={() => {
              handleOpomenaModal(true);
              setClickedOpomenaId(row.id);
            }}
          >
            {translations.general.ne}
          </Button>
        ),
      filters: [
        { text: translations.general.da, value: true },
        { text: translations.general.ne, value: false },
      ],
      onFilter: (value: any, record: ExcelTableData) =>
        record.opomenaPlacena === value,
      hidden: !opomeneUvjet,
    },
    {
      title: translations.excelTable.opomena,
      dataIndex: "opomenaPoslana",
      width: 100,
      align: "center" as AlignType,
      render: (value: boolean, row: ExcelTableData) =>
        value ? (
          <Button onClick={(): Promise<void> => getOpomenaPDFBase64(row.id)}>
            {translations.excelTable.PDF}
          </Button>
        ) : (
          ""
        ),
      hidden: !opomeneUvjet,
    },
    {
      title: translations.excelTable.vjerodostojnaIspravaPrilozena,
      dataIndex: "vjerodostojnaIspravaPrilozena",
      width: 160,
      align: "center" as AlignType,
      render: (value: boolean, record: ExcelTableData) =>
        value ? (
          <CheckOutlined style={{ width: "100%", color: "green" }} />
        ) : (
          <Upload
            style={{ width: "100%" }}
            className="cursorPointer"
            beforeUpload={(file) => {
              if (file.type !== pdfType) {
                showError(translations.general.wrongFileType);
              } else {
                setClickedPrijedlogId(record.id);
                setVjerodostojnaIspravaPdf([file]);
              }
              return false;
            }}
            accept={pdfType}
          >
            <CloseOutlined style={{ width: "100%", color: "red" }} />
          </Upload>
        ),
      filters: [
        { text: translations.general.da, value: true },
        { text: translations.general.ne, value: false },
      ],
      onFilter: (value: any, record: ExcelTableData) =>
        record.vjerodostojnaIspravaPrilozena === value,
    },
    {
      title: translations.excelTable.prijedlogNapravljen,
      dataIndex: "prijedlogNapravljen",
      width: 100,
      align: "center" as AlignType,
      render: (value: boolean) =>
        value ? translations.general.da : translations.general.ne,
      filters: [
        { text: translations.general.da, value: true },
        { text: translations.general.ne, value: false },
      ],
      onFilter: (value: any, record: ExcelTableData) =>
        record.prijedlogNapravljen === value,
    },
    {
      title: translations.excelTable.datumZaPrijedlogNapravljen,
      dataIndex: "datumZaPrijedlogNapravljen",
      width: 140,
      render: (value: Date) => formatDateTime(value),
      sorter: (a: ExcelTableData, b: ExcelTableData) =>
        moment(
          a.datumZaPrijedlogNapravljen /*ako hocemo u desc nullove na vrh || "0001.01.01"*/
        ).unix() -
        moment(b.datumZaPrijedlogNapravljen /* || "0001.01.01"*/).unix(),
    },
    {
      title: translations.excelTable.generiraniPDF,
      dataIndex: "prijedlogNapravljen",
      width: 100,
      align: "center" as AlignType,
      render: (value: boolean, row: ExcelTableData) =>
        value ? (
          <Button onClick={(): Promise<void> => getGeneriraniPDFBase64(row.id)}>
            {translations.excelTable.PDF}
          </Button>
        ) : (
          ""
        ),
    },
    {
      title: translations.excelTable.prijedlogPotpisan,
      dataIndex: "prijedlogPotpisan",
      width: 100,
      align: "center" as AlignType,
      render: (value: boolean) =>
        value ? translations.general.da : translations.general.ne,
      filters: [
        { text: translations.general.da, value: true },
        { text: translations.general.ne, value: false },
      ],
      onFilter: (value: any, record: ExcelTableData) =>
        record.prijedlogPotpisan === value,
    },
    {
      title: translations.excelTable.datumZaPrijedlogPotpisan,
      dataIndex: "datumZaPrijedlogPotpisan",
      width: 140,
      render: (value: Date) => formatDateTime(value),
      sorter: (a: ExcelTableData, b: ExcelTableData) =>
        moment(a.datumZaPrijedlogNapravljen).unix() -
        moment(b.datumZaPrijedlogNapravljen).unix(),
    },
    {
      title: translations.excelTable.vrstaPotpisa,
      dataIndex: "pdfSigningType",
      width: 120,
      align: "center" as AlignType,
      render: (value: PdfSigningTypeEnum) =>
        value ? (
          value === PdfSigningTypeEnum.App ? (
            <DesktopOutlined style={{ width: "100%", color: "green" }} />
          ) : (
            <HighlightOutlined style={{ width: "100%", color: "red" }} />
          )
        ) : (
          ""
        ),
      filters: [
        {
          text: <DesktopOutlined style={{ width: "100%", color: "green" }} />,
          value: PdfSigningTypeEnum.App,
        },
        {
          text: <HighlightOutlined style={{ width: "100%", color: "red" }} />,
          value: PdfSigningTypeEnum.Upload,
        },
      ],
      onFilter: (value: any, record: ExcelTableData) =>
        record.pdfSigningType === value,
    },
    {
      title: translations.excelTable.potpisaniPDF,
      dataIndex: "prijedlogPotpisan",
      width: 100,
      align: "center" as AlignType,
      render: (value: boolean, row: ExcelTableData) =>
        value ? (
          <Button onClick={(): Promise<void> => getPotpisaniPDFBase64(row.id)}>
            {translations.excelTable.PDF}
          </Button>
        ) : (
          ""
        ),
    },
    {
      title: translations.excelTable.poslanoNaSud,
      dataIndex: "poslanoNaSud",
      width: 100,
      align: "center" as AlignType,
      render: (value: boolean) =>
        value ? translations.general.da : translations.general.ne,
      filters: [
        { text: translations.general.da, value: true },
        { text: translations.general.ne, value: false },
      ],
      onFilter: (value: any, record: ExcelTableData) =>
        record.poslanoNaSud === value,
    },
    {
      title: translations.excelTable.datumZaPoslanoNaSud,
      dataIndex: "datumZaPoslanoNaSud",
      width: 140,
      render: (value: Date) => formatDateTime(value),
      sorter: (a: ExcelTableData, b: ExcelTableData) =>
        moment(a.datumZaPoslanoNaSud).unix() -
        moment(b.datumZaPoslanoNaSud).unix(),
    },
    {
      title: translations.excelTable.poslaniPDF,
      dataIndex: "poslanoNaSud",
      width: 100,
      align: "center" as AlignType,
      render: (value: boolean, row: ExcelTableData) =>
        value ? (
          <Button onClick={(): Promise<void> => getPoslaniPDFBase64(row.id)}>
            {translations.excelTable.PDF}
          </Button>
        ) : (
          ""
        ),
    },
    {
      title: translations.excelTable.oznakaPredmeta,
      dataIndex: "oznakaPredmeta",
      width: 120,
      ...getColumnSearchProps("oznakaPredmeta"),
    },
    {
      title: translations.excelTable.nazivSuda,
      dataIndex: "nazivSuda",
      width: 150,
      render: (value: string) => renderTooltip(value, 18),
      ...getColumnSearchProps("nazivSuda"),
    },
    {
      title: translations.excelTable.nazivUreda,
      dataIndex: "nazivUreda",
      width: 150,
      render: (value: string) => renderTooltip(value, 18),
      ...getColumnSearchProps("nazivUreda"),
    },
    {
      title: translations.excelTable.tip,
      dataIndex: "tip",
      width: 120,
      render: (value: OsobaTip): string => getOsobaTypeDisplayName(value),
      filters: [
        {
          text: getOsobaTypeDisplayName(OsobaTip.FIZICKA_OSOBA),
          value: OsobaTipString.FIZICKA_OSOBA,
        },
        {
          text: getOsobaTypeDisplayName(OsobaTip.PRAVNA_OSOBA),
          value: OsobaTipString.PRAVNA_OSOBA,
        },
        {
          text: getOsobaTypeDisplayName(OsobaTip.OSTALO),
          value: OsobaTipString.OSTALO,
        },
      ],
      onFilter: (value: any, record: ExcelTableData) => record.tip === value,
    },
    {
      title: translations.excelTable.oIB,
      dataIndex: "oib",
      width: 110,
      ...getColumnSearchProps("oib"),
    },
    {
      title: translations.excelTable.ime,
      dataIndex: "ime",
      width: 120,
      ...getColumnSearchProps("ime"),
    },
    {
      title: translations.excelTable.prezime,
      dataIndex: "prezime",
      width: 140,
      render: (value: string) => renderTooltip(value, 16),
      ...getColumnSearchProps("prezime"),
    },
    {
      title: translations.excelTable.naziv,
      dataIndex: "naziv",
      width: 120,
      ...getColumnSearchProps("naziv"),
    },
    {
      title: translations.excelTable.adresa,
      dataIndex: "adresa",
      width: 160,
      ...getColumnSearchProps("adresa"),
      render: (value: string) => renderTooltip(value, 18),
    },
    {
      title: translations.excelTable.postanskiBroj,
      dataIndex: "postanskiBroj",
      width: 100,
      ...getColumnSearchProps("postanskiBroj"),
    },
    {
      title: translations.excelTable.mjesto,
      dataIndex: "mjesto",
      width: 120,
      ...getColumnSearchProps("mjesto"),
      render: (value: string) => renderTooltip(value, 14),
    },
    {
      title: translations.excelTable.nazivObrtaOpgaSamostalneDjelatnosti,
      dataIndex: "nazivObrtaOpgaSamostalneDjelatnosti",
      width: 160,
    },
    {
      title: translations.excelTable.racunSpecificneNamjene,
      dataIndex: "racunSpecificneNamjene",
      width: 140,
    },
    {
      title: translations.excelTable.sredstvaOvrheIdevi,
      dataIndex: "sredstvaOvrheIdevi",
      width: 180,
      render: (value: SredstvaOvrheType): ReactElement | string =>
        renderTooltip(getSredstvaOvrheTypeDisplayName(value), 22),
    },
    {
      title: translations.excelTable.dvaOvrsenika,
      dataIndex: "dvaOvrsenika",
      width: 120,
      hidden: !showDvaOvrsenika,
    },
    {
      title: translations.excelTable.tip2,
      dataIndex: "tip2",
      width: 120,
      render: (value: OsobaTip): string => getOsobaTypeDisplayName(value),
      hidden: !showDvaOvrsenika,
      filters: [
        {
          text: getOsobaTypeDisplayName(OsobaTip.FIZICKA_OSOBA),
          value: OsobaTipString.FIZICKA_OSOBA,
        },
        {
          text: getOsobaTypeDisplayName(OsobaTip.PRAVNA_OSOBA),
          value: OsobaTipString.PRAVNA_OSOBA,
        },
        {
          text: getOsobaTypeDisplayName(OsobaTip.OSTALO),
          value: OsobaTipString.OSTALO,
        },
      ],
      onFilter: (value: any, record: ExcelTableData) => record.tip === value,
    },
    {
      title: translations.excelTable.oIB2,
      dataIndex: "oib2",
      width: 110,
      hidden: !showDvaOvrsenika,
      ...getColumnSearchProps("oib2"),
    },
    {
      title: translations.excelTable.ime2,
      dataIndex: "ime2",
      width: 120,
      hidden: !showDvaOvrsenika,
      ...getColumnSearchProps("ime2"),
    },
    {
      title: translations.excelTable.prezime2,
      dataIndex: "prezime2",
      width: 140,
      render: (value: string) => renderTooltip(value, 16),
      hidden: !showDvaOvrsenika,
      ...getColumnSearchProps("prezime2"),
    },
    {
      title: translations.excelTable.naziv2,
      dataIndex: "naziv2",
      width: 120,
      hidden: !showDvaOvrsenika,
      ...getColumnSearchProps("naziv2"),
    },
    {
      title: translations.excelTable.adresa2,
      dataIndex: "adresa2",
      width: 160,
      render: (value: string) => renderTooltip(value, 18),
      hidden: !showDvaOvrsenika,
      ...getColumnSearchProps("adresa2"),
    },
    {
      title: translations.excelTable.postanskiBroj2,
      dataIndex: "postanskiBroj2",
      width: 100,
      hidden: !showDvaOvrsenika,
      ...getColumnSearchProps("postanskiBroj2"),
    },
    {
      title: translations.excelTable.mjesto2,
      dataIndex: "mjesto2",
      width: 120,
      render: (value: string) => renderTooltip(value, 14),
      hidden: !showDvaOvrsenika,
      ...getColumnSearchProps("mjesto2"),
    },
    {
      title: translations.excelTable.nazivObrtaOpgaSamostalneDjelatnosti2,
      dataIndex: "nazivObrtaOpgaSamostalneDjelatnosti2",
      width: 180,
      hidden: !showDvaOvrsenika,
    },
    {
      title: translations.excelTable.racunSpecificneNamjene2,
      dataIndex: "racunSpecificneNamjene2",
      width: 140,
      hidden: !showDvaOvrsenika,
    },
    {
      title: translations.excelTable.sredstvaOvrheIdevi2,
      dataIndex: "sredstvaOvrheIdevi2",
      width: 180,
      render: (value: SredstvaOvrheType): ReactElement | string =>
        renderTooltip(getSredstvaOvrheTypeDisplayName(value), 22),
      hidden: !showDvaOvrsenika,
    },
    {
      title: translations.excelTable.vrstaOdgovornostiOvrsenika,
      dataIndex: "vrstaOdgovornostiOvrsenika",
      width: 140,
    },
    {
      title: translations.excelTable.vrstaVjerodostojneIsprave,
      dataIndex: "vrstaVjerodostojneIspraveId",
      width: 180,
      render: (value: VjerodostojnaIspravaType): ReactElement | string =>
        renderTooltip(getVjerodostojnaIspravaTypeDisplayName(value), 22),
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.oznakaVjerodostojneIsprave,
      dataIndex: "oznakaVjerodostojneIsprave",
      width: 160,
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.datumVjerodostojneIsprave,
      dataIndex: "datumVjerodostojneIsprave",
      render: (value: Date) => formatDate(value),
      width: 160,
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.opisTrazbine,
      dataIndex: "opisTrazbine",
      width: 200,
      render: (value: string) => renderTooltip(value, 20),
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.glavnica,
      dataIndex: "glavnica",
      width: 120,
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.obracunKamatneStope,
      dataIndex: "obracunKamatneStope",
      width: 150,
      render: (value: KamataType) =>
        renderTooltip(getKamataTypeDisplayName(value), 18),
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.vrstaZakonskeZatezneKamate,
      dataIndex: "vrstaZakonskeZatezneKamate",
      width: 180,
      render: (value: ZatezneKamateType): ReactElement | string =>
        renderTooltip(getZatezneKamateTypeDisplayName(value), 22),
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.ugovornaKamatnaStopa,
      dataIndex: "ugovornaKamatnaStopa",
      width: 120,
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.kamataPoRanijemZakonuOpis,
      dataIndex: "kamataPoRanijemZakonuOpis",
      width: 150,
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.datumPocetkaTijekaKamate,
      dataIndex: "datumPocetkaTijekaKamate",
      render: (value: Date) => formatDate(value),
      width: 120,
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.kamata,
      dataIndex: "kamata",
      width: 120,
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.datumPrethodnoObracunateKamate,
      dataIndex: "datumPrethodnoObracunateKamate",
      render: (value: Date) => formatDate(value),
      width: 140,
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.prethodnoObracunataKamata,
      dataIndex: "prethodnoObracunataKamata",
      width: 160,
      hidden: shouldShowExpandableTable,
    },
    {
      title: translations.excelTable.brojRacuna,
      dataIndex: "brojRacuna",
      width: 140,
      render: (value: string) => renderTooltip(value, 16),
    },
    {
      title: translations.excelTable.model,
      dataIndex: "model",
      width: 120,
    },
    {
      title: translations.excelTable.pozivNaBroj,
      dataIndex: "pozivNaBroj",
      width: 120,
    },
    {
      title: translations.excelTable.dvijeTrazbine,
      dataIndex: "dvijeTrazbine",
      width: 120,
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.glavnica2,
      dataIndex: "glavnica2",
      width: 120,
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.obracunKamatneStope2,
      dataIndex: "obracunKamatneStope2",
      width: 150,
      render: (value: KamataType) =>
        renderTooltip(getKamataTypeDisplayName(value), 18),
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.vrstaZakonskeZatezneKamate2,
      dataIndex: "vrstaZakonskeZatezneKamate2",
      width: 140,
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.ugovornaKamatnaStopa2,
      dataIndex: "ugovornaKamatnaStopa2",
      width: 140,
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.kamataPoRanijemZakonuOpis2,
      dataIndex: "kamataPoRanijemZakonuOpis2",
      width: 150,
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.datumPocetkaTijekaKamate2,
      dataIndex: "datumPocetkaTijekaKamate2",
      render: (value: Date) => formatDate(value),
      width: 120,
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.kamata2,
      dataIndex: "kamata2",
      width: 120,
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.datumPrethodnoObracunateKamate2,
      dataIndex: "datumPrethodnoObracunateKamate2",
      render: (value: Date) => formatDate(value),
      width: 160,
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.prethodnoObracunataKamata2,
      dataIndex: "prethodnoObracunataKamata2",
      width: 160,
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.brojRacuna2,
      dataIndex: "brojRacuna2",
      width: 140,
      render: (value: string) => renderTooltip(value, 16),
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.model2,
      dataIndex: "model2",
      width: 120,
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.pozivNaBroj2,
      dataIndex: "pozivNaBroj2",
      width: 120,
      hidden: !showDvijeTrazbine,
    },
    {
      title: translations.excelTable.troskoviPostupkaKamataPovrat,
      dataIndex: "troskoviPostupkaKamataPovrat",
      width: 140,
    },
    {
      title: translations.excelTable.interniBrojPredmeta,
      dataIndex: "interniBrojPredmeta",
      width: 140,
    },
    {
      title: translations.excelTable.oznakaSredstvaDuga,
      dataIndex: "oznakaSredstvaDuga",
      width: 140,
      render: (value: string) => renderTooltip(value, 16),
    },
  ].filter((item) => !item.hidden);

  const vjerodostojnaIspravaColumns = [
    {
      title: translations.excelTable.vrstaVjerodostojneIsprave,
      dataIndex: "vrstaId",
      width: 250,
      align: "center" as AlignType,
      render: (value: VjerodostojnaIspravaType): ReactElement | string =>
        renderTooltip(getVjerodostojnaIspravaTypeDisplayName(value), 250),
    },
    { 
      title: translations.excelTable.sifraDuznika, 
      dataIndex: 'oznaka',
      width: 400,
      align: "center" as AlignType,
      key: 'oznaka',
    },
    { 
      title: translations.excelTable.datumVjerodostojneIsprave, 
      dataIndex: 'datum',
      render: (value: Date) => formatDate(value),
      width: 400,
      align: "center" as AlignType,
      key: 'datum',
    },
    { 
      title: translations.excelTable.opisTrazbine, 
      dataIndex: 'opisTrazbina',
      width: 500,
      align: "center" as AlignType,
      key: 'opisTrazbina',
    },
    {
      title: translations.excelTable.akcije,
      dataIndex: "",
      key: "",
      width: 50,
      render: (value: VjerodostojnaIsprava): React.ReactElement => (
        <Dropdown
          overlay={<TableActionDropdown tableActions={vjerodostojnaIspravaActionsContent(value)} />}
          overlayStyle={dropdownStyle}
        >
          <Button
            shape="circle"
            type="dashed"
            id="actionsButton"
            className="do-not-use-on-row-function"
          >
            <SmallDashOutlined />
          </Button>
        </Dropdown>
      ),
    },
    {
      title: '',
    },
  ];

  const trazbinaColumns = [
    { 
      title: translations.excelTable.glavnica, 
      dataIndex: 'glavnica',
      width: 150,
      align: "center" as AlignType,
      key: 'glavnica',
    },
    {
      title: translations.excelTable.brojRacuna, 
      dataIndex: 'brojRacuna',
      width: 350,
      align: "center" as AlignType,
      key: 'brojRacuna',
    },
    {
      title: translations.excelTable.akcije,
      dataIndex: "",
      key: "",
      width: 50,
      render: (value: Trazbina): React.ReactElement => (
        <Dropdown
          overlay={<TableActionDropdown tableActions={trazbinaActionsContent(value)} />}
          overlayStyle={dropdownStyle}
        >
          <Button
            shape="circle"
            type="dashed"
            id="actionsButton"
            className="do-not-use-on-row-function"
          >
            <SmallDashOutlined />
          </Button>
        </Dropdown>
      ),
    },
    {
      title: ''
    }
  ];

  return (
    <Form>
      <Table
        {...genericTableProps}
        columns={columns}
        dataSource={excelTableData}
        rowKey={(item): string => item.id}
        expandable={shouldShowExpandableTable ? {
          expandedRowRender: record => renderVjerodostojnaIspravaTable(record.vjerodostojneIsprave || []),
        } : undefined}
      />
      <Drawer
        title={translations.general.addPdfDocuments}
        visible={!!pdfDrawerState}
        onClose={() => handlePdfDrawerChange(DrawerState.Closed, false)}
        width={DRAWER_MEDIUM_WIDTH}
        destroyOnClose
      >
        <UploadPdfForm
          onClose={() => handlePdfDrawerChange(DrawerState.Closed, false)}
          onSuccess={() => handlePdfDrawerChange(DrawerState.Closed, true)}
          prijedlogId={clickedPrijedlogId!}
        />
      </Drawer>
      <Drawer
        title={translations.general.addSignedPdf}
        visible={!!potpisaniPdfDrawerState}
        onClose={() =>
          handlePotpisaniPdfDrawerChange(DrawerState.Closed, false)
        }
        width={DRAWER_MEDIUM_WIDTH}
        destroyOnClose
      >
        <UploadSignedPdfForm
          onClose={() =>
            handlePotpisaniPdfDrawerChange(DrawerState.Closed, false)
          }
          onSuccess={() =>
            handlePotpisaniPdfDrawerChange(DrawerState.Closed, true)
          }
          prijedlogId={clickedPrijedlogId!}
        />
      </Drawer>
      {/* TODO: Refaktoriraj - 3 ponavljanja */}
      {clickedPrijedlog && (
        <Drawer
          title={translations.general.edit}
          visible={!!updateDrawerState}
          onClose={() => handleUpdateDrawerChange(DrawerState.Closed, false)}
          width={DRAWER_MAX_WIDTH}
          destroyOnClose
        >
          <UpdateExcelRowForm
            onClose={() => handleUpdateDrawerChange(DrawerState.Closed, false)}
            onSuccess={() => handleUpdateDrawerChange(DrawerState.Closed, true)}
            prijedlog={clickedPrijedlog}
          />
        </Drawer>
      )}
      {clickedVjerodostojnaIsprava && (
        <Drawer
          title={translations.general.edit}
          visible={!!updateDrawerState}
          onClose={() => handleUpdateDrawerChange(DrawerState.Closed, false)}
          width={DRAWER_MAX_WIDTH}
          destroyOnClose
        >
          <UpdateVjerodostojnaIsprava
            onClose={() => handleUpdateDrawerChange(DrawerState.Closed, false)}
            onSuccess={() => handleUpdateDrawerChange(DrawerState.Closed, true)}
            vjerodostojnaIsprava={clickedVjerodostojnaIsprava}
          />
        </Drawer>
      )}
      {clickedTrazbina && (
        <Drawer
          title={translations.general.edit}
          visible={!!updateDrawerState}
          onClose={() => handleUpdateDrawerChange(DrawerState.Closed, false)}
          width={DRAWER_MAX_WIDTH}
          destroyOnClose
        >
          <UpdateTrazbina
            onClose={() => handleUpdateDrawerChange(DrawerState.Closed, false)}
            onSuccess={() => handleUpdateDrawerChange(DrawerState.Closed, true)}
            trazbina={clickedTrazbina}
          />
        </Drawer>
      )}
      {opomenaPlacenaModal && (
        <Modal
          title={translations.general.opomenaPlacena}
          visible={opomenaPlacenaModal}
          closable={false}
          style={{ width: "200px" }}
          footer={[
            <Button
              key="odustani"
              onClick={(): void => handleOpomenaModal(false)}
            >
              {translations.general.cancel}
            </Button>,
            <Button
              key="ne"
              type="primary"
              onClick={(): Promise<void> => handleOpomenaOkModal(false)}
            >
              {translations.general.ne}
            </Button>,
            <Button
              key="da"
              type="primary"
              onClick={(): Promise<void> => handleOpomenaOkModal(true)}
            >
              {translations.general.da}
            </Button>,
          ]}
        >
          {translations.general.opomenaModalTekst}
        </Modal>
      )}
    </Form>
  );
};

export default ExcelTable;
