import { RouteComponentProps, withRouter } from 'react-router-dom';
import PunomocnikForm from './PunomocnikForm';
import { DropdownOption } from "../../../core/models/DropdownOption";
import { DrawerState } from '../../../core/models/enum';

interface OwnProps {
    onClose: (drawerState: DrawerState, shouldUpdate: boolean) => void;
    punomocnikId?: string;
    osobaTipovi: DropdownOption[];
}

export type Props = OwnProps & RouteComponentProps;

export default withRouter(PunomocnikForm);
