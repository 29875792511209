import { RouteComponentProps, withRouter } from 'react-router-dom';
import UploadExcelForm from './UploadExcelForm';

interface OwnProps {
    onClose: () => void;
    onSuccess: () => void;
}

export type Props = OwnProps & RouteComponentProps;

export default withRouter(UploadExcelForm);
