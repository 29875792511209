export enum DrawerState {
  Closed = 0,
  Read = 1,
  Edit = 2,
  Create = 3,
}

export enum TipOsobe {
  Ovrsenik = 0,
  Ovrhovoditelj = 1,
  Punomocnik = 2,
  ZakonskiZastupnik = 3,
}

export enum PrilogType {
  DokazPunomoci = 0,
  DokazZastupanja = 1,
  VjerodostojnaIsprava = 2,
  StranaBankaPotvrda = 3,
  KamataPoRanijemZakonu = 4,
  Ostalo = 5,
  PotpisaniPDF = 6,
  Opomena = 7,
  Trazbine = 8,
  DokazZastupanjaOvrsenik = 9,
}

export enum OsobaTip {
  FIZICKA_OSOBA = 0,
  PRAVNA_OSOBA = 1,
  OSTALO = 2,
}

export enum OsobaTipString {
  FIZICKA_OSOBA = "FIZICKA_OSOBA",
  PRAVNA_OSOBA = "PRAVNA_OSOBA",
  OSTALO = "OSTALO",
}

export enum VezaniSudionikTip {
  FIZICKA_OSOBA = 0,
  PRAVNA_OSOBA = 1,
}

export enum PdfSigningTypeEnum {
  Upload = "upload",
  App = "app",
}

export enum KamataType {
  ZakonskaZatezna = "ZAKONSKA_ZATEZNA_KAMATA",
  UgovornaZatezna = "UGOVORNA_ZATEZNA_KAMATA",
  NeTraziSe = "KAMATA_SE_NE_TRAZI",
}

export enum SredstvaOvrheType {
  OdredbeOvrsnogZakona = 1,
  PljenidbaRadiNaplate = 2,
  PljenidbaUmjestoIsplate = 3,
  ZapljenaRadiNaplate = 4,
  ZabiljezbaUZemljisnojKnjizi = 5,
  ZapljenaPokretnine = 6,
  ZapljenaPokretninePutemJavnogKomisionara = 7,
  ZabiljezbaNaMotornomVozilu = 8,
  ZabiljezbaNaMotornomVoziluPutemJavnogKomisionara = 9,
  PljenidbaDionica = 10,
  PljenidbaUdjela = 11,
  PljenidbaVrijednosnica = 12,
  ZapljenaTrazbine = 13,
  ZapljenaTrazbineZabiljezbaOvrhe = 14,
  PljenidbaOProdajiPokretnina = 15,
}

export enum VjerodostojnaIspravaType {
  Racun = 1,
  IzvadakIzPoslovnihKnjiga = 2,
  Mjenica = 3,
  CekSProtestom = 4,
  JavnaIsprava = 5,
  OvjerovljenaPrivatnaIsprava = 6,
  PosebnimPropisimaJavnaIsprava = 7,
  ObracunKamata = 8,
}

export enum ZatezneKamateType {
  KamatnaStopaClanak29TrgovackihUgovora = 1,
  KamatnaStopaClanak29OstaliOdnosi = 2,
  KamatnaStopaClanak12 = 3,
  KamatnaStopaPrije01012008KamatnaStopaClanak29TrgovackihUgovora = 5,
  KamatnaStopaPrije01012008KamatnaStopaClanak29OstaliOdnos = 6,
}
