/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, Modal } from "antd";
import { AxiosResponse } from "axios";
import { translations } from '../../../config/translations';
import { Punomocnik } from "../../../core/models/Punomocnik";
import { DropdownOption } from '../../../core/models/DropdownOption';
import { DrawerState, VezaniSudionikTip } from "../../../core/models/enum";
import {
    filterDropdownOptions,
    confirmDropdownHelper,
} from "../../../helpers/AutocompleteDropdownHelper";
import { formItemLayoutDrawer, customArrayValidationHelper, customStringValidationHelper } from "../../../helpers/TableHelper";
import { customOibValidationHelper } from "../../../helpers/OibHelper";
import { useApiContext } from '../../../helpers/ApiContext';
import { DrawerButtons } from "../../../components/drawer/buttons/DrawerButtons";
import { AutocompleteDropdown } from "../../../components/autocomplete-dropdown";
import { Props } from "./index";

const PunomocnikForm: React.FC<Props> = (props: Props) => {
    const { onClose, punomocnikId, osobaTipovi } = props;
    const { postAsync, deleteAsync, putAsync, getAsync, userId } = useApiContext();
    const [oib, setOib] = useState<string | undefined>();
    const [ime, setIme] = useState<string | undefined>();
    const [prezime, setPrezime] = useState<string | undefined>();
    const [naziv, setNaziv] = useState<string | undefined>();
    const [ulicaIKbr, setUlicaIKbr] = useState<string | undefined>();
    const [postanskiBroj, setPostanskiBroj] = useState<string | undefined>();
    const [naselje, setNaselje] = useState<string | undefined>();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedOsobaTip, setSelectedOsobaTip] = useState<DropdownOption[] | undefined>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        if (punomocnikId) {
            setIsEdit(true);
            getPunomocnik();
        }
    }, [punomocnikId]);

    const getPunomocnik = async () => {
        setLoading(true);

        const data = await getAsync<Punomocnik>('/Punomocnici/Read/' + punomocnikId, undefined, undefined, translations.punomocnik.errorGet);

        if (data) {
            setOib(data.oib);
            setIme(data.ime);
            setPrezime(data.prezime);
            setNaziv(data.naziv);
            setUlicaIKbr(data.ulicaIKbr);
            setPostanskiBroj(data.postanskiBroj);
            setNaselje(data.naselje);
            setSelectedOsobaTip(getOsobaTip(data.tip!))
        };

        setLoading(false);
    };

    const getOsobaTip = (tip: VezaniSudionikTip): DropdownOption[] => (osobaTipovi.filter((o: DropdownOption): boolean => Number(o.id!) === tip!));

    const handleSave = async () => {
        const punomocnik: Punomocnik = {
            id: punomocnikId,
            oib,
            tip: Number(selectedOsobaTip![0].id),
            ime,
            prezime,
            naziv,
            ulicaIKbr,
            postanskiBroj,
            naselje,
            applicationUserId: userId
        };

        if (!isEdit) {
            const data = await postAsync<AxiosResponse>('/Punomocnici/Create', punomocnik, undefined, translations.punomocnik.successCreate, translations.punomocnik.errorCreate);

            if (data) {
                onClose(DrawerState.Closed, true);
            };
        } else {
            const data = await putAsync<AxiosResponse>('/Punomocnici/Update', punomocnik, undefined, translations.punomocnik.successEdit, translations.punomocnik.errorEdit);

            if (data) {
                onClose(DrawerState.Closed, true);
            };
        };
    };

    const confirmOsobaTip = async (option: DropdownOption[]) => {
        const selectedOsobaTip = confirmDropdownHelper(option);
        setSelectedOsobaTip(selectedOsobaTip);

        if (selectedOsobaTip![0].id === VezaniSudionikTip.FIZICKA_OSOBA.toString()) {
            setNaziv(undefined);
        } else if (selectedOsobaTip![0].id === VezaniSudionikTip.PRAVNA_OSOBA.toString()) {
            setIme(undefined);
            setPrezime(undefined);
        }
    };

    const handleDeleteModal = (newState: boolean) => {
        setShowDeleteModal(newState);
    };

    const deletePunomocnik = async () => {
        const data = await deleteAsync<AxiosResponse>('/Punomocnici/Delete/' + punomocnikId, undefined, translations.punomocnik.successDelete, translations.punomocnik.errorDelete);

        if (data) {
            onClose(DrawerState.Closed, true);
        };
    };

    return (
        <>
            {((!loading && punomocnikId) || !punomocnikId) && <Form onFinish={handleSave} style={{ marginLeft: '20px', marginRight: '40px' }} {...formItemLayoutDrawer}>
                <DrawerButtons
                    onCancelAction={() => onClose(DrawerState.Closed, false)}
                    isEdit={isEdit}
                    onDeleteAction={() => handleDeleteModal(true)}
                />
                <Form.Item
                    name='oib'
                    label={translations.punomocnik.oib}
                    initialValue={oib}
                    rules={[
                        {
                            required: true,
                            validator: (
                                _rule: any,
                                value: any,
                                callback: any
                            ): void =>
                                customStringValidationHelper(
                                    _rule,
                                    callback,
                                    'Obavezno polje!',
                                    oib
                                ),
                        },
                        {
                            required: true,
                            validator: (
                                _rule: any,
                                value: any,
                                callback: any
                            ): void =>
                                customOibValidationHelper(
                                    _rule,
                                    callback,
                                    'Neispravan OIB',
                                    oib
                                ),
                        },
                    ]}>
                    <Input
                        placeholder={translations.punomocnik.unesiteOib}
                        value={oib}
                        onChange={
                            (input): void => {
                                setOib(input.target.value)
                            }
                        }
                    >
                    </Input>
                </Form.Item>
                <Form.Item
                    name="osobaTipovi"
                    label={translations.punomocnik.tip}
                    rules={[
                        {
                            required: true,
                            validator: (
                                _rule: any,
                                value: any,
                                callback: any
                            ): void =>
                                customArrayValidationHelper(
                                    _rule,
                                    callback,
                                    'Obavezno polje!',
                                    selectedOsobaTip
                                ),
                        },
                    ]}>
                    <AutocompleteDropdown
                        placeholder={translations.punomocnik.unesiteTip}
                        getOptionsFrontend={(value: string): DropdownOption[] =>
                            filterDropdownOptions(value, osobaTipovi)
                        }
                        initialValues={selectedOsobaTip}
                        confirmDirty={confirmOsobaTip}
                    />
                </Form.Item>
                {selectedOsobaTip && selectedOsobaTip[0].id === VezaniSudionikTip.FIZICKA_OSOBA.toString() as any &&
                    <Form.Item
                        name='ime'
                        label={translations.punomocnik.ime}
                        initialValue={ime}
                        rules={[
                            {
                                required: selectedOsobaTip && selectedOsobaTip[0].id === VezaniSudionikTip.FIZICKA_OSOBA.toString() as any,
                                validator: (
                                    _rule: any,
                                    value: any,
                                    callback: any
                                ): void =>
                                    customStringValidationHelper(
                                        _rule,
                                        callback,
                                        'Obavezno polje!',
                                        ime
                                    ),
                            },
                        ]}>
                        <Input
                            placeholder={translations.punomocnik.unesiteIme}
                            value={ime}
                            onChange={
                                (input): void => setIme(input.target.value)
                            }
                        >
                        </Input>
                    </Form.Item>}
                {selectedOsobaTip && selectedOsobaTip[0].id === VezaniSudionikTip.FIZICKA_OSOBA.toString() as any &&
                    <Form.Item
                        name='prezime'
                        label={translations.punomocnik.prezime}
                        initialValue={prezime}
                        rules={[
                            {
                                required: selectedOsobaTip && selectedOsobaTip[0].id === VezaniSudionikTip.FIZICKA_OSOBA.toString() as any,
                                validator: (
                                    _rule: any,
                                    value: any,
                                    callback: any
                                ): void =>
                                    customStringValidationHelper(
                                        _rule,
                                        callback,
                                        'Obavezno polje!',
                                        prezime
                                    ),
                            },
                        ]}>
                        <Input
                            placeholder={translations.punomocnik.unesitePrezime}
                            value={prezime}
                            onChange={
                                (input): void => setPrezime(input.target.value)
                            }
                        >
                        </Input>
                    </Form.Item>}
                {selectedOsobaTip && selectedOsobaTip[0].id === VezaniSudionikTip.PRAVNA_OSOBA.toString() as any &&
                    <Form.Item
                        name='naziv'
                        label={translations.punomocnik.naziv}
                        initialValue={naziv}
                        rules={[
                            {
                                required: selectedOsobaTip && selectedOsobaTip[0].id === VezaniSudionikTip.PRAVNA_OSOBA.toString() as any,
                                validator: (
                                    _rule: any,
                                    value: any,
                                    callback: any
                                ): void =>
                                    customStringValidationHelper(
                                        _rule,
                                        callback,
                                        'Obavezno polje!',
                                        naziv
                                    ),
                            },
                        ]}>
                        <Input
                            placeholder={translations.punomocnik.unesiteNaziv}
                            value={naziv}
                            onChange={
                                (input): void => setNaziv(input.target.value)
                            }
                        >
                        </Input>
                    </Form.Item>}
                <Form.Item
                    name='ulicaIKbr'
                    label={translations.punomocnik.ulicaIKbr}
                    initialValue={ulicaIKbr}
                    rules={[
                        {
                            required: true,
                            validator: (
                                _rule: any,
                                value: any,
                                callback: any
                            ): void =>
                                customStringValidationHelper(
                                    _rule,
                                    callback,
                                    'Obavezno polje!',
                                    ulicaIKbr
                                ),
                        },
                    ]}>
                    <Input
                        placeholder={translations.punomocnik.unesiteUlicaIKbr}
                        value={ulicaIKbr}
                        onChange={
                            (input): void => setUlicaIKbr(input.target.value)
                        }
                    >
                    </Input>
                </Form.Item>
                <Form.Item
                    name="postanskiBroj"
                    label={translations.punomocnik.postanskiBroj}
                    initialValue={postanskiBroj}
                    rules={[
                        {
                            required: true,
                            validator: (
                                _rule: any,
                                value: any,
                                callback: any
                            ): void =>
                                customStringValidationHelper(
                                    _rule,
                                    callback,
                                    'Obavezno polje!',
                                    postanskiBroj
                                ),
                        },
                    ]}>
                    <Input
                        placeholder={translations.punomocnik.unesitePostanskiBroj}
                        value={postanskiBroj}
                        onChange={
                            (input): void => setPostanskiBroj(input.target.value)
                        }
                    >
                    </Input>
                </Form.Item>
                <Form.Item
                    name='naselje'
                    label={translations.punomocnik.naselje}
                    initialValue={naselje}
                    rules={[
                        {
                            required: true,
                            validator: (
                                _rule: any,
                                value: any,
                                callback: any
                            ): void =>
                                customStringValidationHelper(
                                    _rule,
                                    callback,
                                    'Obavezno polje!',
                                    naselje
                                ),
                        },
                    ]}>
                    <Input
                        placeholder={translations.punomocnik.unesiteNaselje}
                        value={naselje}
                        onChange={
                            (input): void => setNaselje(input.target.value)
                        }
                    >
                    </Input>
                </Form.Item>
                {isEdit && <Modal
                    title={translations.punomocnik.deleteModal}
                    visible={showDeleteModal}
                    onOk={deletePunomocnik}
                    onCancel={(): void => handleDeleteModal(false)}>
                    {translations.punomocnik.deleteModalText}
                </Modal>}
            </Form>}
        </>
    );
}

export default PunomocnikForm;