import { RouteComponentProps, withRouter } from 'react-router-dom';
import { VjerodostojnaIsprava } from '../../../../utils/models/ExcelTableData';
import UpdateVjerodostojnaIspravaForm from './UpdateVjerodostojnaIspravaForm';

interface OwnProps {
    onClose: () => void;
    onSuccess: () => void;
    vjerodostojnaIsprava: VjerodostojnaIsprava;
}

export type Props = OwnProps & RouteComponentProps;

export default withRouter(UpdateVjerodostojnaIspravaForm);
