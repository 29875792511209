

const routes = {
    ROUTE_DASHBOARD: '/',

    ROUTE_EXCEL_FILES: '/excelFiles',

    ROUTE_EXCEL_FILE_DETAILS: '/excelFiles/:excelFileId',

    ROUTE_OVRHOVODITELJI: '/ovrhovoditelji',

    ROUTE_PUNOMOCNICI: '/punomocnici',

    ROUTE_ZAKONSKI_ZASTUPNICI: '/zakonskiZastupnici',

    ROUTE_TOKEN: '/token'
};

export const sidebarKey = {
    Dashboard: "dashboard",
    ExcelFiles: "excel-files",
    Ovrhovoditelji: "ovrhovoditelji",
    Punomocnici: "punomocnici",
    ZakonskiZastupnici: "zakonskiZastupnici",
    Token: "token",
    Settings: "settings",
}

export default routes;