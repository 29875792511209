import {
  KamataType,
  OsobaTip,
  PdfSigningTypeEnum,
} from "../../core/models/enum";
import { IExcelTableData } from "../interfaces/IExcelTableData";

export class ExcelTableData implements IExcelTableData {
  id!: string;
  redniBroj!: number | null;
  interniId!: string | null;
  opomenaPoslana!: boolean | null;
  datumZaOpomenaPoslana!: Date | null;
  opomenaPlacena!: boolean | null;
  prijedlogNapravljen!: boolean | null;
  datumZaPrijedlogNapravljen!: Date | null;
  prijedlogPotpisan!: boolean | null;
  datumZaPrijedlogPotpisan!: Date | null;
  jedinstveniIdentifikator!: string | null;
  poslanoNaSud!: boolean | null;
  datumZaPoslanoNaSud!: Date | null;
  tip!: OsobaTip | null;
  oib!: string | null;
  ime!: string | null;
  prezime!: string | null;
  naziv!: string | null;
  adresa!: string | null;
  postanskiBroj!: string | null;
  mjesto!: string | null;
  nazivObrtaOpgaSamostalneDjelatnosti!: string | null;
  racunSpecificneNamjene!: string | null;
  sredstvaOvrheIdevi!: string | null;
  dvaOvrsenika!: string;
  tip2!: OsobaTip | null;
  oib2!: string | null;
  ime2!: string | null;
  prezime2!: string | null;
  naziv2!: string | null;
  adresa2!: string | null;
  postanskiBroj2!: string | null;
  mjesto2!: string | null;
  nazivObrtaOpgaSamostalneDjelatnosti2!: string | null;
  racunSpecificneNamjene2!: string | null;
  sredstvaOvrheIdevi2!: string | null;
  vrstaOdgovornostiOvrsenika!: string | null;
  vrstaVjerodostojneIsprave!: number | null;
  vrstaVjerodostojneIspraveId!: number | null;
  oznakaVjerodostojneIsprave!: string | null;
  datumVjerodostojneIsprave!: Date | null;
  opisTrazbine!: string | null;
  glavnica!: number | null;
  obracunKamatneStope!: KamataType | null;
  vrstaZakonskeZatezneKamate!: number | null;
  vrstaZakonskeZatezneKamateId!: number | null;
  ugovornaKamatnaStopa!: string | null;
  kamataPoRanijemZakonuOpis!: string | null;
  datumPocetkaTijekaKamate!: Date | null;
  kamata!: number | null;
  datumPrethodnoObracunateKamate!: Date | null;
  prethodnoObracunataKamata!: number | null;
  brojRacuna!: string | null;
  model!: string | null;
  pozivNaBroj!: string | null;
  dvijeTrazbine!: string | null;
  glavnica2!: number | null;
  obracunKamatneStope2!: KamataType | null;
  vrstaZakonskeZatezneKamate2!: number | null;
  vrstaZakonskeZatezneKamateId2!: number | null;
  ugovornaKamatnaStopa2!: string | null;
  kamataPoRanijemZakonuOpis2!: string | null;
  datumPocetkaTijekaKamate2!: Date | null;
  kamata2!: number | null;
  datumPrethodnoObracunateKamate2!: Date | null;
  prethodnoObracunataKamata2!: number | null;
  brojRacuna2!: string | null;
  model2!: string | null;
  pozivNaBroj2!: string | null;
  vjerodostojnaIspravaPrilozena!: boolean | null;
  pdfSigningType!: PdfSigningTypeEnum | null;
  troskoviPostupkaKamataPovrat!: string | null;
  ostaliPrivitci!: string | null;
  ostaliPrivitciOpisi!: string | null;
  oznakaPredmeta!: string | null;
  nazivSuda!: string | null;
  nazivUreda!: string | null;
  interniBrojPredmeta!: string | null;
  oznakaSredstvaDuga!: string | null;
  vjerodostojneIsprave!: VjerodostojnaIsprava[] | null;
  prijedlogZakonskiZastupnici!: prijedlogZakonskiZastupnici[] | null;
}

export class VjerodostojnaIsprava {
  id!: number;
  prijedlogInterniId!: string;
  prijedlogId!: number;
  isDeleted?: boolean;
  vrstaId!: number;
  oznaka!: string;
  datum!: string;
  opisTrazbina!: string | null;
  trazbine!: Trazbina[];
}

export class Trazbina {
  id!: number;
  vjerodostojnaIspravaId!: number;
  interniIdPrijedloga!: string;
  sifraDuznika!: string | null;
  glavnica!: number;
  obracunKamatneStope!: string;
  vrstaZakonskeZatezneKamateId!: number | null;
  ugovornaKamatnaStopa!: number | null;
  kamataPoRanijemZakonuOpis!: string | null;
  datumPocetkaTijekaKamate!: string | null;
  kamata!: number | null;
  datumPrethodnoObracunateKamate!: string | null;
  prethodnoObracunataKamata!: number | null;
  brojRacuna!: string;
  model!: string;
  pozivNaBroj!: string;
}

export class prijedlogZakonskiZastupnici {
  id!: number;
  zakonskiZastupnikId!: number;
  pravnaOsnovaZaZastupanjeId!: number;
  prijedlogId!: number;
}

export enum TableDataType {
  ExcelTableData = 0,
  VjerodostojnaIsprava = 1,
  Trazbina = 2,
}