export const translations = {
  general: {
    dashboard: "Početna",
    settings: "Postavke",
    excel: "Excel",
    excelFiles: "Popis tablica",
    token: "Token",
    details: "Detalji",
    selectDate: "Odaberite datum",
    wrongFileType: "Pogrešan tip datoteke",
    upload: "Odaberite datoteku",
    file: "Excel dokument (.xlsx)",
    requiredField: "Obavezno polje",
    excelTitle: "Excel dokumenti",
    importBtnText: "Uvezi",
    saveBtnText: "Spremi",
    cancel: "Odustani",
    save: "Spremi",
    edit: "Uredi",
    akcije: "Akcije",
    opomene: "Opomene",
    opomena: "Opomena",
    prijedlog: "Prijedlog",
    privitci: "Privitci",
    wrongOib: "Neispravan OIB",
    addExcel: "Dodaj excel",
    delete: "Obriši",
    confirm: "Potvrdi",
    confirmMessage: "Jeste li sigurni?",
    importSuccess: "Excel dokument uspješno uvezen",
    importPdfSuccess: "Pdf dokument uspješno uvezen",
    importBulkPdfSuccess: "Pdf dokumenti uspješno uveženi",
    emptyZip: "Zip ne sadrži nijedan pdf",
    errorSavingData: "Greška prilikom spremanja",
    noData: "Nema podataka",
    accountTitle: "Korisnički račun",
    login: "Prijava",
    register: "Registracija",
    logout: "Odjava",
    ovrhovoditelj: "Ovrhovoditelj",
    ovrhovoditelji: "Ovrhovoditelji",
    punomocnik: "Punomoćnik",
    punomocnici: "Punomoćnici",
    saveExcel: "Spremi dokument",
    sendExcel: "Pošalji dokument",
    zakonskiZastupnik: "Zakonski zastupnik",
    zakonskiZastupnici: "Zakonski zastupnici",
    odabirExcelDokumenta: "Odaberite excel dokument",
    addPdfDocuments: "Dodaj privitke prijedlogu",
    addPrivitakExcel: "Dodaj privitke",
    addMutualPrivitakExcel: "Dodaj zajedničke privitke",
    addBulkPrivitakVjerodostojne: "Grupno dodaj vjerodostojne isprave",
    addBulkPrivitakOstalo: "Grupno dodaj ostale privitke",
    addBulkPrijedlogSigned: "Grupno dodaj potpisane prijedloge",
    addExcelPrivitci: "Dodaj privitke na sve prijedloge",
    addSignedPdf: "Dodaj potpisani PDF",
    pdf1: "PDF1",
    saveModalText: "Jeste li sigurni da želite poslati excel dokument?",
    vjerodostojnaIsprava: "Vjerodostojna isprava",
    vjerodostojneIsprave: "Vjerodostojne isprave",
    ostalo: "Ostalo",
    ostaloPrivitci: "Ostali privitci",
    signedPrijedlozi: "Potpisani prijedlozi",
    dokazPunomoci: "Dokaz punomoći",
    dokazZastupanja: "Dokaz zastupanja",
    dokazZastupanjaOvrsenik: "Dokaz zastupanja ovršenika",
    potpisaniPdf: "Potpisani PDF",
    spremiDatoteku: "Spremite datoteku",
    errorWrongXlsx:
      "Greška! Provjerite je li excel dokument odgovarajućeg formata.",
    errorUpload: "Pogreška prilikom učitavanja dokumenta.",
    opisDokumenta: "Opis dokumenta *",
    opis: "Opis",
    essentialData: "Osnovni podaci",
    pravnaOsnovaZaZastupanje: "Pravna osnova za zastupanje",
    pravneOsnoveZaZastupanje: "Pravne osnove za zastupanje",
    generirajPrijedloge: "Generiraj prijedloge",
    da: "Da",
    ne: "Ne",
    generirajPrijedlogTooltipDisable:
      "Svi prijedlozi moraju imati priloženu vjerodostojnu ispravu",
    generirajPrijedlogTooltip: "Generiraj prijedlog",
    potpisiPosaljiTooltipDisable: "Nema generiranog prijedloga",
    potpisiPosaljiTooltip: "Potpiši i pošalji",
    potpisiPosalji: "Potpiši i pošalji",
    potpisiPosaljiTekst: "Potpiši i pošalji tekst",
    errorGenerate: "Neuspješno generiranje prijedloga",
    errorSignSend: "Neuspješno potpisivanje prijedloga i slanje na sud",
    errorSign: "Neuspješno digitalno potpisivanje prijedloga",
    errorSend: "Neuspješno slanje prijedloga na sud",
    errorDownloadChunkPrijedlozi: "Neuspješno preuzimanje prijedloga",
    errorDownloadChunkFinal: "Neuspješno preuzimanje poslanih",
    errorDownloadOpomene: "Neuspješno preuzimanje opomena",
    potpisi: "Potpiši",
    posalji: "Pošalji na sud",
    generirajOpomene: "Generiraj opomene",
    potpisiTooltipDisable: "Prijedlozi moraju biti generirani",
    potpisiTooltip: "Potpiši",
    posaljiTooltipDisable: "Prijedlozi moraju biti potpisani",
    posaljiTooltip: "Pošalji",
    opomenaTooltipDisable: "Svi prijedlozi su generirani",
    opomenaTooltip: "Pošalji opomene",
    potpisiTekstSviGenerirani:
      "Jeste li sigurni da želite digitalno potpisati generirane prijedloge?",
    potpisiTekstNegenerirani:
      "Negenerirano je x od y prijedloga. Jeste li sigurni da želite digitalno potpisati generirane prijedloge?",
    opomenaTekstSviGenerirani: "Jeste li sigurni da želite generirati opomene?",
    opomenaTekstNegenerirani:
      "Negenerirano je x od y prijedloga. Jeste li sigurni da želite generirati opomene za negenerirane prijedloge?",
    posaljiTekst: "Jeste li sigurni da želite poslati prijedloge na sud?",
    generateSuccess: "Uspješno generirani prijedlozi",
    sendSuccess: "Uspješno poslano na sud",
    signSuccess: "Uspješno digitalno potpisano",
    downloadChunkPrijedlozi: "Preuzmi sve prijedloge",
    downloadChunkPrijedloziDisable: "Potrebno je generirati prijedloge",
    downloadChunkPrijedloziBtn: "Preuzmi prijedloge",
    downloadChunkPrijedloziTekstSviGenerirani:
      "Jeste li sigurni da želite preuzeti sve prijedloge?",
    downloadChunkPrijedloziTekstNegenerirani:
      "Negenerirano je x od y prijedloga. Jeste li sigurni da želite preuzeti generirane prijedloge?",
    downloadChunkPrijedloziSuccess: "Uspješno preuzimanje prijedloga",
    downloadChunkFinal: "Preuzmi sve poslane prijedloge",
    downloadChunkFinalDisable: "Potrebno je poslati prijedloge na sud",
    downloadChunkFinalBtn: "Preuzmi završne",
    downloadChunkFinalTekst:
      "Jeste li sigurni da želite preuzeti sve poslane prijedloge?",
    downloadChunkFinalSuccess: "Uspješno preuzimanje poslanih",
    downloadOpomene: "Preuzmi sve opomene",
    downloadOpomeneDisable: "Potrebno je generirati opomene",
    downloadOpomeneBtn: "Preuzmi opomene",
    downloadOpomeneTekst:
      "Jeste li sigurni da želite preuzeti sve generirane opomene?",
    downloadOpomeneSuccess: "Uspješno preuzimanje opomena",
    opomenaModalTekst: "Je li opomena plaćena?",
    opomenaPlacena: "Opomena plaćena",
    generirajPrijedlogeTekst:
      "Jeste li sigurni da želite generirati prijedloge koji imaju priloženu vjerodostojnu ispravu?",
    obrisiDatoteku: "Obriši datoteku",
    prijedloziDownloadFilename: "Prijedlozi",
    finalDownloadFilename: "Završne",
    opomeneDownloadFilename: "Opomene",
    errorDeletingPdf: "Neuspješno brisanje dokumenta",
    successDeletingPdf: "Uspješno brisanje dokumenta",
    errorEditingPdf: "Neuspješno uređivanje dokumenta",
    successEditingPdf: "Uspješno uređivanje dokumenta",
    deleteExcelFile: "Obrišite excel dokument",
    deleteExcelFileTekst: "Jeste li sigurni da želite obrisati excel dokument?",
    deleteDocument: "Obriši",
    deleteSuccess: "Uspješno brisanje excel dokumenta",
    deleteError: "Neuspješno brisanje excel dokumenta",
    genericPostError: "Neuspješno",
    genericPostSuccess: "Uspješno",
    genericPutError: "Neuspješno",
    genericPutSuccess: "Uspješno",
    genericGetError: "Neuspješno",
    genericGetSuccess: "Uspješno",
    genericDeleteError: "Neuspješno",
    genericDeleteSuccess: "Uspješno",
    opomenaSendSuccess: "Uspješno generiranje opomena",
    opomenaSendError: "Neuspješno generiranje opomena",
    validating: "Validacija podataka, molimo pričekajte.",
    loading: "Učitavanje podataka, molimo pričekajte.",
    saving: "Spremanje podataka, molimo pričekajte i ne osvježavajte stranicu.",
    deleting:
      "Brisanje podataka, molimo pričekajte i ne osvježavajte stranicu.",
    prijedloziSaving:
      "Generiranje prijedloga, molimo pričekajte i ne osvježavajte stranicu.",
    opomeneSaving:
      "Generiranje opomena, molimo pričekajte i ne osvježavajte stranicu.",
    signingSaving:
      "Potpisivanje dokumenata, molimo pričekajte i ne osvježavajte stranicu.",
    sendToCountSaving:
      "Slanje prijedloga na sud, molimo pričekajte i ne osvježavajte stranicu.",
    bulkUploadSaving:
      "Grupno učitavanje i spremanje datoteka, molimo pričekajte i ne osvježavajte stranicu.",
    search: "Pretraži",
    clear: "Očisti",
    opisRequired: "Opis dokumenta je obavezan",
    editDescriptionBtn: "Spremi opis",
    dodajPrilezeceTrazbine: "Dodaj priležeće tražbine",
    dodajTrazbine: "Dodaj tražbine",
    total: "Ukupno",
  },
  excelTable: {
    redniBroj: "Redni broj",
    interniId: "Interni ID",
    ostaliPrivitci: "Ostali privitci",
    ostaliPrivitciOpisi: "Opisi ostalih privitaka",
    promijeniStatusOpomene: "Promijeni status opomene",
    imeIPrezime: "Ime i prezime",
    adresa: "Adresa",
    mjesto: "Mjesto",
    poslovniBroj: "Poslovni broj",
    brojKartice: "Broj kartice",
    datumDugovanja: "Datum dugovanja",
    ukupniIznos: "Ukupni iznos",
    ukupniIznos2: "Ukupni iznos 2",
    pozivNaBroj: "Poziv na broj",
    oIB: "OIB",
    ovršenik1: "Ovršenik 1",
    ovršenik2: "Ovršenik 2",
    datumIzvatka: "Datum izvatka",
    javniBiljeznik: "Javni bilježnik",
    adresaJB: "Adresa JB",
    mjestoJB: "Mjesto JB",
    datumUtuzenja: "Datum utuženja",
    glavnica1: "Glavnica 1",
    glavnica1k: "Glavnica 1 k",
    datumZaduzenjaGlavnica1: "Datum zaduženja glavnica1",
    rowIndex: "Broj retka",
    columnIndex: "Broj stupca",
    columnName: "Naziv stupca",
    expectedType: "Očekivani tip",
    cellValue: "Sadržaj",
    errorMessage: "Tekst greške",
    akcije: "Akcije",
    detalji: "Detalji",
    dodajPrivitak: "Dodaj privitak",
    dodajPotpisaniPdf: "Dodaj potpisani PDF",
    id: "Id",
    prijedlogNapravljen: "Prijedlog napravljen",
    datumZaPrijedlogNapravljen: "Datum za prijedlog napravljen",
    prijedlogPotpisan: "Prijedlog potpisan",
    datumZaPrijedlogPotpisan: "Datum za prijedlog potpisan",
    vrstaPotpisa: "Vrsta potpisa",
    poslanoNaSud: "Poslano na sud",
    datumZaPoslanoNaSud: "Datum za poslano na sud",
    tip: "Tip",
    ime: "Ime",
    prezime: "Prezime",
    naziv: "Naziv",
    postanskiBroj: "Poštanski broj",
    nazivObrtaOpgaSamostalneDjelatnosti:
      "Naziv obrta, OPG-a, samostalne djelatnosti",
    racunSpecificneNamjene: "Račun specifične namjene",
    sredstvaOvrheIdevi: "Sredstva ovrhe ID-evi",
    dvaOvrsenika: "Dva ovršenika",
    tip2: "Tip 2",
    oIB2: "OIB 2",
    ime2: "Ime 2",
    prezime2: "Prezime 2",
    naziv2: "Naziv 2",
    adresa2: "Adresa 2",
    postanskiBroj2: "Poštanski broj 2",
    mjesto2: "Mjesto 2",
    nazivObrtaOpgaSamostalneDjelatnosti2:
      "Naziv obrta, OPG-a, samostalne djelatnosti 2",
    racunSpecificneNamjene2: "Račun specifične namjene 2",
    sredstvaOvrheIdevi2: "Sredstva ovrhe ID-evi 2",
    vrstaOdgovornostiOvrsenika: "Vrsta odgovornosti ovršenika",
    vrstaVjerodostojneIsprave: "Vrsta vjerodostojne isprave",
    oznakaVjerodostojneIsprave: "Oznaka vjerodostojne isprave",
    datumVjerodostojneIsprave: "Datum vjerodostojne isprave",
    opisTrazbine: "Opis tražbine",
    trazbina1: "Tražbina 1",
    trazbina2: "Tražbina 2",
    glavnica: "Glavnica",
    obracunKamatneStope: "Obračun kamatne stope",
    vrstaZakonskeZatezneKamate: "Vrsta zakonske zatezne kamate",
    ugovornaKamatnaStopa: "Ugovorna kamatna stopa",
    kamataPoRanijemZakonuOpis: "Kamata po ranijem zakonu opis",
    datumPocetkaTijekaKamate: "Datum početka tijeka kamate",
    kamata: "Kamata",
    datumPrethodnoObracunateKamate: "Datum prethodno obračunate kamate",
    prethodnoObracunataKamata: "Prethodno obračunata kamata",
    brojRacuna: "Broj računa ",
    model: "Model",
    glavnica2: "Glavnica 2",
    obracunKamatneStope2: "Obračun kamatne stope 2",
    vrstaZakonskeZatezneKamate2: "Vrsta zakonske zatezne kamate 2",
    ugovornaKamatnaStopa2: "Ugovorna kamatna stopa 2",
    kamataPoRanijemZakonuOpis2: "Kamata po ranijem zakonu opis 2",
    datumPocetkaTijekaKamate2: "Datum početka tijeka kamate 2",
    kamata2: "Kamata 2",
    datumPrethodnoObracunateKamate2: "Datum prethodno obračunate kamate 2",
    prethodnoObracunataKamata2: "Prethodno obračunata kamata 2",
    brojRacuna2: "Broj računa 2",
    model2: "Model 2",
    dvijeTrazbine: "Dvije tražbine",
    pozivNaBroj2: "Poziv na broj 2",
    vjerodostojnaIspravaPrilozena: "Vjerodostojna isprava priložena",
    generiraniPDF: "Generirani prijedlog",
    potpisaniPDF: "Potpisani prijedlog",
    poslaniPDF: "Poslani prijedlog",
    PDF: "PDF",
    jedinstveniIdentifikator: "Jedinstveni identifikator",
    opomenaPoslana: "Opomena generirana",
    datumZaOpomenaPoslana: "Datum kreiranja opomene",
    opomenaPlacena: "Opomena plaćena",
    opomena: "Opomena",
    troskoviPostupkaKamataPovrat: "Troškovi postupka, kamata, povrat",
    oznakaPredmeta: "Oznaka predmeta",
    nazivSuda: "Sud",
    nazivUreda: "Javni bilježnik",
    interniBrojPredmeta: "Interni broj predmeta",
    oznakaSredstvaDuga: "Oznaka sredstva duga",
    sifraDuznika: "Šifra dužnika",
  },
  punomocnik: {
    title: "Punomoćnici",
    addPunomocnik: "Dodaj punomoćnika",
    oib: "OIB",
    tip: "Tip",
    ime: "Ime",
    prezime: "Prezime",
    naziv: "Naziv",
    ulicaIKbr: "Adresa",
    postanskiBroj: "Poštanski broj",
    naselje: "Naselje",
    unesiteIme: "Unesite ime",
    unesiteOib: "Unesite OIB",
    unesiteTip: "Odaberite tip osobe",
    unesitePrezime: "Unesite prezime",
    unesiteNaziv: "Unesite naziv",
    unesiteUlicaIKbr: "Unesite ulicu i kućni broj",
    unesitePostanskiBroj: "Unesite poštanski broj",
    unesiteNaselje: "Unesite naselje",
    errorCreate: "Neuspješno kreiranje punomoćnika",
    successCreate: "Uspješno kreiranje punomoćnika",
    errorEdit: "Neuspješno uređivanje punomoćnika",
    successEdit: "Uspješno uređivanje punomoćnika",
    errorGet: "Neuspješno dohvaćanje punomoćnika",
    errorDelete: "Neuspješno brisanje punomoćnika",
    successDelete: "Uspješno brisanje punomoćnika",
    deleteModalText: "Jeste li sigurni da želite obrisati punomoćnika?",
    deleteModal: "Obriši punomoćnika",
  },
  ovrhovoditelj: {
    title: "Ovrhovoditelji",
    addOvrhovoditelj: "Dodaj ovrhovoditelja",
    oib: "OIB",
    tip: "Tip",
    ime: "Ime",
    prezime: "Prezime",
    naziv: "Naziv",
    ulicaIKbr: "Adresa",
    postanskiBroj: "Poštanski broj",
    naselje: "Naselje",
    nazivObrtaOpgaSamostalneDjelatnosti:
      "Naziv obrta, OPG-a, samostalne djelatnosti",
    racunSpecificneNamjene: "Račun specifične namjene",
    kontaktPodaci: "Kontakt podaci",
    unesiteIme: "Unesite ime",
    unesiteTip: "Odaberite tip osobe",
    unesiteOib: "Unesite OIB",
    unesitePrezime: "Unesite prezime",
    unesiteNaziv: "Unesite naziv",
    unesiteUlicaIKbr: "Unesite ulicu i kućni broj",
    unesitePostanskiBroj: "Unesite poštanski broj",
    unesiteNaselje: "Unesite naselje",
    unesiteNazivObrtaOpgaSamostalneDjelatnosti:
      "Unesite naziv obrta, OPG-a, samostalne djelatnosti",
    unesiteRacunSpecificneNamjene: "Unesite račun specifične namjene",
    unesiteKontaktPodaci: "Unesite kontakt podatke",
    errorCreate: "Neuspješno kreiranje ovrhovoditelja",
    successCreate: "Uspješno kreiranje ovrhovoditelja",
    errorEdit: "Neuspješno uređivanje ovrhovoditelja",
    successEdit: "Uspješno uređivanje ovrhovoditelja",
    errorGet: "Neuspješno dohvaćanje ovrhovoditelja",
    errorDelete: "Neuspješno brisanje ovrhovoditelja",
    successDelete: "Uspješno brisanje ovrhovoditelja",
    deleteModalText: "Jeste li sigurni da želite obrisati ovrhovoditelja?",
    deleteModal: "Obriši ovrhovoditelja",
  },
  zakonskiZastupnik: {
    title: "Zakonski zastupnici",
    addZakonskiZastupnik: "Dodaj zakonskog zastupnika",
    oib: "OIB",
    tip: "Tip",
    ime: "Ime",
    prezime: "Prezime",
    naziv: "Naziv",
    ulicaIKbr: "Adresa",
    postanskiBroj: "Poštanski broj",
    naselje: "Naselje",
    unesiteIme: "Unesite ime",
    unesiteOib: "Unesite OIB",
    unesiteTip: "Odaberite tip osobe",
    unesitePrezime: "Unesite prezime",
    unesiteNaziv: "Unesite naziv",
    unesiteUlicaIKbr: "Unesite ulicu i kućni broj",
    unesitePostanskiBroj: "Unesite poštanski broj",
    unesiteNaselje: "Unesite naselje",
    errorCreate: "Neuspješno kreiranje zakonskog zastupnika",
    successCreate: "Uspješno kreiranje zakonskog zastupnika",
    errorEdit: "Neuspješno uređivanje zakonskog zastupnika",
    successEdit: "Uspješno uređivanje zakonskog zastupnika",
    errorGet: "Neuspješno dohvaćanje zakonskog zastupnika",
    errorDelete: "Neuspješno brisanje zakonskog zastupnika",
    successDelete: "Uspješno brisanje zakonskog zastupnika",
    deleteModalText:
      "Jeste li sigurni da želite obrisati zakonskog zastupnika?",
    deleteModal: "Obriši zakonskog zastupnika",
  },
  token: {
    title: "Token",
    oldToken: "Stari token",
    oldTokenPlaceholder: "Nema pohranjenog starog tokena",
    newToken: "Novi token",
    newTokenPlaceholder: "Unesite novi token",
    saveBtn: "Spremi novi token",
    errorSavingNewToken: "Pogreška kod spremanja novog tokena",
    successSavingNewToken: "Uspješno spremanje novog tokena",
  },
  excelFilesTable: {
    naziv: "Naziv dokumenta",
    datum: "Datum",
    ovrhovoditelj: "Ovrhovoditelj",
    punomocnici: "Punomoćnici",
    zakonskiZastupnik: "Zakonski zastupnik",
    akcije: "Akcije",
    details: "Detalji",
    delete: "Obriši dokument",
  },
};
