/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DatePicker, Form, Input } from "antd";
import { Props } from "./index";
import { translations } from "../../../../config/translations";
import { customArrayValidationHelper, formItemLayoutDrawer } from "../../../../helpers/TableHelper";
import { AutocompleteDropdown } from "../../../../components/autocomplete-dropdown";
import { filterDropdownOptions } from "../../../../helpers/AutocompleteDropdownHelper";
import { DropdownOption } from "../../../../core/models/DropdownOption";
import moment from "moment";
import { getVjerodostojnaIspravaModel } from "../../../../helpers/EnumHelper";
import { DrawerButtons } from "../../../../components/drawer/buttons/DrawerButtons";
import { useApiContext } from "../../../../helpers/ApiContext";

const UpdateVjerodostojnaIspravaForm: React.FC<Props> = (props: Props) => {
    const { vjerodostojnaIsprava, onClose, onSuccess } = props;
    const { postAsync } = useApiContext();

    const [vrsteVjerodostojneIsprave, setVrsteVjerodostojneIsprave] = useState<DropdownOption[]>([]);
    const [selectedVrstaVjerodostojneIsprave, setSelectedVrstaVjerodostojneIsprave] = useState<DropdownOption[] | undefined>();

    useEffect(() => {
        setVrsteVjerodostojneIsprave(getVjerodostojnaIspravaModel());
    }, []);

    useEffect(() => {
        setInitialVjerodostojneValues();
    }, [vrsteVjerodostojneIsprave.length]);


    const setInitialVjerodostojneValues = async () => {
          if (vjerodostojnaIsprava.vrstaId) {
              setSelectedVrstaVjerodostojneIsprave(
                  getVrstaVjerodostojne(vjerodostojnaIsprava.vrstaId)
            );
          }
        };

    const getVrstaVjerodostojne = (id: number): DropdownOption[] =>
        vrsteVjerodostojneIsprave.filter(
          (o: DropdownOption): boolean => o.id === id.toString()
        );

    const handleSave = async (values: any) => {
        const updateModel = {
            ...values,
            id: vjerodostojnaIsprava.id,
            oznaka: values.oznaka,
            vrstaId : selectedVrstaVjerodostojneIsprave && selectedVrstaVjerodostojneIsprave?.length > 0
                ? Number(selectedVrstaVjerodostojneIsprave[0].id)
                : undefined,
            datum: values.datum ? moment(values.datum).toDate() : undefined,
            opisTrazbina: values.opisTrazbina
        };
    
        const result = await postAsync(`/VjerodostojneIsprave/Update`, updateModel);
    
        if (result) {
            onSuccess();
        }
    };


    return(
        <Form onFinish={handleSave} {...formItemLayoutDrawer}>
            <DrawerButtons onCancelAction={() => onClose()} />
            <Form.Item
                name="oznaka"
                label={translations.excelTable.oznakaVjerodostojneIsprave}
                initialValue={vjerodostojnaIsprava.oznaka}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="vrsta"
                label={translations.excelTable.vrstaVjerodostojneIsprave}
                rules={[
                {
                    required: true,
                    validator: (_rule: any, value: any, callback: any): void =>
                    customArrayValidationHelper(
                        _rule,
                        callback,
                        translations.general.requiredField,
                        selectedVrstaVjerodostojneIsprave
                    ),
                },
                ]}
            >
                <AutocompleteDropdown
                placeholder={translations.excelTable.vrstaVjerodostojneIsprave}
                getOptionsFrontend={(value: string): DropdownOption[] =>
                    filterDropdownOptions(value, vrsteVjerodostojneIsprave)
                }
                initialValues={selectedVrstaVjerodostojneIsprave}
                confirmDirty={(value: DropdownOption[]): void => {
                    setSelectedVrstaVjerodostojneIsprave(value);
                }}
                />
            </Form.Item>
            <Form.Item
                name="datum"
                label={translations.excelTable.datumVjerodostojneIsprave}
                initialValue={
                    vjerodostojnaIsprava.datum
                    ? moment(vjerodostojnaIsprava.datum)
                    : undefined
                }
                rules={[
                {
                    type: "object" as const,
                    required: true,
                    message: translations.general.selectDate,
                },
                ]}
            >
                <DatePicker format="DD.MM.yyyy." />
            </Form.Item>
            <Form.Item
                name="opisTrazbina"
                label={translations.excelTable.opisTrazbine}
                initialValue={vjerodostojnaIsprava.opisTrazbina}
            >
                <Input />
            </Form.Item>
        </Form>
    );
}

export default UpdateVjerodostojnaIspravaForm