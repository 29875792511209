import React, { useState, useEffect } from 'react';
import { Props } from './index';
import { Button, PageHeader, Drawer } from 'antd';
import { translations } from '../../config/translations';
import { DrawerState } from '../../core/models/enum';
import { PlusOutlined } from '@ant-design/icons';
import { DRAWER_MEDIUM_WIDTH } from '../../config/constants';
import ZakonskiZastupnikForm from '../zakonskiZastupnici/forms';
import ZakonskiZastupniciTable from './tables';
import { DropdownOption } from "../../core/models/DropdownOption";
import { getVezaniSudionikTipModel } from "../../helpers/EnumHelper";
import { IVezaniSudionikTipModel } from "../../utils/interfaces/IVezaniSudionikTipModel";

const ZakonskiZastupnici: React.FC<Props> = (props: Props) => {
    const [zakonskiZastupnikDrawerState, setZakonskiZastupnikDrawerState] = useState<DrawerState>(DrawerState.Closed);
    const [shouldTableUpdate, setShouldTableUpdate] = useState(false);
    const [osobaTipovi, setOsobaTipovi] = useState<DropdownOption[]>([]);
    const [clickedZakonskiZastupnikId, setClickedZakonskiZastupnikId] = useState<string | undefined>();

    useEffect(() => {
        getOsobaTipovi();
    }, []);

    const getOsobaTipovi = async () => {
        const data = getVezaniSudionikTipModel();

        setOsobaTipovi(data.map((
            item: IVezaniSudionikTipModel,
        ): DropdownOption => ({ id: item.vezaniSudionikTip.toString(), name: item.displayName })));
    };

    const handleZakonskiZastupnikDrawerChange = (newZakonskiZastupnikDrawerState: DrawerState, newShouldUpdate: boolean, id?: string) => {
        setZakonskiZastupnikDrawerState(newZakonskiZastupnikDrawerState);
        setShouldTableUpdate(newShouldUpdate);
        setClickedZakonskiZastupnikId(id);
    };

    return (
        <div>
            <PageHeader
                title={translations.zakonskiZastupnik.title}
                extra={
                    [
                        <Button
                            key="1"
                            type="primary"
                            style={{
                                zIndex: 10,
                                float: 'right',
                                marginLeft: '10px'
                            }}
                            onClick={() => handleZakonskiZastupnikDrawerChange(DrawerState.Create, false)}
                            className="no-print"
                        >
                            <PlusOutlined />
                            {translations.zakonskiZastupnik.addZakonskiZastupnik}
                        </Button>,
                    ]
                }
            />
            <ZakonskiZastupniciTable
                shouldUpdate={shouldTableUpdate}
                setShouldUpdateState={setShouldTableUpdate}
                osobaTipovi={osobaTipovi}
                handleZakonskiZastupnikDrawerChange={handleZakonskiZastupnikDrawerChange}
            />
            <Drawer
                title={translations.general.zakonskiZastupnik}
                visible={!!zakonskiZastupnikDrawerState}
                onClose={() => handleZakonskiZastupnikDrawerChange(DrawerState.Closed, false)}
                width={DRAWER_MEDIUM_WIDTH}
                destroyOnClose
            >
                <ZakonskiZastupnikForm
                    onClose={handleZakonskiZastupnikDrawerChange}
                    zakonskiZastupnikId={clickedZakonskiZastupnikId}
                    osobaTipovi={osobaTipovi}
                />
            </Drawer>
        </div>
    );
}

export default ZakonskiZastupnici;