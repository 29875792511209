
export const oibValidate = (oib: string | null): boolean => {
    if (oib === null) return true;
    if (oib.length !== 11) return false;

    let remainder = 10;

    for (let index = 0; index < oib.length - 1; index += 1) {
        const currentNumber = Number(oib[index]);

        const sum = currentNumber + remainder;

        let modulo = sum % 10;

        if (modulo === 0) {
            modulo = 10;
        }

        const multi = modulo * 2;

        remainder = multi % 11;
    }

    let controlNum = 0;

    if (remainder === 1) {
        controlNum = 0;
    } else {
        controlNum = 11 - remainder;
    }

    if (oib[10] === String(controlNum)) return true;

    return false;
};