import React, { useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './styles.css';

interface Props {
    customMessage: string;
}

const delayTime = 100;
const antIcon = <LoadingOutlined style={{ fontSize: 120, color: 'white' }} spin />;

export const LoadingOverlay: React.FC<Props> = (props: Props) => {
    const { customMessage } = props;
    const [backgroundColor, setBackgroundColor] = useState<string>("transparent");

    setTimeout(() => {
        setBackgroundColor("rgba(0, 0, 0, 0.5)");
    }, delayTime);

    return (
        <div className="loading" style={{ backgroundColor }}>
            <Spin delay={delayTime} indicator={antIcon} tip={customMessage} />
        </div>
    );
}
