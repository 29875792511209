import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Trazbina } from '../../../../utils/models/ExcelTableData';
import UpdateTrazbinaForm from './UpdateTrazbinaForm';

interface OwnProps {
    onClose: () => void;
    onSuccess: () => void;
    trazbina: Trazbina;
}

export type Props = OwnProps & RouteComponentProps;

export default withRouter(UpdateTrazbinaForm);
