import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ExcelTableData } from '../../../../utils/models/ExcelTableData';
import UpdateExcelRowForm from './UpdateExcelRowForm';

interface OwnProps {
    onClose: () => void;
    onSuccess: () => void;
    prijedlog: ExcelTableData;
}

export type Props = OwnProps & RouteComponentProps;

export default withRouter(UpdateExcelRowForm);
