import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PrilogType } from '../../../../core/models/enum';
import BulkExcelAttachmentForm from './BulkExcelAttachmentForm';

interface OwnProps {
    onSuccess: () => void;
    excelFileId: string;
    prilogType: PrilogType;
}

export type Props = OwnProps & RouteComponentProps;

export default withRouter(BulkExcelAttachmentForm);
