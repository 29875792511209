import React, { useState, useEffect } from 'react';
import { Props } from './index';
import { Button, PageHeader, Drawer } from 'antd';
import { translations } from '../../config/translations';
import { DrawerState } from '../../core/models/enum';
import { PlusOutlined } from '@ant-design/icons';
import { DRAWER_MEDIUM_WIDTH } from '../../config/constants';
import PunomocnikForm from '../punomocnici/forms';
import PunomocniciTable from './tables';
import { DropdownOption } from "../../core/models/DropdownOption";
import { getVezaniSudionikTipModel } from "../../helpers/EnumHelper";
import { IVezaniSudionikTipModel } from "../../utils/interfaces/IVezaniSudionikTipModel";

const Punomocnici: React.FC<Props> = () => {
    const [punomocnikDrawerState, setPunomocnikDrawerState] = useState<DrawerState>(DrawerState.Closed);
    const [shouldTableUpdate, setShouldTableUpdate] = useState(false);
    const [osobaTipovi, setOsobaTipovi] = useState<DropdownOption[]>([]);
    const [clickedPunomocnikId, setClickedPunomocnikId] = useState<string | undefined>();

    useEffect(() => {
        getOsobaTipovi();
    }, []);

    const getOsobaTipovi = async () => {
        const data = getVezaniSudionikTipModel();

        setOsobaTipovi(data.map((
            item: IVezaniSudionikTipModel,
        ): DropdownOption => ({ id: item.vezaniSudionikTip.toString(), name: item.displayName })));
    };

    const handlePunomocnikDrawerChange = (newPunomocnikDrawerState: DrawerState, newShouldUpdate: boolean, id?: string) => {
        setPunomocnikDrawerState(newPunomocnikDrawerState);
        setShouldTableUpdate(newShouldUpdate);
        setClickedPunomocnikId(id);
    };

    return (
        <div>
            <PageHeader
                title={translations.punomocnik.title}
                extra={
                    [
                        <Button
                            key="1"
                            type="primary"
                            style={{
                                zIndex: 10,
                                float: 'right',
                                marginLeft: '10px',
                            }}
                            onClick={() => handlePunomocnikDrawerChange(DrawerState.Create, false)}
                            className="no-print"
                        >
                            <PlusOutlined />
                            {translations.punomocnik.addPunomocnik}
                        </Button>,
                    ]
                }
            />
            <PunomocniciTable
                shouldUpdate={shouldTableUpdate}
                setShouldUpdateState={setShouldTableUpdate}
                osobaTipovi={osobaTipovi}
                handlePunomocnikDrawerChange={handlePunomocnikDrawerChange}

            />
            <Drawer
                title={translations.general.punomocnik}
                visible={!!punomocnikDrawerState}
                onClose={() => handlePunomocnikDrawerChange(DrawerState.Closed, false)}
                width={DRAWER_MEDIUM_WIDTH}
                destroyOnClose
            >
                <PunomocnikForm
                    onClose={handlePunomocnikDrawerChange}
                    punomocnikId={clickedPunomocnikId}
                    osobaTipovi={osobaTipovi}
                />
            </Drawer>
        </div>
    );
}

export default Punomocnici;