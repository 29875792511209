import React, { useState } from "react";
import { Form, Upload, Button } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { ImportOutlined } from "@ant-design/icons";
import { AxiosResponse } from "axios";
import { translations } from "../../../../config/translations";
import { zipType } from "../../../../config/constants";
import { showError } from "../../../../helpers/NotificationHelper";
import { formItemLayoutDrawer10_14 } from "../../../../helpers/TableHelper";
import { getBulkUploadDrawerLabel } from "../../../../helpers/TitleHelper";
import { useApiContext } from '../../../../helpers/ApiContext';
import { Props } from "./index";

const BulkExcelAttachmentForm: React.FC<Props> = (props: Props) => {
    const { excelFileId, prilogType, onSuccess } = props;
    const { postAsync } = useApiContext();
    const [uploadedZip, setPdfPrivitciDrawerState] = useState<UploadFile[]>([]);

    const savePdfovi = async (): Promise<void> => {
        let formData = new FormData();

        formData.append("zip", uploadedZip[0] as any);
        formData.append("type", prilogType as any);
        formData.append("excelFileId", excelFileId);

        const data = await postAsync<AxiosResponse>('/Files/UploadBulkPrijedlogPdf', formData, translations.general.bulkUploadSaving, translations.general.importBulkPdfSuccess, translations.general.errorSavingData);

        if (data) {
            onSuccess();
        };
    };

    return (
        <Form {...formItemLayoutDrawer10_14}>
            <Form.Item
                name="uploadedZip"
                label={getBulkUploadDrawerLabel(prilogType)}
            >
                <Upload
                    fileList={uploadedZip}
                    onRemove={() => {
                        setPdfPrivitciDrawerState([]);
                    }}
                    beforeUpload={(file) => {
                        if (file.size === 0) {
                            showError(translations.general.emptyZip);
                            return false;
                        }
                        if (file.type !== zipType) {
                            showError(translations.general.wrongFileType);
                        } else {
                            setPdfPrivitciDrawerState([file]);
                        }
                        return false;
                    }}
                    accept={zipType}
                >
                    <Button type="primary" style={{ marginTop: '10px' }}>
                        <ImportOutlined />
                        {translations.general.upload}
                    </Button>
                </Upload>
                <Button
                    type="default"
                    onClick={savePdfovi}
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                    disabled={uploadedZip.length === 0}>
                    <ImportOutlined />
                    {translations.general.spremiDatoteku}
                </Button>
            </Form.Item>
        </Form>
    );
}

export default BulkExcelAttachmentForm;