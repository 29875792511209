import React from "react";
import { Form, Input, InputNumber } from "antd";
import { Props } from "./index";
import { formItemLayoutDrawer } from "../../../../helpers/TableHelper";
import { useApiContext } from "../../../../helpers/ApiContext";
import { DrawerButtons } from "../../../../components/drawer/buttons/DrawerButtons";
import { translations } from "../../../../config/translations";

const UpdateTrazbinaForm: React.FC<Props> = (props: Props) => {
    const { trazbina, onClose, onSuccess } = props;
    const { postAsync } = useApiContext();

    const handleSave = async (values: any) => {
        const updateModel = {
            ...values,
            id: trazbina.id,
            glavnica: values.glavnica ? Number(values.glavnica) : undefined,
            brojRacuna: values.brojRacuna
        };
    
        const result = await postAsync(`/Trazbine/Update`, updateModel);
    
        if (result) {
            onSuccess();
        }
    }


    return(
        <Form onFinish={handleSave} {...formItemLayoutDrawer}>
            <DrawerButtons onCancelAction={() => onClose()} />
            <Form.Item
                name="glavnica"
                label={translations.excelTable.glavnica}
                initialValue={trazbina.glavnica}
                rules={[
                {
                    required: true,
                    message: translations.general.requiredField,
                },
                ]}
            >
                <InputNumber className="custom-antd-number-input" />
            </Form.Item>
            <Form.Item
                name="brojRacuna"
                label={translations.excelTable.brojRacuna}
                initialValue={trazbina.brojRacuna}
                rules={[
                {
                    required: true,
                    message: translations.general.requiredField,
                },
                ]}
            >
                <Input />
            </Form.Item>
        </Form>
    );
}

export default UpdateTrazbinaForm