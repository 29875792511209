import React, { ReactElement } from 'react';
import { Menu } from 'antd';
import { TableAction } from '../../core/models/TableAction';
import './styles.css';

interface Props {
    tableActions: TableAction[];
    className?: string;
}

export const TableActionDropdown: React.FC<Props> = (props: Props) => {
    const { tableActions, className } = props;

    return (
        <Menu className={className}>
            {
                tableActions?.length > 0
                && tableActions.map((action: TableAction): ReactElement => (
                    <Menu.Item
                        key={`actions-${action.id}`}
                        onClick={(): (Promise<void> | void) => action.onClick(action.entity)}
                        className={`action-button ${action.className} ${action.disabled && 'action-button-disabled'}`}
                    >
                        {action.label}
                    </Menu.Item>
                ))
            }
        </Menu>
    );
};
