import { RouteComponentProps, withRouter } from 'react-router-dom';
import ZakonskiZastupniciTable from './ZakonskiZastupniciTable';
import { DropdownOption } from "../../../core/models/DropdownOption";
import { DrawerState } from '../../../core/models/enum';

interface OwnProps {
    shouldUpdate: boolean;
    setShouldUpdateState: (newState: boolean) => void;
    osobaTipovi: DropdownOption[];
    handleZakonskiZastupnikDrawerChange: (newZakonskiZastupnikDrawerState: DrawerState, newShouldUpdate: boolean, id?: string) => void;
}

export type Props = OwnProps & RouteComponentProps;

export default withRouter(ZakonskiZastupniciTable);
