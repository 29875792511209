import axios from "axios";
import { API_HOST } from "../config/constants";
import { showError, showSuccess } from "../helpers/NotificationHelper";
import { translations } from "../config/translations";
import { Error } from "../core/models/Error";


const instance = axios.create({
    baseURL: API_HOST,
    timeout: 600000,
});

export const get = async <T>(url: string, handleError: (err: Error, message?: string) => any, customSuccessMessage?: string, customErrorMessage?: string): Promise<T> => {
    const res = await instance.get<T>(
        url,
        {
            transformResponse: ([] as any[]).concat(
                (data: string): T => JSON.parse(data),
            ),
        },
    ).catch((err): any => handleError(err, customErrorMessage || translations.general.genericGetError));
    if (res.data) {
        //showSuccess(customSuccesMessage || translations.general.genericGetSuccess); nemamo trenutno nigdje obavijest
    } else {
        showError(translations.general.genericGetError);
    };
    return res.data;
};

export const post = async <T>(url: string, handleError: (err: Error, message?: string) => any, data?: any, customSuccessMessage?: string, customErrorMessage?: string, shouldShowDataErrors?: boolean): Promise<T> => {
    const res = await instance.post(
        url,
        data,
        {
            timeout: 600000,
            headers: {
                'Content-Type': 'application/json',
            },
        },
    ).catch((err): any => handleError(err, customErrorMessage || translations.general.genericPostError));
    if (res.data && !(res.data.isSuccessful !== undefined && res.data.isSuccessful === false)) {
        showSuccess(customSuccessMessage || translations.general.genericPostSuccess);
    } else {
        showError(translations.general.genericPostError);
    };
    return res.data;
};

export const deleteApi = async <T>(url: string, handleError: (err: Error, message?: string) => any, customSuccessMessage?: string, customErrorMessage?: string): Promise<T> => {
    const res = await instance.delete<T>(
        url,
        {
            transformResponse: ([] as any[]).concat(
                (data: string): T => JSON.parse(data),
            ),
        },
    ).catch((err): any => handleError(err, customErrorMessage || translations.general.genericDeleteError));
    if (res.data) {
        showSuccess(customSuccessMessage || translations.general.genericDeleteSuccess);
    } else {
        showError(translations.general.genericDeleteError);
    };
    return res.data;
};

export const put = async <T>(url: string, handleError: (err: Error, message?: string) => any, data?: any, customSuccessMessage?: string, customErrorMessage?: string): Promise<T> => {
    const res = await instance.put(
        url,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    ).catch((err): any => handleError(err, customErrorMessage || translations.general.genericPutError));
    if (res.data) {
        showSuccess(customSuccessMessage || translations.general.genericPutSuccess);
    } else {
        showError(translations.general.genericPutError);
    };
    return res.data;
};