import { RouteComponentProps, withRouter } from 'react-router-dom';
import ExcelFilesTable from './ExcelFilesTable';

interface OwnProps {
    shouldUpdate: boolean;
    setShouldUpdateState: (newState: boolean) => void;
}

export type Props = OwnProps & RouteComponentProps;

export default withRouter(ExcelFilesTable);
