/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ReactElement } from "react";
import { Form, Upload, Button, Input } from "antd";
import { DeleteOutlined, ImportOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import { AxiosResponse } from "axios";
import { translations } from "../../../../config/translations";
import { pdfType } from "../../../../config/constants";
import { showError } from "../../../../helpers/NotificationHelper";
import { PrilogType } from "../../../../core/models/enum";
import { PrijedlogPrilog } from "../../../../core/models/PrijedlogPrilog";
import { useApiContext } from "../../../../helpers/ApiContext";
import { formItemLayoutDrawer } from "../../../../helpers/TableHelper";
import { openPDFPreview } from "../../../../helpers/CommonHelper";
import { Props } from "./index";

const UploadPdfForm: React.FC<Props> = (props: Props) => {
  const { prijedlogId } = props;
  const { postAsync, deleteAsync, getAsync } = useApiContext();
  const [vjerodostojnaIspravaPdf, setVjerodostojnaIspravaPdf] = useState<
    UploadFile[]
  >([]);
  const [ostaloPdf, setOstaloPdf] = useState<UploadFile[]>([]);
  const [dokazPunomociPdf, setDokazPunomociPdf] = useState<UploadFile[]>([]);
  const [dokazZastupanjaPdf, setDokazZastupanjaPdf] = useState<UploadFile[]>([]);
  const [dokazZastupanjaOvrsenikPdf, setDokazZastupanjaOvrsenikPdf] = useState<UploadFile[]>([]);



  const [ostaloDescription, setOstaloDescription] = useState<
    string | undefined
  >();
  const [vjerodostojnaIsprava, setVjerodostojnaIsprava] = useState<
    PrijedlogPrilog | undefined
  >();
  const [ostaliPdfovi, setOstaliPdfovi] = useState<
    PrijedlogPrilog[] | undefined
  >();
  const [dokazPunomoci, setDokazPunomoci] = useState<
    PrijedlogPrilog | undefined
  >();
  const [dokazZastupanja, setDokazZastupanja] = useState<
    PrijedlogPrilog | undefined
  >();
  const [dokazZastupanjaOvrsenik, setDokazZastupanjaOvrsenik] = useState<
    PrijedlogPrilog | undefined
  >();

  useEffect(() => {
    getPdfs();
  }, []);

  const saveVjerodostojnaIspravaPdf = async () => {
    let formData = new FormData();

    formData.append("pdf", vjerodostojnaIspravaPdf[0] as any);
    formData.append("type", PrilogType.VjerodostojnaIsprava as any);
    formData.append("prijedlogId", prijedlogId);

    const data = await postAsync<AxiosResponse>(
      "/Files/UploadPrijedlogPdf",
      formData,
      undefined,
      translations.general.importPdfSuccess,
      translations.general.errorSavingData
    );

    if (data) {
      getPdfs();
      setVjerodostojnaIspravaPdf([]);
    }
  };

  const deleteVjerodostojnaIspravaPdf = async () => {
    const data = await deleteAsync<AxiosResponse>(
      "/Files/DeletePrijedlogPrilog/" + vjerodostojnaIsprava?.id,
      undefined,
      translations.general.successDeletingPdf,
      translations.general.errorDeletingPdf
    );

    if (data.data) {
      getPdfs();
      setVjerodostojnaIsprava(undefined);
    }
  };

  const saveDokazPunomociPdf = async () => {
    let formData = new FormData();

    formData.append("pdf", dokazPunomociPdf[0] as any);
    formData.append("type", PrilogType.DokazPunomoci as any);
    formData.append("prijedlogId", prijedlogId);

    const data = await postAsync<AxiosResponse>(
      "/Files/UploadPrijedlogPdf",
      formData,
      undefined,
      translations.general.importPdfSuccess,
      translations.general.errorSavingData
    );

    if (data) {
      getPdfs();
      setDokazPunomociPdf([]);
    }
  };

  const deleteDokazPunomociPdf = async () => {
    const data = await deleteAsync<AxiosResponse>(
      "/Files/DeletePrijedlogPrilog/" + dokazPunomoci?.id,
      undefined,
      translations.general.successDeletingPdf,
      translations.general.errorDeletingPdf
    );

    if (data.data) {
      getPdfs();
      setDokazPunomoci(undefined);
    }
  };

  const saveDokazZastupanjaPdf = async () => {
    let formData = new FormData();

    formData.append("pdf", dokazZastupanjaPdf[0] as any);
    formData.append("type", PrilogType.DokazZastupanja as any);
    formData.append("prijedlogId", prijedlogId);

    const data = await postAsync<AxiosResponse>(
      "/Files/UploadPrijedlogPdf",
      formData,
      undefined,
      translations.general.importPdfSuccess,
      translations.general.errorSavingData
    );

    if (data) {
      getPdfs();
      setDokazZastupanjaPdf([]);
    }
  };

    const deleteDokazZastupanjaPdf = async () => {
      const data = await deleteAsync<AxiosResponse>(
        "/Files/DeletePrijedlogPrilog/" + dokazZastupanja?.id,
        undefined,
        translations.general.successDeletingPdf,
        translations.general.errorDeletingPdf
      );

      if (data.data) {
        getPdfs();
        setDokazZastupanja(undefined);
      }
    };

  const saveDokazZastupanjaOvrsenikPdf = async () => {
    let formData = new FormData();

    formData.append("pdf", dokazZastupanjaOvrsenikPdf[0] as any);
    formData.append("type", PrilogType.DokazZastupanjaOvrsenik as any);
    formData.append("prijedlogId", prijedlogId);

    const data = await postAsync<AxiosResponse>(
      "/Files/UploadPrijedlogPdf",
      formData,
      undefined,
      translations.general.importPdfSuccess,
      translations.general.errorSavingData
    );

    if (data) {
      getPdfs();
      setDokazZastupanjaOvrsenikPdf([]);
    }
  };

  const deleteDokazZastupanjaOvrsenikPdf = async () => {
    const data = await deleteAsync<AxiosResponse>(
      "/Files/DeletePrijedlogPrilog/" + dokazZastupanjaOvrsenik?.id,
      undefined,
      translations.general.successDeletingPdf,
      translations.general.errorDeletingPdf
    );

    if (data.data) {
      getPdfs();
      setDokazZastupanjaOvrsenik(undefined);
    }
  };

  const editOstaloOpis = async (index: number) => {
    if (ostaliPdfovi && ostaliPdfovi[index].desc.length > 0) {
      if (ostaliPdfovi) {
        let formData = new FormData();

        formData.append("id", ostaliPdfovi[index].id);
        formData.append("description", ostaliPdfovi[index].desc);
        const data = await postAsync<AxiosResponse>(
          "/Files/EditPrijedlogPrilogOpis",
          formData,
          undefined,
          translations.general.successEditingPdf,
          translations.general.errorEditingPdf
        );

        if (data.data) {
          getPdfs();
        }
      }
    } else {
      showError(translations.general.opisRequired);
    }
  };

  const saveOstaloPdfovi = async () => {
    if (ostaloDescription) {
      let formData = new FormData();

      formData.append("pdf", ostaloPdf[0] as any);
      formData.append("type", PrilogType.Ostalo as any);
      formData.append("prijedlogId", prijedlogId);
      formData.append("desc", ostaloDescription as any);

      const data = await postAsync<AxiosResponse>(
        "/Files/UploadPrijedlogPdf",
        formData,
        undefined,
        translations.general.importPdfSuccess,
        translations.general.errorSavingData
      );

      if (data) {
        getPdfs();
        setOstaloPdf([]);
        setOstaloDescription(undefined);
      }
    } else {
      showError(translations.general.opisRequired);
    }
  };

  const deleteOstaloPdfovi = async (id: string) => {
    const data = await deleteAsync<AxiosResponse>(
      "/Files/DeletePrijedlogPrilog/" + id,
      undefined,
      translations.general.successDeletingPdf,
      translations.general.errorDeletingPdf
    );

    if (data.data) {
      getPdfs();
    }
  };

  const getPdfs = async () => {
    const data = await getAsync<PrijedlogPrilog[]>(
      "/Files/GetAllPrijedlogPrilog/" + prijedlogId
    );

    if (data) {
      const isprava = data.find(
        (pdf: PrijedlogPrilog): boolean =>
          pdf.type === PrilogType.VjerodostojnaIsprava
      );
      if (isprava) {
        setVjerodostojnaIsprava(isprava);
      }
      const ostalo = data.filter(
        (pdf: PrijedlogPrilog): boolean => pdf.type === PrilogType.Ostalo
      );

      if (ostalo) {
        setOstaliPdfovi(
          ostalo.map((item: PrijedlogPrilog): PrijedlogPrilog => item)
        );
      }

      const punomoc = data.find(
        (pdf: PrijedlogPrilog): boolean => pdf.type === PrilogType.DokazPunomoci
      );
      if (punomoc) {
        setDokazPunomoci(punomoc);
      }
      const zastupanje = data.find(
        (pdf: PrijedlogPrilog): boolean =>
          pdf.type === PrilogType.DokazZastupanja
      );

      if (zastupanje) {
        setDokazZastupanja(zastupanje);
      }

      const zastupanjeOvrsenik = data.find(
        (pdf: PrijedlogPrilog): boolean =>
          pdf.type === PrilogType.DokazZastupanjaOvrsenik
      )

      if (zastupanjeOvrsenik) {
        setDokazZastupanjaOvrsenik(zastupanjeOvrsenik);
      }
    }
  };

  const setDescription = (description: string, index: number) => {
    if (ostaliPdfovi) {
      const newPdfArray = ostaliPdfovi.map(
        (pdf: PrijedlogPrilog, i: number): PrijedlogPrilog => {
          if (index === i) {
            return { ...pdf, desc: description };
          } else {
            return pdf;
          }
        }
      );

      setOstaliPdfovi(newPdfArray);
    }
  };

  return (
    <Form {...formItemLayoutDrawer}>
      <Form.Item
        name="vjerodostojnaIspravaPdf"
        label={translations.general.vjerodostojnaIsprava}
        rules={[
          {
            required: true,
            message: translations.general.requiredField,
          },
        ]}
      >
        {!vjerodostojnaIsprava && (
          <Upload
            fileList={vjerodostojnaIspravaPdf}
            onRemove={() => {
              setVjerodostojnaIspravaPdf([]);
            }}
            beforeUpload={(file) => {
              if (file.type !== pdfType) {
                showError(translations.general.wrongFileType);
              } else {
                setVjerodostojnaIspravaPdf([file]);
              }
              return false;
            }}
            accept={pdfType}
            disabled={vjerodostojnaIsprava !== undefined}
          >
            <Button type="primary" style={{ marginTop: "10px" }}>
              <ImportOutlined />
              {translations.general.upload}
            </Button>
          </Upload>
        )}
        {vjerodostojnaIsprava && (
          <Button
            onClick={(): Promise<void> =>
              openPDFPreview(
                vjerodostojnaIsprava.base64,
                translations.general.prijedlog
              )
            }
            style={{ width: "350px", overflow: "hidden" }}
          >
            {vjerodostojnaIsprava.fileName}
          </Button>
        )}
        {!vjerodostojnaIsprava && (
          <Button
            type="default"
            onClick={saveVjerodostojnaIspravaPdf}
            style={{ marginTop: "20px", marginBottom: "20px" }}
            disabled={vjerodostojnaIspravaPdf.length === 0}
          >
            <ImportOutlined />
            {translations.general.spremiDatoteku}
          </Button>
        )}
        {vjerodostojnaIsprava && (
          <Button
            type="default"
            danger
            onClick={deleteVjerodostojnaIspravaPdf}
            style={{ marginLeft: "10px" }}
            disabled={!vjerodostojnaIsprava}
          >
            <DeleteOutlined />
          </Button>
        )}
      </Form.Item>
      <Form.Item
        name="dokazPunomociPdf"
        label={translations.general.dokazPunomoci}
      >
        {!dokazPunomoci && (
          <Upload
            fileList={dokazPunomociPdf}
            onRemove={() => {
              setDokazPunomociPdf([]);
            }}
            beforeUpload={(file) => {
              if (file.type !== pdfType) {
                showError(translations.general.wrongFileType);
              } else {
                setDokazPunomociPdf([file]);
              }
              return false;
            }}
            accept={pdfType}
            disabled={dokazPunomoci !== undefined}
          >
            <Button type="primary" style={{ marginTop: "10px" }}>
              <ImportOutlined />
              {translations.general.upload}
            </Button>
          </Upload>
        )}
        {dokazPunomoci && (
          <Button
            onClick={(): Promise<void> =>
              openPDFPreview(
                dokazPunomoci.base64,
                translations.general.prijedlog
              )
            }
            style={{ width: "350px", overflow: "hidden" }}
          >
            {dokazPunomoci.fileName}
          </Button>
        )}
        {!dokazPunomoci && (
          <Button
            type="default"
            onClick={saveDokazPunomociPdf}
            style={{ marginTop: "20px", marginBottom: "20px" }}
            disabled={dokazPunomociPdf.length === 0}
          >
            <ImportOutlined />
            {translations.general.spremiDatoteku}
          </Button>
        )}
        {dokazPunomoci && (
          <Button
            type="default"
            danger
            onClick={deleteDokazPunomociPdf}
            style={{ marginLeft: "10px" }}
            disabled={!dokazPunomoci}
          >
            <DeleteOutlined />
          </Button>
        )}
      </Form.Item>
      <Form.Item
        name="dokazZastupanjaPdf"
        label={translations.general.dokazZastupanja}
      >
        {!dokazZastupanja && (
          <Upload
            fileList={dokazZastupanjaPdf}
            onRemove={() => {
              setDokazZastupanjaPdf([]);
            }}
            beforeUpload={(file) => {
              if (file.type !== pdfType) {
                showError(translations.general.wrongFileType);
              } else {
                setDokazZastupanjaPdf([file]);
              }
              return false;
            }}
            accept={pdfType}
            disabled={dokazZastupanja !== undefined}
          >
            <Button type="primary" style={{ marginTop: "10px" }}>
              <ImportOutlined />
              {translations.general.upload}
            </Button>
          </Upload>
        )}
        {dokazZastupanja && (
          <Button
            onClick={(): Promise<void> =>
              openPDFPreview(
                dokazZastupanja.base64,
                translations.general.prijedlog
              )
            }
            style={{ width: "350px", overflow: "hidden" }}
          >
            {dokazZastupanja.fileName}
          </Button>
        )}
        {!dokazZastupanja && (
          <Button
            type="default"
            onClick={saveDokazZastupanjaPdf}
            style={{ marginTop: "20px", marginBottom: "20px" }}
            disabled={dokazZastupanjaPdf.length === 0}
          >
            <ImportOutlined />
            {translations.general.spremiDatoteku}
          </Button>
        )}
        {dokazZastupanja && (
          <Button
            type="default"
            danger
            onClick={deleteDokazZastupanjaPdf}
            style={{ marginLeft: "10px" }}
            disabled={!dokazZastupanja}
          >
            <DeleteOutlined />
          </Button>
        )}
      </Form.Item>

      <Form.Item
        name="dokazZastupanjaOvrsenikPdf"
        label={translations.general.dokazZastupanjaOvrsenik}
      >
        {!dokazZastupanjaOvrsenik && (
          <Upload
            fileList={dokazZastupanjaOvrsenikPdf}
            onRemove={() => {
              setDokazZastupanjaOvrsenikPdf([]);
            }}
            beforeUpload={(file) => {
              if (file.type !== pdfType) {
                showError(translations.general.wrongFileType);
              } else {
                setDokazZastupanjaOvrsenikPdf([file]);
              }
              return false;
            }}
            accept={pdfType}
            disabled={dokazZastupanjaOvrsenik !== undefined}
          >
            <Button type="primary" style={{ marginTop: "10px" }}>
              <ImportOutlined />
              {translations.general.upload}
            </Button>
          </Upload>
        )}
        {dokazZastupanjaOvrsenik && (
          <Button
            onClick={(): Promise<void> =>
              openPDFPreview(
                dokazZastupanjaOvrsenik.base64,
                translations.general.prijedlog
              )
            }
            style={{ width: "350px", overflow: "hidden" }}
          >
            {dokazZastupanjaOvrsenik.fileName}
          </Button>
        )}
        {!dokazZastupanjaOvrsenik && (
          <Button
            type="default"
            onClick={saveDokazZastupanjaOvrsenikPdf}
            style={{ marginTop: "20px", marginBottom: "20px" }}
            disabled={dokazZastupanjaOvrsenikPdf.length === 0}
          >
            <ImportOutlined />
            {translations.general.spremiDatoteku}
          </Button>
        )}
        {dokazZastupanjaOvrsenik && (
          <Button
            type="default"
            danger
            onClick={deleteDokazZastupanjaOvrsenikPdf}
            style={{ marginLeft: "10px" }}
            disabled={!dokazZastupanjaOvrsenik}
          >
            <DeleteOutlined />
          </Button>
        )}
      </Form.Item>

      <Form.Item name="ostaloPdfovi" label={translations.general.ostalo}>
        {ostaliPdfovi &&
          ostaliPdfovi?.map(
            (pdf: PrijedlogPrilog, index: number): ReactElement => (
              <>
                <Button
                  style={{
                    marginBottom: "10px",
                    width: "350px",
                    overflow: "hidden",
                  }}
                  onClick={(): Promise<void> =>
                    openPDFPreview(pdf.base64, translations.general.prijedlog)
                  }
                >
                  {pdf.fileName}
                </Button>
                <Button
                  type="default"
                  danger
                  onClick={() => deleteOstaloPdfovi(pdf.id)}
                  style={{ marginLeft: "10px" }}
                  disabled={!ostaliPdfovi}
                >
                  <DeleteOutlined />
                </Button>
                <Input
                  addonBefore={translations.general.opisDokumenta}
                  placeholder={translations.general.opis}
                  required={true}
                  value={pdf.desc}
                  style={{ marginTop: "10px" }}
                  onChange={(input): void =>
                    setDescription(input.target.value, index)
                  }
                ></Input>
                <Button
                  type="default"
                  onClick={() => editOstaloOpis(index)}
                  style={{
                    marginLeft: "40px",
                    display: "block",
                    marginTop: "10px",
                    marginBottom: "30px",
                  }}
                  disabled={!ostaliPdfovi}
                >
                  {translations.general.editDescriptionBtn}
                </Button>
              </>
            )
          )}
        <Upload
          fileList={ostaloPdf}
          onRemove={() => {
            setOstaloPdf([]);
          }}
          beforeUpload={(file) => {
            if (file.type !== pdfType) {
              showError(translations.general.wrongFileType);
            } else {
              setOstaloPdf(ostaloPdf.concat([file]));
            }
            return false;
          }}
          accept={pdfType}
          style={{ marginTop: "10px" }}
        >
          <Button type="primary" style={{ marginTop: "10px" }}>
            <ImportOutlined />
            {translations.general.upload}
          </Button>
        </Upload>
        {ostaloPdf.length > 0 && (
          <Input
            addonBefore={translations.general.opisDokumenta}
            placeholder={translations.general.opis}
            required={true}
            value={ostaloDescription}
            style={{ marginTop: "20px" }}
            onChange={(input): void => setOstaloDescription(input.target.value)}
          ></Input>
        )}
        <Button
          type="default"
          onClick={saveOstaloPdfovi}
          style={{ marginTop: "20px", marginBottom: "20px" }}
          disabled={ostaloPdf.length === 0}
        >
          <ImportOutlined />
          {translations.general.spremiDatoteku}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UploadPdfForm;
