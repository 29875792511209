/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Table from 'antd/lib/table';
import { Dropdown, Button } from 'antd';
import { SmallDashOutlined } from '@ant-design/icons';
import moment from 'moment';
import { translations } from '../../../config/translations';
import { dropdownStyle } from '../../../config/settings';
import Routes from '../../../config/routes';
import { ExcelFilesTableData } from '../../../utils/models/ExcelFilesTableData'
import { TableAction } from '../../../core/models/TableAction';
import { formatDateTime } from '../../../helpers/DateHelper';
import { genericTableProps, getColumnSearchProps } from '../../../helpers/TableHelper';
import { onRowClick } from '../../../helpers/TableHelper';
import { useApiContext } from '../../../helpers/ApiContext';
import { TableActionDropdown } from '../../../components/tableAction-dropdown/TableActionDropdown';
import { Props } from './index';

const ExcelTable: React.FC<Props> = (props: Props) => {
    const { getAsync, userId } = useApiContext();
    const history = useHistory();
    const { shouldUpdate, setShouldUpdateState } = props;
    const [excelFilesTableData, setExcelFilesTableData] = useState<ExcelFilesTableData[] | undefined>([]);

    useEffect(() => {
        getExcelFilesTableData();
    }, [userId]);

    useEffect(() => {
        if (shouldUpdate) {
            setShouldUpdateState(false);
            getExcelFilesTableData();
        }
    }, [shouldUpdate]);

    const getExcelFilesTableData = async () => {
        if (userId !== "") {
            const data = await getAsync<ExcelFilesTableData[]>('/Excel/GetTableByUser/' + userId);

            setExcelFilesTableData(data || []);
        }
    };

    const actionsContent = (value: ExcelFilesTableData): TableAction[] => {
        const returnActions: TableAction[] = [
            {
                id: 1,
                entity: value,
                className: 'details-action-button',
                label: translations.excelFilesTable.details,
                onClick: (): void => history.push(`${Routes.ROUTE_EXCEL_FILES}/${value.id}`),
            },
        ];

        return returnActions;
    };

    const columns = [
        {
            title: translations.excelFilesTable.naziv,
            dataIndex: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: translations.excelFilesTable.datum,
            dataIndex: 'date',
            width: 140,
            render: (value: Date) => formatDateTime(value),
            sorter: (a: ExcelFilesTableData, b: ExcelFilesTableData) => moment(a.date).unix() - moment(b.date).unix()
        },
        {
            title: translations.excelFilesTable.ovrhovoditelj,
            dataIndex: 'ovrhovoditeljFullName',
            ...getColumnSearchProps('ovrhovoditeljFullName'),
            width: 300
        },
        {
            title: translations.excelFilesTable.punomocnici,
            dataIndex: 'punomocnikFullName',
            ...getColumnSearchProps('punomocnikFullName'),
            width: 300
        },
        {
            title: translations.excelFilesTable.zakonskiZastupnik,
            dataIndex: 'zakonskiZastupnikFullName',
            ...getColumnSearchProps('zakonskiZastupnikFullName'),
            width: 300
        },
        {
            title: translations.excelFilesTable.akcije,
            dataIndex: '',
            key: '',
            width: 50,
            render: (value: ExcelFilesTableData): React.ReactElement => (
                <Dropdown
                    overlay={<TableActionDropdown tableActions={actionsContent(value)} />}
                    overlayStyle={dropdownStyle}
                >
                    <Button
                        shape="circle"
                        type="dashed"
                        id="actionsButton"
                        className="do-not-use-on-row-function"
                    >
                        <SmallDashOutlined />
                    </Button>
                </Dropdown>
            ),
        },
    ];

    return (
        <Table
            {...genericTableProps}
            className="cursorPointer"
            columns={columns}
            dataSource={excelFilesTableData}
            rowKey={(item): string => item.id}
            onRow={(item): { onClick: (column: any) => void } => ({
                onClick: (column: { target: { nodeName: string } }): void => onRowClick(column, (): void => history.push(`${Routes.ROUTE_EXCEL_FILES}/${item.id}`)),
            })}
        />
    );
}

export default ExcelTable;