/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  FC,
  useState,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { showError } from "../helpers/NotificationHelper";
import { Error as CustomError } from "../core/models/Error";
import { AuthorizationRoutes } from "../components/api-authorization/ApiAuthorizationConstants";
import authService from "../components/api-authorization/AuthorizeService";
import { LoadingOverlay } from "../components/loading-overlay";
import { post, put, deleteApi, get } from "../utils/api";
import { translations } from "../config/translations";

export interface IApiValues {
  loading: boolean;
  userId: string;
  getAsync: <T>(
    url: string,
    customLoadingMessage?: string,
    customSuccessMessage?: string,
    customErrorMessage?: string
  ) => Promise<T>;
  postAsync: <T>(
    url: string,
    data?: any,
    customLoadingMessage?: string,
    customSuccessMessage?: string,
    customErrorMessage?: string
  ) => Promise<T>;
  putAsync: <T>(
    url: string,
    data?: any,
    customLoadingMessage?: string,
    customSuccessMessage?: string,
    customErrorMessage?: string
  ) => Promise<T>;
  deleteAsync: <T>(
    url: string,
    customLoadingMessage?: string,
    customSuccessMessage?: string,
    customErrorMessage?: string
  ) => Promise<T>;
}

const defaultState: IApiValues = {
  loading: false,
  userId: "",
  getAsync: async function getAsync<T>(
    url: string,
    customLoadingMessage?: string,
    customSuccessMessage?: string,
    customErrorMessage?: string
  ): Promise<T> {
    throw new Error("Function not implemented.");
  },
  postAsync: async function postAsync<T>(
    url: string,
    data?: any,
    customLoadingMessage?: string,
    customSuccessMessage?: string,
    customErrorMessage?: string
  ): Promise<T> {
    throw new Error("Function not implemented.");
  },
  putAsync: async function putAsync<T>(
    url: string,
    data?: any,
    customLoadingMessage?: string,
    customSuccessMessage?: string,
    customErrorMessage?: string
  ): Promise<T> {
    throw new Error("Function not implemented.");
  },
  deleteAsync: async function deleteAsync<T>(
    url: string,
    customLoadingMessage?: string,
    customSuccessMessage?: string,
    customErrorMessage?: string
  ): Promise<T> {
    throw new Error("Function not implemented.");
  },
};

const ApiContext = createContext<IApiValues>(defaultState);
export const useApiContext = () => useContext(ApiContext);

export const ApiContextProvider: FC = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>();
  const [userId, setUserId] = useState<string>(defaultState.userId);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = (): void => {
    Promise.resolve().then(async () => {
      const user = await authService.getUser();

      if (!user?.sub) {
        history.push(AuthorizationRoutes.Login);
      } else if (user?.sub !== userId) {
        setUserId(user?.sub);
      }
    });
  };

  const handleError = (err: CustomError, customMessage?: string): any => {
    const { message, response } = err;

    if (response?.status === 401) {
      history.push(AuthorizationRoutes.Login);
    }

    if (customMessage) {
      showError(customMessage);
      return customMessage;
    }
    if (message) {
      showError(message);
      return message;
    }
    if (response) {
      showError(message);
      return response;
    }
    return err;
  };

  async function getAsync<T>(
    url: string,
    customLoadingMessage?: string,
    customSuccessMessage?: string,
    customErrorMessage?: string
  ): Promise<T> {
    fetchUserData();
    updateLoadingData(true, customLoadingMessage || translations.general.loading);

    const result: T = await get(
      url,
      handleError,
      customSuccessMessage,
      customErrorMessage
    );

    updateLoadingData(false, undefined);

    return result;
  }

  async function postAsync<T>(
    url: string,
    data?: any,
    customLoadingMessage?: string,
    customSuccessMessage?: string,
    customErrorMessage?: string
  ): Promise<T> {
    fetchUserData();
    updateLoadingData(true, customLoadingMessage || translations.general.saving);

    const result: T = await post(
      url,
      handleError,
      data,
      customSuccessMessage,
      customErrorMessage
    );

    updateLoadingData(false, undefined);

    return result;
  }

  async function putAsync<T>(
    url: string,
    data?: any,
    customLoadingMessage?: string,
    customSuccessMessage?: string,
    customErrorMessage?: string
  ): Promise<T> {
    fetchUserData();
    updateLoadingData(true, customLoadingMessage || translations.general.saving);

    const result: T = await put(
      url,
      handleError,
      data,
      customSuccessMessage,
      customErrorMessage
    );

    updateLoadingData(false, undefined);

    return result;
  }

  async function deleteAsync<T>(
    url: string,
    customLoadingMessage?: string,
    customSuccessMessage?: string,
    customErrorMessage?: string
  ): Promise<T> {
    fetchUserData();
    updateLoadingData(true, customLoadingMessage || translations.general.deleting);

    const result: T = await deleteApi(
      url,
      handleError,
      customSuccessMessage,
      customErrorMessage
    );

    updateLoadingData(false, undefined);

    return result;
  }

  const updateLoadingData = (newState: boolean, message?: string): void => {
    if (newState) {
      setLoadingMessage(message)
      setLoading(true);
    } else {
      setLoading(false);
      setLoadingMessage(undefined);
    }
  }

  return (
    <ApiContext.Provider
      value={{ loading, userId, getAsync, postAsync, putAsync, deleteAsync }}
    >
      {props.children}
      {
        loadingMessage && loading && (
          <LoadingOverlay customMessage={loadingMessage} />
        )
      }
    </ApiContext.Provider>
  );
};
