/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button, Drawer, PageHeader, } from 'antd';
import { FolderAddOutlined } from '@ant-design/icons';
import { translations } from '../../config/translations';
import { DRAWER_FULL_WIDTH } from '../../config/constants';
import { DrawerState } from '../../core/models/enum';
import UploadExcelForm from '../../modules/excel/forms/uploadExcel';
import ExcelFilesTable from './tables';
import { Props } from './index';

const ExcelFiles: React.FC<Props> = (props: Props) => {
    const [uploadDrawerState, setUploadDrawerState] = useState<DrawerState>(DrawerState.Closed);
    const [shouldTableUpdate, setShouldTableUpdate] = useState(false);

    const handleUploadDrawerChange = (newUploadDrawerState: DrawerState) => {
        setUploadDrawerState(newUploadDrawerState);
    };

    const handleUploadSuccessCreate = () => {
        handleUploadDrawerChange(DrawerState.Closed);
        setShouldTableUpdate(true)
    };

    return (
        <div>
            <PageHeader
                title={translations.general.excelTitle}
                extra={
                    [
                        <Button
                            key="1"
                            type="dashed"
                            style={{
                                zIndex: 10,
                                float: 'right',
                                marginLeft: '10px'
                            }}
                            onClick={() => handleUploadDrawerChange(DrawerState.Create)}
                            className="no-print"
                        >
                            <FolderAddOutlined />
                            {translations.general.addExcel}
                        </Button>
                    ]
                }
            />
            <Drawer
                title={translations.general.addExcel}
                visible={!!uploadDrawerState}
                onClose={() => handleUploadDrawerChange(DrawerState.Closed)}
                width={DRAWER_FULL_WIDTH}
                destroyOnClose
            >
                <UploadExcelForm
                    onClose={() => handleUploadDrawerChange(DrawerState.Closed)}
                    onSuccess={() => handleUploadSuccessCreate()}
                />
            </Drawer>
            <ExcelFilesTable
                shouldUpdate={shouldTableUpdate}
                setShouldUpdateState={setShouldTableUpdate}
            />
        </div>
    );
}

export default ExcelFiles;